import {
  ActivationStatusLabelsEnum,
  ActivationStatusValuesEnum,
  SettelmentStatusLabelsEnum,
  SettelmentStatusValuesEnum,
} from '../types/activationsManagement.type';

export const ACTIVATION_STATUS_DROPDOWN = [
  { label: ActivationStatusLabelsEnum.All, value: ActivationStatusValuesEnum.All },
  { label: ActivationStatusLabelsEnum.Activated, value: ActivationStatusValuesEnum.Activated },
  { label: ActivationStatusLabelsEnum.NotActivated, value: ActivationStatusValuesEnum.NotActivated },
];

export const SETTELMENT_STATUS_DROPDOWN = [
  { label: SettelmentStatusLabelsEnum.All, value: SettelmentStatusValuesEnum.All },
  { label: SettelmentStatusLabelsEnum.Settled, value: SettelmentStatusValuesEnum.Settled },
  { label: SettelmentStatusLabelsEnum.NotSettled, value: SettelmentStatusValuesEnum.NotSettled },
];
