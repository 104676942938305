



































import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import { computed, defineComponent } from '@vue/composition-api';
import AuthorizedMemberTabIcon from '@/assets/icons/domains/partnership-details/AuthorizedMemberTabIcon.vue';
import ContactInfoTabIcon from '@/assets/icons/domains/partnership-details/ContactInfoTabIcon.vue';
import BusinessDetailsTabIcon from '@/assets/icons/domains/partnership-details/BusinessDetailsTabIcon.vue';
import { PartnershipDetailsTabsNamesEnum } from '@/domains/partnership-details/types/partnership.type';
import AgreementDetailsTabIcon from '@/assets/icons/domains/partnership-details/AgreementDetailsTabIcon.vue';
import BankInformationTabIcon from '@/assets/icons/domains/partnership-details/BankInformationTabIcon.vue';

export default defineComponent({
  components: {
    BankInformationTabIcon,
    AgreementDetailsTabIcon,
    BaseText,
    BaseIcon,
    AuthorizedMemberTabIcon,
    ContactInfoTabIcon,
    BusinessDetailsTabIcon,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: PartnershipDetailsTabsNamesEnum.AuthorizedMember,
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const icons = [
      {
        name: PartnershipDetailsTabsNamesEnum.AuthorizedMember,
        viewBox: '0 0 16 14',
        width: 16,
        height: 14,
      },
      {
        name: PartnershipDetailsTabsNamesEnum.ContactInfo,
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      {
        name: PartnershipDetailsTabsNamesEnum.BusinessDetails,
        viewBox: '0 0 14 16',
        width: 14,
        height: 16,
      },
      {
        name: PartnershipDetailsTabsNamesEnum.AgreementDetails,
        viewBox: '0 0 15 15',
        width: 15,
        height: 15,
      },
      {
        name: PartnershipDetailsTabsNamesEnum.BankInformation,
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
    ];
    const tabIcon = computed(() => {
      return icons.find((icon) => {
        return icon.name === props.tab;
      });
    });
    return {
      tabIcon,
      PartnershipDetailsTabsNamesEnum,
    };
  },
});
