export enum DropdownEnums {
  country = 'country',
  partnerType = 'partnerType',
  appIntendedFor = 'appIntendedFor',
  isLookingForIdea = 'isLookingForIdea',
}

export enum InputEnums {
  fullName = 'fullName',
  emailAddress = 'emailAddress',
  mobileNumber = 'mobileNumber',
}

export enum isIndependentEnum {
  independent = 'independent',
  employed = 'employed',
}

export enum appTypePropertyEnum {
  ZidAppMarket = 'zid-app-market',
  EnterpriseMerchant = 'enterprise-merchant',
  ThemeDeveloper = 'theme-developer',
}
