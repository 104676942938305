import httpClient from '../../httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PartnerAppsListResponseModel from '@/api/models/PartnerAppsListResponseModel';

const ENDPOINT_URL = '/pd/apps/list';

export const getPartnerAppsList = (appTypes?: number[]): Promise<PartnerAppsListResponseModel | ErrorModel> => {
  const params = appTypes && appTypes.length > 0 ? { type: appTypes } : {};

  return httpClient
    .get(ENDPOINT_URL, { params })
    .then((response) => new PartnerAppsListResponseModel(response))
    .catch((error) => error);
};
