import Vue from 'vue';
import ErrorModel from '@/api/models/ErrorModel';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { reactive, ref, onBeforeMount } from '@vue/composition-api';
import { deleteTeamMember, getTeamMembers } from '../../api/teamMembers';
import { TeamMemberInfo } from '../../models/TeamMembersResponseModel';
import {
  TeamMemberStatusEnum,
  TeamMemberRolesEnum,
  TeamMemberRolesNamesEnum,
} from '../../types/memberDetails.interface';

const useTeamMembers = () => {
  const loadingTeamMembersList = ref(false);
  const isDeleteTeamMemberModalVisible = ref(false);
  const loadingDeleteTeamMember = ref(false);
  const teamMemberIdToDelete = ref();
  const teamMembersList = reactive<Record<string, TeamMemberInfo[]>>({ list: [] });

  onBeforeMount(async () => {
    await loadTeamMembersList();
  });

  const hideDeleteTeamMemberModal = (): void => {
    isDeleteTeamMemberModalVisible.value = false;
  };

  const showDeleteTeamMemberModal = (): void => {
    isDeleteTeamMemberModalVisible.value = true;
  };

  const loadTeamMembersList = async (): Promise<void> => {
    loadingTeamMembersList.value = true;
    const response = await getTeamMembers();
    if (!(response instanceof ErrorModel) && response.users) {
      teamMembersList.list = [...response.users];
    }
    loadingTeamMembersList.value = false;
  };

  const onDeleteTeamMemberClicked = (teamMemberID: number): void => {
    teamMemberIdToDelete.value = teamMemberID;
    showDeleteTeamMemberModal();
  };

  const onEditTeamMemberClicked = (teamMemberID: number): void => {
    router.push({ name: RoutingRouteEnum.MemberDetails, params: { id: `${teamMemberID}` } });
  };

  const confirmDeleteTeamMember = async (): Promise<void> => {
    loadingDeleteTeamMember.value = true;
    const deleteTeamMemberRes = await deleteTeamMember(teamMemberIdToDelete.value);
    if (deleteTeamMemberRes instanceof ErrorModel) {
      Vue.$toast.error('Team member could not be deleted');
      hideDeleteTeamMemberModal();
      teamMemberIdToDelete.value = null;
      loadingDeleteTeamMember.value = false;
      return;
    }
    teamMembersList.list = [...deleteTeamMemberRes.users];
    Vue.$toast.success('Team member has been deleted');
    hideDeleteTeamMemberModal();
    teamMemberIdToDelete.value = null;
    loadingDeleteTeamMember.value = false;
  };

  const AddNewMemberClicked = (): void => {
    router
      .push({
        name: RoutingRouteEnum.MemberDetails,
      })
      .catch((er) => {
        //
      });
  };

  return {
    teamMembersList,
    loadingTeamMembersList,
    isDeleteTeamMemberModalVisible,
    loadingDeleteTeamMember,
    TeamMemberStatusEnum,
    TeamMemberRolesEnum,
    TeamMemberRolesNamesEnum,
    onDeleteTeamMemberClicked,
    onEditTeamMemberClicked,
    hideDeleteTeamMemberModal,
    showDeleteTeamMemberModal,
    confirmDeleteTeamMember,
    AddNewMemberClicked,
  };
};

export default useTeamMembers;
