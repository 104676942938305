import httpClient from '../../httpClient';
import CreateStoreResponseModel from '../../models/CreateStoreResponseModel';
import { createStoreDataInterface } from '@/domains/development-store/types/developmentStore.type';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/dev-stores/create';

export const createStore = (data: createStoreDataInterface): Promise<CreateStoreResponseModel | ErrorModel> => {
  return httpClient
    .post(ENDPOINT_URL, {
      name: data.name,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    })
    .then((response) => new CreateStoreResponseModel(response))
    .catch((error) => error);
};
