import Vue from 'vue';
import { computed, defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import CircleQuestionMarkIcon from '@/assets/icons/domains/partnership-details/CircleQuestionMarkIcon.vue';

import {
  ZidButton,
  ZidCard,
  ZidCardBody,
  ZidCardHeader,
  ZidFileSelector,
  ZidInput,
  ZidInputGroup,
  ZidInputTrailing,
  ZidRadio,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidSwitch,
} from '@zidsa/ui';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import {
  CountryCityInterface,
  PartnershipBusinessDetailsInterface,
  PartnershipDetailsBusinessTypeEnum,
  PartnershipDetailsFilePropertyEnum,
  UploadedDocumentsInterface,
  PartnershipDetailsStepEnum,
} from '@/domains/partnership-details/types/partnership.type';
import { getCountriesList, getCountryCities } from '@/domains/partnership-details/api/geography';
import ErrorModel from '@/api/models/ErrorModel';
import { helpers, numeric, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { setPartnershipBusinessDetails } from '@/domains/partnership-details/api/partnership';
import router from '@/router';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';

export default defineComponent({
  components: {
    TabMultiLanguage,
    BaseText,
    ZidButton,
    ZidCard,
    ZidCardBody,
    ZidCardHeader,
    ZidFileSelector,
    ZidInput,
    ZidInputGroup,
    ZidInputTrailing,
    ZidRadio,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidSwitch,
    BaseIcon,
    CircleQuestionMarkIcon,
  },
  props: {
    businessData: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const COUNTRIES_BOX_ID = 'partnershipCountriesList';
    const CITIES_BOX_ID = 'partnershipCitiesList';
    const isLoadingCountriesOrCities = ref(false);
    const isLoadingBusinessDetailsTab = ref(false);
    const countriesList = reactive<Record<string, CountryCityInterface[]>>({ list: [] });
    const citiesList = reactive<Record<string, CountryCityInterface[]>>({ list: [] });
    const countriesListFiltered = reactive<Record<string, CountryCityInterface[]>>({ list: [] });
    const citiesListFiltered = reactive<Record<string, CountryCityInterface[]>>({ list: [] });
    const businessDetailsState = reactive<PartnershipBusinessDetailsInterface>({
      businessType: props.businessData.businessType,
      companyNameAr: props.businessData.companyNameAr,
      companyNameEn: props.businessData.companyNameEn,
      country: props.businessData.country,
      cityId: props.businessData.cityId,
      addressLine1: props.businessData.addressLine1,
      addressLine2: props.businessData.addressLine2,
      district: props.businessData.district,
      postalCode: props.businessData.postalCode,
      commercialRegisterNumber: props.businessData.commercialRegisterNumber,
      commercialRegisterDoc: props.businessData.commercialRegisterDoc,
      vatNumber: props.businessData.vatNumber,
      vatDoc: props.businessData.vatDoc,
      nationalIdDoc: props.businessData.nationalIdDoc,
      freelancerDoc: props.businessData.freelancerDoc,
    });

    const uploadedDocumentsUrls = reactive<UploadedDocumentsInterface>({
      commercialRegisterDoc: props.businessData.commercialRegisterDoc ?? '',
      vatDoc: props.businessData.vatDoc ?? '',
      nationalIdDoc: props.businessData.nationalIdDoc ?? '',
      freelancerDoc: props.businessData.freelancerDoc ?? '',
    });

    const validBusinessType = (value: any) => {
      return (
        value === PartnershipDetailsBusinessTypeEnum.Individual || value === PartnershipDetailsBusinessTypeEnum.Company
      );
    };

    const validFileSize = helpers.withMessage('Please select a valid PDF or PNG of maximum 5MB', (file: File) => {
      return !file || file.size <= 5000000;
    });

    const validFileType = helpers.withMessage('Please select a valid PNG or PDF file', (file: File) => {
      return !file || file.type === 'application/pdf' || file.type === 'image/png';
    });

    const rules = {
      businessType: {
        required,
        validBusinessType,
      },
      country: {
        required,
      },
      cityId: {
        required,
      },
      addressLine1: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
      },
      district: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
      },
      postalCode: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
      },
      companyNameAr: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
      },
      companyNameEn: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
      },
      commercialRegisterNumber: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
      },
      commercialRegisterDoc: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
        }),
        maxSize: validFileSize,
        accepted: validFileType,
      },
      vatDoc: {
        required: requiredIf(() => {
          return (
            businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company &&
            businessDetailsState.country === 'SA'
          );
        }),
        maxSize: validFileSize,
        accepted: validFileType,
      },
      vatNumber: {
        required: requiredIf(() => {
          return (
            businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company &&
            businessDetailsState.country === 'SA'
          );
        }),
      },
      nationalIdDoc: {
        required: requiredIf(() => {
          return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Individual;
        }),
        maxSize: validFileSize,
        accepted: validFileType,
      },
      freelancerDoc: {
        maxSize: validFileSize,
        accepted: validFileType,
      },
    };

    const validator = useVuelidate(rules, businessDetailsState);

    onBeforeMount(async () => {
      await loadCountries();
      await loadCountryCities();
      await loadUploadedDocuments();
    });

    const selectedCountryName = computed(() => {
      if (!businessDetailsState.country) {
        return '...';
      }
      return (
        countriesList.list.find((country) => {
          return country.code.toLowerCase() === businessDetailsState.country?.toLowerCase();
        })?.name ?? '...'
      );
    });

    const selectedCityName = computed(() => {
      if (!businessDetailsState.cityId) {
        return '...';
      }
      return (
        citiesList.list.find((city) => {
          return city.id === businessDetailsState.cityId;
        })?.name ?? '...'
      );
    });

    const isCompany = computed(() => {
      return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Company;
    });

    const isIndividual = computed(() => {
      return businessDetailsState.businessType === PartnershipDetailsBusinessTypeEnum.Individual;
    });

    const isValidForm = computed(() => {
      return !validator.value.$invalid;
    });

    const isDisabledForm = computed(() => {
      return props.step !== PartnershipDetailsStepEnum.BusinessDetails || isLoadingBusinessDetailsTab.value;
    });

    const nationalIDFieldLabel = computed(() => {
      return businessDetailsState.country === 'SA' ? 'National ID / Iqama' : 'Passport';
    });

    const loadCountries = async () => {
      isLoadingCountriesOrCities.value = true;
      const response = await getCountriesList();
      if (response instanceof ErrorModel || typeof response.countries === typeof undefined) {
        Vue.$toast.error('Failed to load Countries');
        isLoadingCountriesOrCities.value = false;
        return;
      }
      countriesList.list = response.countries;
      countriesListFiltered.list = countriesList.list;
      isLoadingCountriesOrCities.value = false;
    };

    const loadCountryCities = async () => {
      citiesListFiltered.list = [];
      citiesList.list = [];
      if (!businessDetailsState.country) {
        return;
      }
      isLoadingCountriesOrCities.value = true;
      const countryId = getCountryIdByIsoCode(businessDetailsState.country);
      if (!countryId) {
        Vue.$toast.error('Wrong Country selected!');
        isLoadingCountriesOrCities.value = false;
        return;
      }
      const response = await getCountryCities(countryId);
      if (response instanceof ErrorModel || typeof response.cities === typeof undefined) {
        Vue.$toast.error('Failed to load Countries');
        isLoadingCountriesOrCities.value = false;
        return;
      }
      citiesList.list = response.cities;
      citiesListFiltered.list = response.cities;
      isLoadingCountriesOrCities.value = false;
    };

    const getCountryIdByIsoCode = (isoCode: string): number | undefined => {
      return countriesList.list.find((country) => {
        return country.code.toLowerCase() === isoCode.toLowerCase();
      })?.id;
    };

    const onCountryChange = async (countryCode: string) => {
      businessDetailsState.country = countryCode;
      businessDetailsState.cityId = null;
      closeSelectBox(COUNTRIES_BOX_ID);
      await loadCountryCities();
    };

    const onCityChange = async (cityId: number) => {
      businessDetailsState.cityId = cityId;
      closeSelectBox(CITIES_BOX_ID);
    };

    const onSearchCountries = (search: string) => {
      if (!search) {
        countriesListFiltered.list = countriesList.list;
        return;
      }
      countriesListFiltered.list = countriesList.list.filter((country) => {
        return country.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      openSelectBox(COUNTRIES_BOX_ID);
    };

    const onSearchCities = (search: string) => {
      if (!search) {
        citiesListFiltered.list = citiesList.list;
        return;
      }
      citiesListFiltered.list = citiesList.list.filter((city) => {
        return city.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      openSelectBox(CITIES_BOX_ID);
    };

    const openSelectBox = (boxId: string) => {
      const box = document.getElementById(boxId);
      if (!box) {
        return;
      }
      box.querySelector('div[role=listbox]')?.classList.add('zid-select--is-open');
      box.querySelector('.zid-select-body')?.removeAttribute('style');
    };

    const closeSelectBox = (boxId: string) => {
      const box = document.getElementById(boxId);
      if (!box) {
        return;
      }
      box.querySelector('div[role=listbox]')?.classList.remove('zid-select--is-open');
      box.querySelector('.zid-select-body')?.setAttribute('style', 'display:none;');
    };

    const onBusinessTypeChange = (type: PartnershipDetailsBusinessTypeEnum) => {
      businessDetailsState.businessType = type;
      validator.value.businessType.$touch();
      if (isCompany.value) {
        businessDetailsState.freelancerDoc = null;
        businessDetailsState.nationalIdDoc = null;
        return;
      }
      businessDetailsState.vatDoc = null;
      businessDetailsState.commercialRegisterDoc = null;
      businessDetailsState.companyNameEn = null;
      businessDetailsState.companyNameAr = null;
      businessDetailsState.commercialRegisterNumber = null;
      businessDetailsState.vatNumber = null;
    };

    const onFileSelect = (fileProperty: PartnershipDetailsFilePropertyEnum, file: File[]) => {
      if (!file.length) {
        return;
      }
      businessDetailsState[fileProperty] = file[0];
      validator.value[fileProperty].$touch();
    };

    const onFileRemove = (fileProperty: PartnershipDetailsFilePropertyEnum) => {
      businessDetailsState[fileProperty] = null;
      validator.value[fileProperty].$touch();
    };

    const onConfirmButtonClick = async () => {
      isLoadingBusinessDetailsTab.value = true;
      const response = await setPartnershipBusinessDetails(businessDetailsState);
      if (response instanceof ErrorModel || typeof response.partnership_details === typeof undefined) {
        Vue.$toast.error('Failed to save contact information');
        isLoadingBusinessDetailsTab.value = false;
        return;
      }
      Vue.$toast.success('Business details updated successfully');
      isLoadingBusinessDetailsTab.value = false;
      router.go(0);
    };

    const createUploadedFileObject = async (url: string | File | null, name: string) => {
      if (!url || url instanceof File) {
        return url;
      }
      return await getFileFromURL(url, name, 'application/pdf');
    };

    const loadUploadedDocuments = async () => {
      if (props.step !== PartnershipDetailsStepEnum.BusinessDetails) {
        return;
      }
      businessDetailsState.commercialRegisterDoc = await createUploadedFileObject(
        businessDetailsState.commercialRegisterDoc,
        'commercial register',
      );
      businessDetailsState.vatDoc = await createUploadedFileObject(businessDetailsState.vatDoc, 'vat document');
      businessDetailsState.nationalIdDoc = await createUploadedFileObject(
        businessDetailsState.nationalIdDoc,
        'national id',
      );
      businessDetailsState.freelancerDoc = await createUploadedFileObject(
        businessDetailsState.freelancerDoc,
        'freelancer document',
      );
    };

    return {
      COUNTRIES_BOX_ID,
      CITIES_BOX_ID,
      PartnershipDetailsBusinessTypeEnum,
      PartnershipDetailsFilePropertyEnum,
      businessDetailsState,
      countriesListFiltered,
      citiesListFiltered,
      selectedCountryName,
      selectedCityName,
      isValidForm,
      isCompany,
      isIndividual,
      isLoadingCountriesOrCities,
      isDisabledForm,
      isLoadingBusinessDetailsTab,
      validator,
      nationalIDFieldLabel,
      uploadedDocumentsUrls,
      PartnershipDetailsStepEnum,
      onCountryChange,
      onSearchCountries,
      onCityChange,
      onSearchCities,
      onBusinessTypeChange,
      onFileSelect,
      onFileRemove,
      onConfirmButtonClick,
    };
  },
});
