import Vue from 'vue';
import router from '@/router';
import { reactive, ref, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email, minLength, maxLength } from '@vuelidate/validators';
import { COUNTRIES_LIST } from '../../../helpers/constants';
import { PARTNER_TYPES_LIST, IS_LOOKING_FOR_IDEAS, APPLICATION_INTENTIONS_LIST } from './dropdownInfo';
import { DropdownEnums, InputEnums, isIndependentEnum, appTypePropertyEnum } from '../types/profile.type';
import { getPartnerInformation, updatePartnerInformation } from '../api/profile';
import PartnerInfoModel from '../models/PartnerInfoModel';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { PHONE_NUMBER } from '@/helpers/regex/numbers';

const { useActions, useGetters } = createNamespacedHelpers('authentication');

const useProfile = () => {
  const { setPartnerName, setPartnerEmail } = useActions(['setPartnerName', 'setPartnerEmail']);
  const { isAccountOwner } = useGetters(['isAccountOwner']);

  const profileState = reactive({
    fullName: '',
    emailAddress: '',
    mobileNumber: '',
    country: {
      label: '',
      value: '',
    },
    partnerType: {
      label: '',
      value: '',
    },
    appIntendedFor: {
      label: '',
      value: '',
    },
    isLookingForIdea: {
      label: '',
      value: '',
    },
  });

  const rules = {
    fullName: { fullName: helpers.withMessage('Full name is required', required) },
    emailAddress: { required, emailAddress: helpers.withMessage('E-mail address is not valid', email) },
    mobileNumber: {
      required,
      mobileNumber: helpers.withMessage('Mobile number is required', required),
      minLength: minLength(8),
      maxLength: maxLength(14),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    country: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    partnerType: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    appIntendedFor: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    isLookingForIdea: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
  };

  const v$ = useVuelidate(rules, profileState);

  const showPassword = ref(false);
  const loadingData = ref(false);

  const countriesList = reactive({
    options: COUNTRIES_LIST,
  });

  const partnerTypes = reactive({
    options: PARTNER_TYPES_LIST,
  });

  const applicationIntentions = reactive({
    options: APPLICATION_INTENTIONS_LIST,
  });

  const isLookingForIdeasOptions = reactive({
    options: IS_LOOKING_FOR_IDEAS,
  });

  const convertFirstLetterToUppercase = (string: string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

  const extractPartnerTypeSelection = (value: number) => {
    if (value === 1) return { label: 'An Independent Developer', value: isIndependentEnum.independent };
    else return { label: 'An Employed Developer (company)', value: isIndependentEnum.employed };
  };

  const extractAppIntendedForSelection = (value: number) => {
    switch (value) {
      case 1:
        return { label: 'Developing Public gApp (list on Zid App Market)', value: appTypePropertyEnum.ZidAppMarket };
      case 2:
        return { label: 'Developing Theme (list on Zid Theme Market)', value: appTypePropertyEnum.ThemeDeveloper };
      default:
        return {
          label: 'Developing Private App (only for Professional and Enterprise packages)',
          value: appTypePropertyEnum.EnterpriseMerchant,
        };
    }
  };

  const getAppIntendedFromLabel = (value: string): string => {
    switch (value) {
      case appTypePropertyEnum.ZidAppMarket:
        return '1';
      case appTypePropertyEnum.ThemeDeveloper:
        return '2';
      default:
        return '0';
    }
  };

  const extractLookingForIdeaSelection = (value: number) => {
    if (value === 1) return { label: 'Yes', value: 'true' };
    else return { label: 'No', value: 'false' };
  };

  onBeforeMount(async () => {
    loadingData.value = true;
    const response = await getPartnerInformation();
    if (!response.partner) return;
    profileState.fullName = response.partner.name ?? '';
    profileState.emailAddress = response.partner.email ?? '';
    profileState.mobileNumber = response.partner.mobile ?? '';

    profileState.country = {
      label: response.partner.country ? convertFirstLetterToUppercase(response.partner.country) : '',
      value: response.partner.country ?? '',
    };

    profileState.partnerType = extractPartnerTypeSelection(response.partner.is_independent);

    profileState.appIntendedFor = extractAppIntendedForSelection(response.partner.application_type);

    profileState.isLookingForIdea = extractLookingForIdeaSelection(response.partner.looking_for_ideas);

    loadingData.value = false;
  });

  const toggleShowPassword = () => (showPassword.value = !showPassword.value);

  const onSelectionDataChange = (changedValue: Record<string, string>, property: DropdownEnums) => {
    v$.value[property].$touch();
    const selectedValue = { ...changedValue };
    profileState[property].label = selectedValue.label;
    profileState[property].value = selectedValue.value;
  };

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: InputEnums) => {
    v$.value[dataProperty].$touch();
    profileState[dataProperty] = changedValue.value;
  };

  const onSaveChangesClicked = async () => {
    loadingData.value = true;
    const partnerInfo = new PartnerInfoModel({
      name: profileState.fullName,
      email: profileState.emailAddress,
      mobile: profileState.mobileNumber,
      country: profileState.country.value,
      is_independent: profileState.partnerType.value === isIndependentEnum.independent ? '1' : '0',
      application_type: getAppIntendedFromLabel(profileState.appIntendedFor.value),
      looking_for_ideas:
        profileState.isLookingForIdea.value || profileState.isLookingForIdea.value === 'true' ? '1' : '0',
    });
    const response = await updatePartnerInformation(partnerInfo);
    if (!response.partner) {
      Vue.$toast.error('Failed to save changes!');
      return;
    }
    setPartnerName(response.partner.name);
    setPartnerEmail(response.partner.email);
    Vue.$toast.success('', {
      position: 'top',
      message: 'Changes have been saved successfully!',
    });
    loadingData.value = false;
  };

  const onBackClicked = () => {
    router.back();
  };

  return {
    profileState,
    countriesList,
    partnerTypes,
    applicationIntentions,
    isLookingForIdeasOptions,
    showPassword,
    v$,
    loadingData,
    isAccountOwner,
    toggleShowPassword,
    onSelectionDataChange,
    onInputDataChange,
    onSaveChangesClicked,
    onBackClicked,
  };
};

export default useProfile;
