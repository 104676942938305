import { defineComponent, ref, computed, reactive } from '@vue/composition-api';
import { ZidButton, ZidIcon, ZidInput, ZidModal } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import { createStore } from '@/api/top-level-apis/development-store/createStore';
import Vue from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import {
  createStoreDataInterface,
  ResponseErrorsList,
  StoreFieldPropertyEnum,
  ValidationErrorsEnum,
} from '@/domains/development-store/types/developmentStore.type';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidIcon,
    ZidInput,
    ZidModal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, cxt) {
    const isModalActive = ref(false);
    const isCreatingStore = ref(false);
    const showPassword = ref(false);

    const createDevelopmentStoreState = reactive<createStoreDataInterface>({
      name: '',
      password: '',
      passwordConfirmation: '',
    });
    const responseErrors = reactive<ResponseErrorsList>({ list: [] });

    const rules = {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(191),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(55),
      },
      passwordConfirmation: {
        required,
        sameAsPassword: helpers.withMessage('Password fields do not match', (value: string) => {
          return value === createDevelopmentStoreState.password;
        }),
      },
    };
    const v$ = useVuelidate(rules, createDevelopmentStoreState);

    const isButtonDisabled = computed(() => {
      return props.disabled;
    });

    const hideModal = () => {
      isModalActive.value = false;
    };

    const showModal = () => {
      isModalActive.value = true;
    };

    const getResponseErrors = (response: BaseModel | ErrorModel): string[] => {
      if (response instanceof ErrorModel && response.validations?.length) {
        let errors: string[] = [];
        response.validations.forEach((validation) => {
          errors = [...validation.errors, ...errors];
        });
        return errors;
      }
      return [];
    };

    const resetDevelopmentStoreState = () => {
      createDevelopmentStoreState.password = '';
      createDevelopmentStoreState.passwordConfirmation = '';
      createDevelopmentStoreState.name = '';
    };

    const onDataInputChange = (changedValue: Record<string, string>, dataProperty: StoreFieldPropertyEnum) => {
      v$.value[dataProperty].$touch();
      createDevelopmentStoreState[dataProperty] = changedValue.value;
    };

    const onSaveClicked = async () => {
      isCreatingStore.value = true;
      const response = await createStore(createDevelopmentStoreState);
      if (response.status !== 'success') {
        Vue.$toast.error(``, {
          message: 'Failed to create development store',
          position: 'top',
        });
        responseErrors.list = getResponseErrors(response);
        isCreatingStore.value = false;
        return;
      }
      isCreatingStore.value = false;
      isModalActive.value = false;
      cxt.emit('devStoreCreated');
      resetDevelopmentStoreState();
      v$.value.$reset();
      Vue.$toast.success('Development store was created successfully');
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      isModalActive,
      isCreatingStore,
      isButtonDisabled,
      v$,
      responseErrors,
      showPassword,
      StoreFieldPropertyEnum,
      ValidationErrorsEnum,
      hideModal,
      showModal,
      onDataInputChange,
      onSaveClicked,
      togglePasswordVisibility,
    };
  },
});
