export enum OrderStatusLabelsEnum {
  All = 'All Statuses',
  InDelivery = 'Delivery In Progress',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Ready = 'Ready',
}

export enum OrderStatusValuesEnum {
  All = 'all',
  InDelivery = 3,
  Completed = 5,
  Canceled = 7,
  Ready = 17,
}

export enum SettelmentStatusValuesEnum {
  Settled = 'settled',
  NotSettled = 'not_settled',
}

export type ManageOrderListFilterState = {
  searchValue: string | number;
  selectedResultPerPage: Record<string, string>;
  orderStatus: Record<string, string>;
  createdDate: Record<string, string>;
  deliveryDate: Record<string, string>;
  targetPage: string;
};

export enum OrderListInputProperties {
  SearchValue = 'searchValue',
}

export enum OrderListSelectProperties {
  ResultPerPage = 'selectedResultPerPage',
  OrderStatus = 'orderStatus',
}

export enum ManageOrderDateRangeFormState {
  CreatedDate = 'createdDate',
  DeliveryDate = 'deliveryDate',
}

export enum ManageOrderDateRangeProperty {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export type SingleOrderType = {
  order_id: number;
  store_id: number;
  store_url: string;
  tracking_number: number;
  order_status: OrderStatusValuesEnum;
  create_date: string;
  delivery_date: string;
  customer: string;
  payment: string;
  total_order: number;
  zid_fees: number;
  settlement_status: SettelmentStatusValuesEnum;
};

export type OrderListType = {
  list: SingleOrderType[];
};

export interface OrdersFilterParamsInterface {
  searchTerm?: string | number;
  status?: string;
  createdFrom?: string;
  createdto?: string;
  deliveredFrom?: string;
  deliveredto?: string;
  pageSize?: string;
  cursor?: string;
}

export type OrdersFilterParamsType = {
  term: string | number;
  orderStatus: string;
  createdStartDate: string;
  createdEndDate: string;
  deliveryStartDate: string;
  deliveryEndDate: string;
  resultPerPage: string;
  targetPage: string;
};

export type ExportOrdersFilterParamsType = {
  term: string | number;
  orderStatus: string;
  createdStartDate: string;
  createdEndDate: string;
  deliveryStartDate: string;
  deliveryEndDate: string;
};
