import Vue from 'vue';
import { reactive, onBeforeMount } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { COUNTRIES_LIST } from '@/helpers/constants';
import router from '@/router/index';
import { getBankAccountInformation, updateBankAccountInformation } from '../api/bankAccount';
import BankAccountInfoModel from '../models/BankAccountInfoModel';

const useBankAccount = () => {
  const bankAccountForm = reactive({
    bankName: '',
    accountName: '',
    swiftCode: '',
    ibanNumber: '',
    country: {
      label: '',
      value: '',
    },
    city: '',
  });

  const rules = {
    bankName: {
      bankName: helpers.withMessage('Bank name is required', required),
    },
    accountName: {
      required,
      accountName: helpers.withMessage('Account name is not valid', (value: string) => {
        return /^[A-Za-z\s]+$/.test(value);
      }),
    },
    swiftCode: {
      swiftCode: helpers.withMessage('Swift code is required', required),
    },
    ibanNumber: {
      ibanNumber: helpers.withMessage('IBAN number is required', required),
    },
    country: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    city: {
      required,
      city: helpers.withMessage('City is not valid', (value: string) => {
        return /^[A-Za-z\s]+$/.test(value);
      }),
    },
  };

  const countriesList = reactive({
    options: COUNTRIES_LIST,
  });

  const v$ = useVuelidate(rules, bankAccountForm);

  onBeforeMount(async () => {
    const response = await getBankAccountInformation();
    if (!response.partnerBankAccount?.bank_name) return;
    bankAccountForm.bankName = response.partnerBankAccount.bank_name;
    bankAccountForm.accountName = response.partnerBankAccount.account_name;
    bankAccountForm.swiftCode = response.partnerBankAccount.swift_code;
    bankAccountForm.ibanNumber = response.partnerBankAccount.iban;
    bankAccountForm.country = {
      label: `${response.partnerBankAccount.country.charAt(0).toUpperCase()}${response.partnerBankAccount.country.slice(
        1,
      )}`,
      value: response.partnerBankAccount.country,
    };
    bankAccountForm.city = response.partnerBankAccount.city;
  });

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: 'bankName' | 'accountName' | 'swiftCode' | 'ibanNumber' | 'city',
  ) => {
    v$.value[dataProperty].$touch();
    bankAccountForm[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: 'country') => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    bankAccountForm[property].label = selectedValue.label;
    bankAccountForm[property].value = selectedValue.value;
  };

  const onBackClicked = () => {
    router.back();
  };

  const onSaveChangesClicked = async () => {
    const bankAccountInfo = new BankAccountInfoModel({
      bank_name: bankAccountForm.bankName,
      account_name: bankAccountForm.accountName,
      iban: bankAccountForm.ibanNumber,
      swift_code: bankAccountForm.swiftCode,
      city: bankAccountForm.city,
      country: bankAccountForm.country.value,
    });

    const response = await updateBankAccountInformation(bankAccountInfo);

    if (response.status !== 'success') {
      Vue.$toast.error('Failed to save bank account information changes');
      return;
    }

    Vue.$toast.success('Bank account information has been updated successfully');
  };

  return {
    bankAccountForm,
    countriesList,
    v$,
    onInputDataChange,
    onSelectionDataChange,
    onBackClicked,
    onSaveChangesClicked,
  };
};

export default useBankAccount;
