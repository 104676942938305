import httpClient from '@/api/httpClient';
import PartnershipResponseModel from '@/domains/partnership-details/models/PartnershipResponseModel';
import ErrorModel from '@/api/models/ErrorModel';
import {
  AgreeableItemInterface,
  AgreeableItemTypeEnum,
  AgreementStatusValueEnum,
  PartnershipBusinessDetailsInterface,
  PartnershipContactInfoState,
} from '@/domains/partnership-details/types/partnership.type';
import PartnershipAgreementsListResponseModel from '@/domains/partnership-details/models/PartnershipAgreementsListResponseModel';
import PartnershipAgreementPreviewResponseModel from '@/domains/partnership-details/models/PartnershipAgreementPreviewResponseModel';
import BaseModel from '@/api/models/BaseModel';
import { buildBusinessDetailsFormData } from '@/domains/partnership-details/helpers/formHelpers';

const ENDPOINT_URL = '/partners/partnership';
export const getPartnershipDetails = async (): Promise<PartnershipResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}`)
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const setPartnershipAuthorizedMember = async (
  memberId: number,
): Promise<PartnershipResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/assign-member`, { member_id: memberId })
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const setPartnershipContactInfo = async (
  data: PartnershipContactInfoState,
): Promise<PartnershipResponseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/update-contact-info`, {
      partnership_officer_name_ar: data.partnershipOfficerNameAr,
      partnership_officer_name_en: data.partnershipOfficerNameEn,
      partnership_officer_email: data.partnershipOfficerEmail,
      partnership_officer_mobile: data.partnershipOfficerMobile,
      with_financial_officer: data.withFinancialOfficer,
      financial_officer_name_ar: data.financialOfficerNameAr ?? null,
      financial_officer_name_en: data.financialOfficerNameEn ?? null,
      financial_officer_email: data.financialOfficerEmail ?? null,
      financial_officer_mobile: data.financialOfficerMobile ?? null,
    })
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const setPartnershipBusinessDetails = async (
  data: PartnershipBusinessDetailsInterface,
): Promise<PartnershipResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/update-business-details`, buildBusinessDetailsFormData(data))
    .then((response) => new PartnershipResponseModel(response))
    .catch((error) => error);
};

export const getPartnerAgreements = async (): Promise<PartnershipAgreementsListResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/agreements`)
    .then((response) => new PartnershipAgreementsListResponseModel(response))
    .catch((error) => error);
};

export const getItemAgreementPreview = async (
  itemId: number,
  itemType: AgreeableItemTypeEnum,
): Promise<PartnershipAgreementPreviewResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/agreements/preview?item_id=${itemId.toString()}&item_type=${itemType.toString()}`)
    .then((response) => new PartnershipAgreementPreviewResponseModel(response))
    .catch((error) => error);
};

export const initiateAgreementRequest = (
  item: AgreeableItemInterface,
  status: AgreementStatusValueEnum,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/agreements`, {
      item_type: item.type,
      item_id: item.id,
      status: status,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
