class PartnerInfoModel {
  public name: string;
  public email: string;
  public mobile: string;
  public country: string;
  public is_independent: string;
  public application_type: string;
  public looking_for_ideas: string;
  constructor(arg: any) {
    this.name = arg.name;
    this.email = arg.email;
    this.mobile = arg.mobile;
    this.country = arg.country;
    this.is_independent = arg.is_independent;
    this.application_type = arg.application_type;
    this.looking_for_ideas = arg.looking_for_ideas;
  }
}

export default PartnerInfoModel;
