import Vue from 'vue';
import { defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidButton,
  ZidCol,
  ZidContainer,
  ZidLoader,
  ZidPageHeader,
  ZidRow,
  ZidModal,
} from '@zidsa/ui';
import CircleCheckIcon from '@/assets/icons/domains/partnership-details/CircleCheckIcon.vue';
import ContactInformation from './partnership-details/contact-information/ContactInformation.vue';
import BusinessDetails from './partnership-details/business-details/BusinessDetails.vue';
import AuthorizedMember from './partnership-details/authorized-member/AuthorizedMember.vue';
import AgreementDetails from './partnership-details/agreement-details/AgreementDetails.vue';
import TabButton from './partnership-details/components/TabButton.vue';
import { getPartnershipDetails } from '@/domains/partnership-details/api/partnership';
import ErrorModel from '@/api/models/ErrorModel';
import {
  PartnershipBusinessDetailsInterface,
  PartnershipContactInfoState,
  PartnershipDetailsResponseType,
  PartnershipDetailsStepEnum,
  PartnershipDetailsTabsNamesEnum,
} from '@/domains/partnership-details/types/partnership.type';
import ExclamationMarkIcon from '@/assets/icons/domains/partnership-details/ExclamationMarkIcon.vue';

export default defineComponent({
  components: {
    ExclamationMarkIcon,
    TabButton,
    AuthorizedMember,
    ContactInformation,
    BusinessDetails,
    AgreementDetails,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BaseText,
    ZidButton,
    ZidContainer,
    ZidRow,
    ZidCol,
    BaseIcon,
    CircleCheckIcon,
    ZidLoader,
    ZidModal,
  },
  setup() {
    const isLoadingPartnershipDetails = ref(true);
    const currentStep = ref(PartnershipDetailsStepEnum.None);
    const partnershipInfoExists = ref(false);
    const activeTab = ref(PartnershipDetailsTabsNamesEnum.AuthorizedMember);
    const stepNotAllowedModalShow = ref(false);
    const businessDetailsData = reactive<PartnershipBusinessDetailsInterface>({
      businessType: null,
      companyNameAr: null,
      companyNameEn: null,
      country: null,
      cityId: null,
      addressLine1: null,
      addressLine2: null,
      district: null,
      postalCode: null,
      commercialRegisterNumber: null,
      commercialRegisterDoc: null,
      vatNumber: null,
      vatDoc: null,
      nationalIdDoc: null,
      freelancerDoc: null,
    });

    const contactInfoData = reactive<PartnershipContactInfoState>({
      partnershipOfficerNameAr: '',
      partnershipOfficerNameEn: '',
      partnershipOfficerEmail: '',
      partnershipOfficerMobile: '',
      financialOfficerNameAr: '',
      financialOfficerNameEn: '',
      financialOfficerEmail: '',
      financialOfficerMobile: '',
      withFinancialOfficer: false,
    });

    onBeforeMount(async () => {
      await loadPartnershipDetails();
    });

    const loadPartnershipDetails = async () => {
      const response = await getPartnershipDetails();
      if (response instanceof ErrorModel || typeof response.partnership_details === typeof undefined) {
        Vue.$toast.error('Error In loading partnership details');
        return;
      }
      partnershipInfoExists.value = response.partnership_details.id !== null;
      currentStep.value = response.partnership_details.step ?? PartnershipDetailsStepEnum.AuthorizedMember;
      setDefaultActiveTab(response.partnership_details);
      fillBusinessDetailsData(response.partnership_details);
      fillContactInfoData(response.partnership_details);
      isLoadingPartnershipDetails.value = false;
    };

    const setDefaultActiveTab = (partnershipDetails: PartnershipDetailsResponseType) => {
      if (!partnershipDetails.step) {
        activeTab.value = PartnershipDetailsTabsNamesEnum.AuthorizedMember;
        return;
      }
      if (partnershipDetails.step === PartnershipDetailsStepEnum.ContactInfo) {
        activeTab.value = PartnershipDetailsTabsNamesEnum.ContactInfo;
        return;
      }
      if (partnershipDetails.step === PartnershipDetailsStepEnum.BusinessDetails) {
        activeTab.value = PartnershipDetailsTabsNamesEnum.BusinessDetails;
        return;
      }
      if (partnershipDetails.step === PartnershipDetailsStepEnum.Agreement) {
        activeTab.value = PartnershipDetailsTabsNamesEnum.AgreementDetails;
        return;
      }
    };

    const onTabClick = (tab: PartnershipDetailsTabsNamesEnum) => {
      if (!isTabAllowedForCurrentStep(tab)) {
        stepNotAllowedModalShow.value = true;
        return;
      }
      activeTab.value = tab;
    };

    const isTabAllowedForCurrentStep = (tab: PartnershipDetailsTabsNamesEnum): boolean => {
      if (!currentStep.value && partnershipInfoExists.value) {
        return true;
      }
      switch (tab) {
        case PartnershipDetailsTabsNamesEnum.AuthorizedMember:
          return currentStep.value === PartnershipDetailsStepEnum.AuthorizedMember;
        case PartnershipDetailsTabsNamesEnum.ContactInfo:
          return currentStep.value >= PartnershipDetailsStepEnum.ContactInfo;
        case PartnershipDetailsTabsNamesEnum.BusinessDetails:
          return currentStep.value >= PartnershipDetailsStepEnum.BusinessDetails;
        case PartnershipDetailsTabsNamesEnum.AgreementDetails:
          return currentStep.value >= PartnershipDetailsStepEnum.Agreement;
        case PartnershipDetailsTabsNamesEnum.BankInformation:
          return false;
      }
    };

    const fillBusinessDetailsData = (partnershipDetails: PartnershipDetailsResponseType) => {
      businessDetailsData.businessType = partnershipDetails.business_type;
      businessDetailsData.companyNameAr = partnershipDetails.company?.name.ar ?? '';
      businessDetailsData.companyNameEn = partnershipDetails.company?.name.en ?? '';
      businessDetailsData.country = partnershipDetails.company?.address?.country_code ?? 'SA';
      businessDetailsData.cityId = partnershipDetails.company?.address?.city_id ?? null;
      businessDetailsData.addressLine1 = partnershipDetails.company?.address?.line_1 ?? '';
      businessDetailsData.addressLine2 = partnershipDetails.company?.address?.line_2 ?? '';
      businessDetailsData.district = partnershipDetails.company?.address?.district ?? '';
      businessDetailsData.postalCode = partnershipDetails.company?.address?.postal_code ?? '';
      businessDetailsData.commercialRegisterNumber = partnershipDetails.company?.cr_number ?? '';
      businessDetailsData.commercialRegisterDoc = partnershipDetails.company?.cr_doc ?? '';
      businessDetailsData.vatNumber = partnershipDetails.company?.vat_number ?? '';
      businessDetailsData.vatDoc = partnershipDetails.company?.vat_doc ?? '';
      businessDetailsData.nationalIdDoc = partnershipDetails.national_id_doc ?? '';
      businessDetailsData.freelancerDoc = partnershipDetails.freelancer_doc ?? '';
    };
    const fillContactInfoData = (partnershipDetails: PartnershipDetailsResponseType) => {
      contactInfoData.partnershipOfficerNameAr = partnershipDetails.partnership_officer?.name.ar ?? '';
      contactInfoData.partnershipOfficerNameEn = partnershipDetails.partnership_officer?.name.en ?? '';
      contactInfoData.partnershipOfficerEmail = partnershipDetails.partnership_officer?.email ?? '';
      contactInfoData.partnershipOfficerMobile = partnershipDetails.partnership_officer?.mobile ?? '';
      contactInfoData.financialOfficerNameAr = partnershipDetails.financial_officer?.name.ar ?? '';
      contactInfoData.financialOfficerNameEn = partnershipDetails.financial_officer?.name.en ?? '';
      contactInfoData.financialOfficerEmail = partnershipDetails.financial_officer?.email ?? '';
      contactInfoData.financialOfficerMobile = partnershipDetails.financial_officer?.mobile ?? '';
      contactInfoData.withFinancialOfficer = !!partnershipDetails.financial_officer?.mobile;
    };

    return {
      isLoadingPartnershipDetails,
      partnershipInfoExists,
      activeTab,
      currentStep,
      stepNotAllowedModalShow,
      PartnershipDetailsTabsNamesEnum,
      businessDetailsData,
      contactInfoData,
      onTabClick,
    };
  },
});
