export enum ActivationStatusLabelsEnum {
  All = 'All Statuses',
  Activated = 'Activated',
  NotActivated = 'Not Activated',
}

export enum ActivationStatusValuesEnum {
  All = 'all',
  Activated = '1',
  NotActivated = '0',
}

export enum SettelmentStatusLabelsEnum {
  All = 'All Statuses',
  Settled = 'Settled',
  NotSettled = 'Not Settled',
}

export enum SettelmentStatusValuesEnum {
  All = 'all',
  Settled = '1',
  NotSettled = '0',
}

export enum DefaultActivationAndSettelmentStatusValueEnum {
  All = 'all',
}

export type SelectedApplicationType = {
  label: string;
  value: string;
};

export type ManageActivationListFilterState = {
  searchValue: string | number;
  selectedResultPerPage: Record<string, string>;
  selectedApp: Record<string, string>;
  activationStatus: Record<string, string>;
  settelmentStatus: Record<string, string>;
  activationDate: Record<string, string>;
  targetPage: string;
};

export enum ActivationListInputProperties {
  SearchValue = 'searchValue',
}

export enum ActivationListSelectProperties {
  ResultPerPage = 'selectedResultPerPage',
  SelectedApp = 'selectedApp',
  ActivationStatus = 'activationStatus',
  SettelmentStatus = 'settelmentStatus',
}

export enum ManageActivationDateRangeFormState {
  ActivationDate = 'activationDate',
}

export enum ManageActivationDateRangeProperty {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export type SingleActivationType = {
  id: number;
  store_id: number;
  store_url: string;
  store_name: string;
  activation_status: ActivationStatusValuesEnum;
  activation_date: string;
  zid_fee: number;
  settlement_status: SettelmentStatusValuesEnum;
};

export type ActivationListType = {
  list: SingleActivationType[];
};

export type ActivationsFilterParamsType = {
  term: string | number;
  activationStatus: string;
  settelmentStatus: string;
  activationStartDate: string;
  activationEndDate: string;
  resultPerPage: string | number;
  targetPage: string;
};

export type ExportActivationsFilterParamsType = {
  term: string | number;
  activationStatus: string;
  settelmentStatus: string;
  activationStartDate: string;
  activationEndDate: string;
};

export interface ActivationsFilterParamsInterface {
  store_id?: string | number;
  activation_status?: string;
  settlement_status?: string;
  activation_date_from?: string;
  activation_date_to?: string;
  pageSize?: string | number;
  cursor?: string;
}
