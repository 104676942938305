



























import { defineComponent, reactive, onBeforeMount, ref } from '@vue/composition-api';
import NotificationItem from '../../../components/notification-item/NotificationItem.vue';
import BaseText from '../../../components/text/BaseText.vue';
import { getNotificationsList } from '@/api/top-level-apis/notifications/notifications';
import NotificationType from '@/types/components/Notification.type';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ZidLoader } from '@zidsa/ui';
import { NotificationTypeEnum } from '@/types/components/Notification.type';
import ErrorModel from '@/api/models/ErrorModel';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    NotificationItem,
    BaseText,
    ZidLoader,
  },
  setup() {
    const isFetchingNotifications = ref(false);
    const notificationsList = reactive<Record<string, NotificationType[]>>({ data: [] });
    const { name } = useGetters(['name']);
    onBeforeMount(async () => {
      isFetchingNotifications.value = true;
      const response = await getNotificationsList();
      if (response instanceof ErrorModel || !response.notes) {
        isFetchingNotifications.value = false;
        return;
      }
      notificationsList.data = response.notes;
      isFetchingNotifications.value = false;
    });

    return {
      name,
      notificationsList,
      NotificationTypeEnum,
      isFetchingNotifications,
    };
  },
});
