import { SubscriptionStatusLabelsEnum, SubscriptionStatusValuesEnum } from '../types/subscriptionsList.type';
export const SUBSCRIPTION_STATUS_DROPDOWN = [
  { label: SubscriptionStatusLabelsEnum.All, value: SubscriptionStatusValuesEnum.All },
  { label: SubscriptionStatusLabelsEnum.Upgrade, value: SubscriptionStatusValuesEnum.Upgrade },
  { label: SubscriptionStatusLabelsEnum.Active, value: SubscriptionStatusValuesEnum.Active },
  { label: SubscriptionStatusLabelsEnum.Inactive, value: SubscriptionStatusValuesEnum.Inactive },
  { label: SubscriptionStatusLabelsEnum.Renew, value: SubscriptionStatusValuesEnum.Renew },
  { label: SubscriptionStatusLabelsEnum.Warning, value: SubscriptionStatusValuesEnum.Warning },
  { label: SubscriptionStatusLabelsEnum.Suspend, value: SubscriptionStatusValuesEnum.Suspend },
  { label: SubscriptionStatusLabelsEnum.Expired, value: SubscriptionStatusValuesEnum.Expired },
  { label: SubscriptionStatusLabelsEnum.Refund, value: SubscriptionStatusValuesEnum.Refund },
];
