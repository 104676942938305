import WebhookLogsResponseModel from '@/domains/webhooks/models/WebhookLogsResponseModel';
import ErrorModel from '@/api/models/ErrorModel';
import httpClient from '@/api/httpClient';
import { WebhookLogsParamInterface } from '@/domains/webhooks/types/webhookLogs.types';

const ENDPOINT_URL = '/partners/webhooks';

export const getAppWebhookLogs = async (
  params: WebhookLogsParamInterface,
): Promise<WebhookLogsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/histories`, { params: params })
    .then((response) => new WebhookLogsResponseModel(response))
    .catch((error) => error);
};
