import BaseModel from '@/api/models/BaseModel';
import { WebhookLogsEventInterface } from '@/domains/webhooks/types/webhookLogs.types';

export default class WebhookLogsResponseModel extends BaseModel {
  public events: WebhookLogsEventInterface[];

  constructor(arg: any) {
    super(arg);
    this.events = arg.events;
  }
}
