import Vue from 'vue';
import { computed, onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { PAYOUT_TYPES_LIST } from './tabsInfo';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import {
  ApplicationInterface,
  ManagePayoutDetailsState,
  ManagePayoutListState,
  ManagePayoutSearchProperty,
  NumericFieldValueInterface,
  PayoutsListType,
  PayoutTypeValuesEnum,
  SettlementStatusLabelsEnum,
  SettlementStatusValuesEnum,
} from '../types/payouts.type';
import ErrorModel from '@/api/models/ErrorModel';
import { getPartnerAppsList } from '@/api/top-level-apis/application/applicationsList';
import {
  downloadPayoutInvoice,
  downloadPayoutReceipt,
  exportPayoutDetails,
  getPayoutList,
  submitPayoutInvoice,
  submitPayoutReceipt,
} from '@/domains/payouts/api/payout';
import { partnerThemesList } from '@/api/top-level-apis/themes/partnerThemesList';
import dayjs from 'dayjs';

const usePayouts = () => {
  const FinanceGuidelinesUrl = 'https://help-partner.zid.sa/en/articles/8750045-get-started-with-partner-payouts';
  const DEFAULT_ITEM_PER_PAGE = 10;
  const RESULT_PER_PAGE = [];
  const loadingAppsList = ref(false);
  const loadingThemesList = ref(false);
  const loadingPayoutList = ref(false);
  const showFiltersBox = ref(false);
  const selectedPayoutId = ref('');
  const selectedAppsList = reactive<Record<string, ApplicationInterface[]>>({ list: [] });
  const selectedThemesList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const themesList = reactive<Record<string, NumericFieldValueInterface[]>>({ list: [] });
  const selectedPayoutType = ref(PayoutTypeValuesEnum.ShippingApps);
  const selectedStatuses = reactive<Record<string, SettlementStatusValuesEnum[]>>({ list: [] });
  const appsList = reactive<Record<string, ApplicationInterface[]>>({ list: [] });
  const filteredAppsList = reactive<Record<string, ApplicationInterface[]>>({ list: [] });
  const totalPages = ref(0);
  const totalResults = ref(0);
  const showPayoutDetailsModal = ref(false);
  const isInvoiceFileInvalid = ref(false);
  const isInitialInvoiceFileLoading = ref(false);
  const isReceiptFileInvalid = ref(false);
  const isInitialReceiptFileLoading = ref(false);
  const isExportPayoutLoading = ref(false);
  const selectedPayoutForExport = ref('');

  const originalPayoutList = reactive<PayoutsListType>({
    list: [],
  });

  for (let i = DEFAULT_ITEM_PER_PAGE; i <= 100; i += DEFAULT_ITEM_PER_PAGE) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const payoutTypes = reactive({
    options: PAYOUT_TYPES_LIST,
  });

  const ManagePayoutListState = reactive<ManagePayoutListState>({
    searchValue: '',
    selectedResultPerPage: {
      label: `${DEFAULT_ITEM_PER_PAGE}`,
      value: `${DEFAULT_ITEM_PER_PAGE}`,
    },
    app: [],
    theme: [],
    settlementStatus: [],
    payoutDate: '',
    targetPage: 1,
  });

  const ManagePayoutDetailsState = reactive<ManagePayoutDetailsState>({
    payoutStartDate: '',
    payoutEndDate: '',
    newInvoiceFile: null,
    newReceiptFile: null,
    hasInvoice: false,
    hasReceipt: false,
    canUploadInvoice: false,
    canUploadReceipt: false,
    payoutStatus: null,
    rejectionNote: null,
  });

  const rules = {
    payoutStartDate: {},
    payoutEndDate: {},
    newInvoiceFile: {
      required: helpers.withMessage('Invoice file is required', () => {
        return !!ManagePayoutDetailsState.newInvoiceFile || !!ManagePayoutDetailsState.newReceiptFile;
      }),
    },
    newReceiptFile: {
      required: helpers.withMessage('Receipt file is required', () => {
        return !!ManagePayoutDetailsState.newInvoiceFile || !!ManagePayoutDetailsState.newReceiptFile;
      }),
    },
    hasInvoice: {},
    hasReceipt: {},
    canUploadInvoice: {},
    canUploadReceipt: {},
  };

  const v$ = useVuelidate(rules, ManagePayoutDetailsState);

  onBeforeMount(async () => {
    await getAppsList();
    await getThemesList();
    await getAllPayouts();
  });

  watch(
    () => [
      ManagePayoutListState.searchValue,
      ManagePayoutListState.selectedResultPerPage,
      ManagePayoutListState.targetPage,
    ],
    async () => {
      await getAllPayouts();
    },
    { deep: true },
  );

  const isAllStatusesChecked = computed(() => {
    return (
      (selectedPayoutType.value === PayoutTypeValuesEnum.ShippingApps && selectedStatuses.list.length === 5) ||
      (selectedPayoutType.value !== PayoutTypeValuesEnum.ShippingApps && selectedStatuses.list.length === 4)
    );
  });

  const onSelectedPayoutTypeChange = async (type: PayoutTypeValuesEnum) => {
    selectedPayoutType.value = type;
    resetAllFilters();
    resetTargetPage();
    updateAvailableAppsList();
    await getAllPayouts();
  };

  const onFilterResultsClick = () => {
    showFiltersBox.value = true;
  };

  const onCancelFiltersButtonClick = () => {
    showFiltersBox.value = false;
  };

  const onInputDataChange = (keyUp: KeyboardEvent, dataProperty: ManagePayoutSearchProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      ManagePayoutListState[dataProperty] = target.value;
      ManagePayoutListState.targetPage = 1;
    }
  };

  const onSelectApp = (selectedApp: ApplicationInterface) => {
    if (selectedAppsList.list.some((app) => app.value === selectedApp.value)) return;
    selectedAppsList.list.push(selectedApp);
    setSelectedAppsState();
  };

  const onUnSelectApp = (unselectedApp: ApplicationInterface) => {
    selectedAppsList.list = selectedAppsList.list.filter((app) => app.value !== unselectedApp.value);
    setSelectedAppsState();
  };

  const setSelectedAppsState = () => {
    ManagePayoutListState.app = [...selectedAppsList.list.map((app) => app.value)];
  };

  const isSelectedApp = (appId: number): boolean => {
    return selectedAppsList.list.some((app) => app.value === appId);
  };

  const isSelectedStatus = (status: SettlementStatusValuesEnum) => {
    return selectedStatuses.list.some((selectedStatus) => selectedStatus === status);
  };

  const onSelectTheme = (selectedTheme: NumericFieldValueInterface) => {
    if (selectedThemesList.list.some((theme) => theme.value === selectedTheme.value)) return;
    selectedThemesList.list.push(selectedTheme);
    setSelectedThemesState();
  };

  const onUnSelectTheme = (unselectedTheme: NumericFieldValueInterface) => {
    selectedThemesList.list = selectedThemesList.list.filter((theme) => theme.value !== unselectedTheme.value);
    setSelectedThemesState();
  };

  const setSelectedThemesState = () => {
    ManagePayoutListState.theme = [...selectedThemesList.list.map((theme) => theme.value)];
  };

  const isSelectedTheme = (themeId: number): boolean => {
    return selectedThemesList.list.some((theme) => theme.value === themeId);
  };

  const onStatusChange = (isChecked: boolean, checkedStatus: SettlementStatusValuesEnum) => {
    if (!isChecked) {
      selectedStatuses.list = selectedStatuses.list.filter((status) => status !== checkedStatus);
      return;
    }
    if (isSelectedStatus(checkedStatus)) {
      return;
    }
    selectedStatuses.list.push(checkedStatus);
  };

  const onSelectAllStatuses = (isChecked: boolean) => {
    if (!isChecked) {
      selectedStatuses.list = [];
      return;
    }
    selectedStatuses.list = [
      SettlementStatusValuesEnum.Pending,
      SettlementStatusValuesEnum.InReview,
      SettlementStatusValuesEnum.Rejected,
      SettlementStatusValuesEnum.Settled,
    ];
    if (selectedPayoutType.value === PayoutTypeValuesEnum.ShippingApps) {
      selectedStatuses.list.push(SettlementStatusValuesEnum.PaymentRequired);
    }
  };

  const resetAllFilters = () => {
    selectedStatuses.list = [];
    selectedThemesList.list = [];
    selectedAppsList.list = [];
    ManagePayoutListState.app = [];
    ManagePayoutListState.theme = [];
    ManagePayoutListState.payoutDate = '';
    ManagePayoutListState.searchValue = '';
    ManagePayoutListState.settlementStatus = [];
  };

  const resetTargetPage = () => (ManagePayoutListState.targetPage = 1);

  const onResultPerPageChange = (perPage: Record<string, string>) => {
    ManagePayoutListState.selectedResultPerPage = perPage;
    resetTargetPage();
  };

  const onApplyFiltersClick = async () => {
    showFiltersBox.value = false;
    resetTargetPage();
    await getAllPayouts();
  };

  const onInvoiceFileChange = (file: File[]) => {
    if (!file || file.length > 1) {
      isInvoiceFileInvalid.value = true;
      return;
    }
    ManagePayoutDetailsState.newInvoiceFile = file[0];
    isInvoiceFileInvalid.value = !file[0] || file[0].size > 5000000;
  };

  const onInvoiceFileRemoved = () => {
    ManagePayoutDetailsState.newInvoiceFile = null;
    isInvoiceFileInvalid.value = true;
  };

  const onReceiptFileChange = (file: File[]) => {
    if (!file || file.length > 1) {
      isReceiptFileInvalid.value = true;
      return;
    }
    ManagePayoutDetailsState.newReceiptFile = file[0];
    isReceiptFileInvalid.value = !file[0] || file[0].size > 5000000;
  };

  const onReceiptFileRemoved = () => {
    ManagePayoutDetailsState.newReceiptFile = null;
    isInvoiceFileInvalid.value = true;
  };

  const updateAvailableAppsList = () => {
    filteredAppsList.list = [];
    if (selectedPayoutType.value == PayoutTypeValuesEnum.PublicOrPrivateApps) {
      filteredAppsList.list = appsList.list?.filter((app) => app.type === 0) || [];
    } else if (selectedPayoutType.value == PayoutTypeValuesEnum.ShippingApps) {
      filteredAppsList.list = appsList.list?.filter((app) => app.type === 2) || [];
    }
  };

  const getAppsList = async () => {
    loadingAppsList.value = true;
    appsList.list = [];
    const response = await getPartnerAppsList();
    if (response instanceof ErrorModel || !response.apps) {
      Vue.$toast.error('Unable to get partner apps');
      loadingAppsList.value = false;
      return;
    }
    appsList.list = response.apps.map((app) => {
      return { label: app.name, value: app.id, type: app.type };
    });
    updateAvailableAppsList();
    loadingAppsList.value = false;
  };

  const getThemesList = async () => {
    loadingThemesList.value = true;
    themesList.list = [];
    const response = await partnerThemesList();
    if (response instanceof ErrorModel || !response.themes) {
      Vue.$toast.error('Unable to get partner themes');
      loadingThemesList.value = false;
      return;
    }
    themesList.list = response.themes.map((theme) => {
      return { label: theme.name, value: theme.id };
    });
    loadingThemesList.value = false;
  };

  const getAllPayouts = async () => {
    const selectedPayoutMonth = ManagePayoutListState.payoutDate
      ? new Date(ManagePayoutListState.payoutDate).toISOString().slice(0, 7)
      : '';
    loadingPayoutList.value = true;
    originalPayoutList.list = [];
    const parameters = {
      searchTerm: ManagePayoutListState.searchValue,
      item:
        selectedPayoutType.value === PayoutTypeValuesEnum.Themes
          ? ManagePayoutListState.theme
          : ManagePayoutListState.app,
      transaction_type: selectedPayoutType.value,
      status: selectedStatuses.list,
      date: selectedPayoutMonth,
      targetPage: ManagePayoutListState.targetPage,
      resultPerPage: ManagePayoutListState.selectedResultPerPage.value,
    };
    const response = await getPayoutList(parameters);
    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Unable to get payout list');
      loadingPayoutList.value = false;
      return;
    }
    originalPayoutList.list = [...response.payload.payouts];
    totalPages.value = response.payload.pagination.last_page;
    totalResults.value = response.payload.pagination.total;
    loadingPayoutList.value = false;
  };

  const createAndTriggerDownloadLink = (file: Blob, fileName: string) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const onDownloadBtnClicked = async (payoutId: string) => {
    isExportPayoutLoading.value = true;
    selectedPayoutForExport.value = payoutId;
    const response = await exportPayoutDetails(payoutId);
    if (response instanceof ErrorModel) {
      Vue.$toast.error('Unable to download the payout details');
      isExportPayoutLoading.value = false;
      selectedPayoutForExport.value = '';
      return;
    }
    Vue.$toast.success('You will receive an email with the report file shortly');
    isExportPayoutLoading.value = false;
    selectedPayoutForExport.value = '';
  };

  const togglePayoutDetailsModal = (
    payoutId?: string,
    payoutMonth?: string,
    hasInvoice?: boolean,
    hasReceipt?: boolean,
    canUploadInvoice?: boolean,
    canUploadReceipt?: boolean,
    payoutStatus?: number,
    rejectionNote?: string | null,
  ) => {
    selectedPayoutId.value = payoutId ?? '';
    if (payoutMonth) {
      const [year, month] = payoutMonth.split('-').map(Number);
      ManagePayoutDetailsState.payoutStartDate = dayjs(`${payoutMonth}-01`).format('YYYY-MM-DD');
      ManagePayoutDetailsState.payoutEndDate = dayjs()
        .set('year', year)
        .set('month', month - 1)
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    ManagePayoutDetailsState.hasInvoice = hasInvoice ?? false;
    ManagePayoutDetailsState.hasReceipt = hasReceipt ?? false;
    ManagePayoutDetailsState.canUploadInvoice = canUploadInvoice ?? false;
    ManagePayoutDetailsState.canUploadReceipt = canUploadReceipt ?? false;
    ManagePayoutDetailsState.payoutStatus = payoutStatus ?? null;
    ManagePayoutDetailsState.rejectionNote = rejectionNote ?? null;

    showPayoutDetailsModal.value = !showPayoutDetailsModal.value;
  };

  const onDownloadInvoiceBtnClicked = async () => {
    if (!selectedPayoutId.value) return;
    const response = await downloadPayoutInvoice(selectedPayoutId.value);
    if (response instanceof ErrorModel || !(response instanceof Blob) || !response.size) {
      Vue.$toast.error('Unable to download the invoice');
      return;
    }
    createAndTriggerDownloadLink(response, 'invoice.pdf');
  };

  const onDownloadReceiptBtnClicked = async () => {
    if (!selectedPayoutId.value) return;
    const response = await downloadPayoutReceipt(selectedPayoutId.value);
    if (response instanceof ErrorModel || !(response instanceof Blob) || !response.size) {
      Vue.$toast.error('Unable to download the receipt');
      return;
    }
    createAndTriggerDownloadLink(response, 'receipt.pdf');
  };

  const onSubmitBtnClicked = async () => {
    let fileType = '';
    let response = null;

    const { newInvoiceFile, newReceiptFile } = ManagePayoutDetailsState;

    if (newInvoiceFile && !newReceiptFile) {
      fileType = 'Invoice';
      response = await submitPayoutInvoice(selectedPayoutId.value, newInvoiceFile);
    } else if (newReceiptFile && !newInvoiceFile) {
      fileType = 'Receipt';
      response = await submitPayoutReceipt(selectedPayoutId.value, newReceiptFile);
    }

    if (response instanceof ErrorModel || !response) {
      Vue.$toast.error(`Unable to submit the ${fileType}`);
      return;
    }

    Vue.$toast.success(`${fileType} submitted successfully`);
    togglePayoutDetailsModal();
    await getAllPayouts();
  };

  const isPayoutCurrentlyExporting = (payoutId: string) => {
    return isExportPayoutLoading.value && selectedPayoutForExport.value === payoutId;
  };

  return {
    v$,
    payoutTypes,
    showFiltersBox,
    selectedStatuses,
    loadingAppsList,
    loadingThemesList,
    loadingPayoutList,
    originalPayoutList,
    selectedPayoutType,
    ManagePayoutListState,
    ManagePayoutSearchProperty,
    PayoutTypeValuesEnum,
    SettlementStatusLabelsEnum,
    SettlementStatusValuesEnum,
    ManagePayoutDetailsState,
    isInitialInvoiceFileLoading,
    isInvoiceFileInvalid,
    isInitialReceiptFileLoading,
    isReceiptFileInvalid,
    showPayoutDetailsModal,
    FinanceGuidelinesUrl,
    filteredAppsList,
    selectedAppsList,
    themesList,
    selectedThemesList,
    totalPages,
    totalResults,
    resultPerPage,
    isAllStatusesChecked,
    onSelectApp,
    onUnSelectApp,
    isSelectedApp,
    onSelectTheme,
    onUnSelectTheme,
    isSelectedTheme,
    onStatusChange,
    isSelectedStatus,
    onSubmitBtnClicked,
    onInputDataChange,
    onApplyFiltersClick,
    onSelectAllStatuses,
    onFilterResultsClick,
    onResultPerPageChange,
    onDownloadBtnClicked,
    onInvoiceFileChange,
    onInvoiceFileRemoved,
    onReceiptFileChange,
    onReceiptFileRemoved,
    togglePayoutDetailsModal,
    onCancelFiltersButtonClick,
    onSelectedPayoutTypeChange,
    onDownloadInvoiceBtnClicked,
    onDownloadReceiptBtnClicked,
    isPayoutCurrentlyExporting,
  };
};

export default usePayouts;
