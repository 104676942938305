import { RootPermissionsEnum } from './root-permissions.enum';

// all roles
export const PERSISTENT_PERMISSIONS = [
  RootPermissionsEnum.LogIn,
  RootPermissionsEnum.Logout,
  RootPermissionsEnum.Register,
  RootPermissionsEnum.ForgotPassword,
  RootPermissionsEnum.ChangePassword,
  RootPermissionsEnum.VerifyEmail,
  RootPermissionsEnum.NotFound,
  RootPermissionsEnum.NotAuthorized,
];
// /all roles

// partner partitioned permissions
export const PERSISTENT_PARTNER_PERMISSIONS = [
  RootPermissionsEnum.Home,
  RootPermissionsEnum.Profile,
  RootPermissionsEnum.Notifications,
  RootPermissionsEnum.Help,
];

export const PARTNER_NORMAL_APPS_PERMISSIONS = [
  RootPermissionsEnum.Applications,
  RootPermissionsEnum.CreateApplication,
  RootPermissionsEnum.CreateApplication_StepsContainer,
  RootPermissionsEnum.CreateApplication_GeneralSettings_Normal,
  RootPermissionsEnum.CreateApplication_GeneralSettings_Javascript,
  RootPermissionsEnum.CreateApplication_AppDetails,
  RootPermissionsEnum.CreateApplication_WebhookManagement,
  RootPermissionsEnum.CreateApplication_PlansManagement,
  RootPermissionsEnum.CreateApplication_Publish,
  RootPermissionsEnum.WebhookLogs,
];

export const PARTNER_SHIPPING_APPS_PERMISSIONS = [
  RootPermissionsEnum.CreateShippingApplication,
  RootPermissionsEnum.CreateShippingApplication_StepsContainer,
  RootPermissionsEnum.CreateShippingApplication_CompanyInfo,
  RootPermissionsEnum.CreateShippingApplication_GeneralSettings,
  RootPermissionsEnum.CreateShippingApplication_AboutCompany,
  RootPermissionsEnum.CreateShippingApplication_ShippingOptionDetails,
  RootPermissionsEnum.CreateShippingApplication_WebhookManagement,
  RootPermissionsEnum.CreateShippingApplication_Publish,
];

export const PARTNER_THEMES_PERMISSIONS = [
  RootPermissionsEnum.ThemesManagement,
  RootPermissionsEnum.ThemeInfoContainer,
];

export const PARTNER_TEAM_MEMBERS_PERMISSIONS = [RootPermissionsEnum.TeamMembers, RootPermissionsEnum.MemberDetails];

export const PARTNER_STORES_PERMISSIONS = [RootPermissionsEnum.DevelopmentStore];

export const PARTNER_FINANCIALS_PERMISSIONS = [
  RootPermissionsEnum.Dashboard,
  RootPermissionsEnum.Subscriptionslist,
  RootPermissionsEnum.ShippingDashboard,
  RootPermissionsEnum.OrdersManagement,
  RootPermissionsEnum.ActivationsManagement,
  RootPermissionsEnum.ThemesDashboard,
  RootPermissionsEnum.ThemesPurchaseList,
  RootPermissionsEnum.Payouts,
];

export const PARTNER_PARTNERSHIPS_PERMISSIONS = [
  RootPermissionsEnum.partnershipDetails,
  RootPermissionsEnum.BankAccount,
];
// /partner partitioned permissions
