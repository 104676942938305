import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import CheckIcon from '@/assets/icons/domains/subscriptions-list/CheckIcon.vue';
import CloseIcon from '@/assets/icons/domains/subscriptions-list/CloseIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidDatepicker,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import ExcelIcon from '@/assets/icons/domains/partnership-admin/ExcelIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useActivationsManagement from '../helpers/useActivationsManagement';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    CheckIcon,
    CloseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidDatepicker,
    ExcelIcon,
    SearchIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },

  setup() {
    return {
      ...useActivationsManagement(),
    };
  },
});
