import dayjs from 'dayjs';
import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import OrdersResponseModel from '../models/OrdersResponseModel';
import {
  OrdersFilterParamsType,
  OrdersFilterParamsInterface,
  ExportOrdersFilterParamsType,
} from '../types/ordersManagement.type';

const ENDPOINT_URL = '/market/shipping/orders';

export const getAllOrders = (filters: OrdersFilterParamsType): Promise<OrdersResponseModel | ErrorModel> => {
  const filterParams: OrdersFilterParamsInterface = {};

  if (filters.term) {
    filterParams.searchTerm = filters.term;
  }
  if (filters.orderStatus) {
    filterParams.status = filters.orderStatus;
  }
  if (filters.createdStartDate) {
    filterParams.createdFrom = dayjs(filters.createdStartDate).format('DD/MM/YYYY');
  }
  if (filters.createdEndDate) {
    filterParams.createdto = dayjs(filters.createdEndDate).format('DD/MM/YYYY');
  }
  if (filters.deliveryStartDate) {
    filterParams.deliveredFrom = dayjs(filters.deliveryStartDate).format('DD/MM/YYYY');
  }
  if (filters.deliveryEndDate) {
    filterParams.deliveredto = dayjs(filters.deliveryEndDate).format('DD/MM/YYYY');
  }
  if (filters.resultPerPage) {
    filterParams.pageSize = filters.resultPerPage;
  }
  if (filters.targetPage) {
    filterParams.cursor = filters.targetPage;
  }

  return httpClient
    .get(`${ENDPOINT_URL}`, {
      params: filterParams,
    })
    .then((response) => new OrdersResponseModel(response))
    .catch((error) => error);
};

export const exportOrdersList = (filters: ExportOrdersFilterParamsType): Promise<BaseModel | ErrorModel> => {
  const filterParams: OrdersFilterParamsInterface = {};

  if (filters.term) {
    filterParams.searchTerm = filters.term;
  }
  if (filters.orderStatus) {
    filterParams.status = filters.orderStatus;
  }
  if (filters.createdStartDate) {
    filterParams.createdFrom = dayjs(filters.createdStartDate).format('DD/MM/YYYY');
  }
  if (filters.createdEndDate) {
    filterParams.createdto = dayjs(filters.createdEndDate).format('DD/MM/YYYY');
  }
  if (filters.deliveryStartDate) {
    filterParams.deliveredFrom = dayjs(filters.deliveryStartDate).format('DD/MM/YYYY');
  }
  if (filters.deliveryEndDate) {
    filterParams.deliveredto = dayjs(filters.deliveryEndDate).format('DD/MM/YYYY');
  }

  return httpClient
    .get(`${ENDPOINT_URL}/export`, {
      params: filterParams,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
