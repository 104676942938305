import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import TeamMemberDetailsModel from '../models/TeamMemberDetailsModel';
import TeamMemberDetailsResponse from '../models/TeamMemberDetailsResponse';
import TeamMembersResponseModel from '../models/TeamMembersResponseModel';

const ENDPOINT_URL = '/market';

export const addTeamMember = (
  teamMemberInfo: TeamMemberDetailsModel,
): Promise<TeamMembersResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/team-members`, teamMemberInfo)
    .then((response) => new TeamMembersResponseModel(response))
    .catch((error) => error);
};

export const editTeamMember = (
  teamMemberId: number,
  teamMemberInfo: TeamMemberDetailsModel,
): Promise<TeamMembersResponseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_URL}/team-members/${teamMemberId}`, teamMemberInfo)
    .then((response) => new TeamMembersResponseModel(response))
    .catch((error) => error);
};

export const getTeamMemberInfo = (teamMemberId: number): Promise<TeamMemberDetailsResponse | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/partner/${teamMemberId}`)
    .then((response) => new TeamMemberDetailsResponse(response))
    .catch((error) => error);
};

export const validateEmail = (email: string): Promise<boolean | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/validate-email`, { email })
    .then((response) => response)
    .catch((error) => error);
};
