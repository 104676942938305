import { defineComponent } from '@vue/composition-api';
import useMemberDetails from '../../helpers/member-details/useMemberDetails';
import {
  ZidLoader,
  ZidButton,
  ZidPageHeader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidIcon,
  ZidInput,
  ZidRadio,
  ZidCheckbox,
} from '@zidsa/ui';
import { RoutingRouteEnum } from '@/router/routes.enum';
import UserIcon from '@/assets/icons/domains/team-members/UserIcon.vue';
import BadgeIcon from '@/assets/icons/domains/team-members/BadgeIcon.vue';
import LockIcon from '@/assets/icons/domains/team-members/LockIcon.vue';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import RightPointingArrowIcon from '@/assets/icons/domains/team-members/RightPointingArrowIcon.vue';

export default defineComponent({
  components: {
    ZidLoader,
    ZidButton,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidIcon,
    UserIcon,
    BadgeIcon,
    LockIcon,
    BaseText,
    BaseIcon,
    RightPointingArrowIcon,
    ZidInput,
    ZidRadio,
    ZidCheckbox,
  },
  setup(_, ctx) {
    return {
      ...useMemberDetails(ctx),
      RoutingRouteEnum,
    };
  },
});
