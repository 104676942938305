class TeamMemberDetailsModel {
  public name: string;
  public email?: string;
  public mobile: string;
  public position: string;
  public permissions: string[];

  constructor(arg: any) {
    this.name = arg.name;
    this.email = arg.email;
    this.mobile = arg.mobile;
    this.position = arg.position;
    this.permissions = arg.permissions;
  }
}

export default TeamMemberDetailsModel;
