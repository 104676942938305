import { defineComponent, reactive, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import ArrowRightIcon from '@/assets/icons/domains/partnership-details/ArrowRightIcon.vue';
import {
  ZidButton,
  ZidCard,
  ZidCardHeader,
  ZidCardBody,
  ZidConfirmModal,
  ZidInput,
  ZidInputGroup,
  ZidInputTrailing,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidRadio,
} from '@zidsa/ui';
import { RoutingRouteEnum } from '@/router/routes.enum';
import router from '@/router/index';
import store from '@/store';
import { setPartnershipAuthorizedMember } from '@/domains/partnership-details/api/partnership';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import { TeamMemberInfo } from '@/domains/team-members/models/TeamMembersResponseModel';
import { getTeamMembers } from '@/domains/team-members/api/teamMembers';
import { TeamMemberRolesEnum, TeamMemberStatusEnum } from '@/domains/team-members/types/memberDetails.interface';
import ExclamationMarkIcon from '@/assets/icons/domains/partnership-details/ExclamationMarkIcon.vue';
import { AuthorizedMemberModeEnum } from '@/domains/partnership-details/types/partnership.type';

export default defineComponent({
  components: {
    ExclamationMarkIcon,
    ArrowRightIcon,
    BaseText,
    BaseIcon,
    ZidButton,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidConfirmModal,
    ZidInput,
    ZidInputGroup,
    ZidInputTrailing,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidRadio,
  },
  setup() {
    const loggedInPartnerId = Number(store.getters['partnerID']);
    const isLoadingAuthorizedMemberTab = ref(false);
    const authorizedMemberMode = ref(AuthorizedMemberModeEnum.Self);
    const selectedMemberId = ref(loggedInPartnerId);
    const selectedMemberName = ref('Select a team member');
    const isConfirmModalOpen = ref(false);
    const teamMembersList = reactive<Record<string, TeamMemberInfo[]>>({ list: [] });
    const authorizedMembersList = reactive<Record<string, TeamMemberInfo[]>>({ list: [] });
    const suggestedMembersList = reactive<Record<string, TeamMemberInfo[]>>({ list: [] });

    const onAuthorizedMemberModeChange = async (mode: AuthorizedMemberModeEnum) => {
      authorizedMemberMode.value = mode;
      if (authorizedMemberMode.value === AuthorizedMemberModeEnum.Self) {
        selectedMemberId.value = loggedInPartnerId;
        return;
      }
      selectedMemberId.value = 0;
      if (!teamMembersList.list.length) {
        await loadTeamMembersList();
        authorizedMembersList.list = teamMembersList.list.filter((member) => {
          return (
            (member.position === TeamMemberRolesEnum.Manager ||
              member.position === TeamMemberRolesEnum.PartnershipOfficer) &&
            member.status === TeamMemberStatusEnum.Active
          );
        });
      }
      if (!authorizedMembersList.list.length) {
        selectedMemberName.value = 'No available members';
      }
    };

    const onSelectAuthorizedMember = (event: Record<any, any>) => {
      selectedMemberId.value = event.value;
      selectedMemberName.value = getSelectedMemberById()?.name ?? '...';
    };

    const onCreateAuthorizedMemberClick = async () => {
      await router.push({ name: RoutingRouteEnum.TeamMembers });
    };

    const loadTeamMembersList = async (): Promise<void> => {
      isLoadingAuthorizedMemberTab.value = true;
      const response = await getTeamMembers();
      if (!(response instanceof ErrorModel) && response.users) {
        teamMembersList.list = [...response.users];
      }
      isLoadingAuthorizedMemberTab.value = false;
    };

    const getSelectedMemberById = (): TeamMemberInfo | null => {
      for (let index = 0; index < authorizedMembersList.list.length; index++) {
        if (authorizedMembersList.list[index].id === selectedMemberId.value) {
          return authorizedMembersList.list[index];
        }
      }
      return null;
    };

    const onSearchTeamMembers = (event: Record<any, any>) => {
      if (!event.value) {
        suggestedMembersList.list = [...authorizedMembersList.list];
        return;
      }
      suggestedMembersList.list = authorizedMembersList.list.filter((member) => {
        return member.name.toLowerCase().indexOf(event.value.toLowerCase()) !== -1;
      });
    };

    const onConfirmButtonClick = async () => {
      if (!selectedMemberId.value) {
        Vue.$toast.error('You must select a member to assign!');
        return;
      }
      isLoadingAuthorizedMemberTab.value = true;
      const response = await setPartnershipAuthorizedMember(selectedMemberId.value);
      isLoadingAuthorizedMemberTab.value = false;
      if (response instanceof ErrorModel || typeof response.partnership_details === typeof undefined) {
        Vue.$toast.error('An error occurred in assigning the authorized member');
        return;
      }
      Vue.$toast.success('Successfully assigned the authorized member');
      if (selectedMemberId.value === loggedInPartnerId) {
        router.go(0);
        return;
      }
      await router.push({ name: RoutingRouteEnum.Dashboard }).catch(() => {
        //
      });
    };

    return {
      authorizedMemberMode,
      isLoadingAuthorizedMemberTab,
      selectedMemberId,
      selectedMemberName,
      isConfirmModalOpen,
      authorizedMembersList,
      suggestedMembersList,
      AuthorizedMemberModeEnum,
      onAuthorizedMemberModeChange,
      onCreateAuthorizedMemberClick,
      onConfirmButtonClick,
      getSelectedMemberById,
      onSelectAuthorizedMember,
      onSearchTeamMembers,
    };
  },
});
