import dayjs from 'dayjs';
import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import ActivationsResponseModel from '../models/ActivationsResponseModel';
import {
  ExportActivationsFilterParamsType,
  ActivationsFilterParamsType,
  ActivationsFilterParamsInterface,
} from '../types/activationsManagement.type';

const ENDPOINT_URL = '/market/shipping/activations';

export const getAllActivations = (
  appId: number,
  filters: ActivationsFilterParamsType,
): Promise<ActivationsResponseModel | ErrorModel> => {
  const filterParams: ActivationsFilterParamsInterface = {};

  if (filters.term) {
    filterParams.store_id = filters.term;
  }
  if (filters.activationStatus) {
    filterParams.activation_status = filters.activationStatus;
  }
  if (filters.settelmentStatus) {
    filterParams.settlement_status = filters.settelmentStatus;
  }
  if (filters.activationStartDate) {
    filterParams.activation_date_from = dayjs(filters.activationStartDate).format('DD/MM/YYYY');
  }
  if (filters.activationEndDate) {
    filterParams.activation_date_to = dayjs(filters.activationEndDate).format('DD/MM/YYYY');
  }
  if (filters.resultPerPage) {
    filterParams.pageSize = filters.resultPerPage;
  }
  if (filters.targetPage) {
    filterParams.cursor = filters.targetPage;
  }

  return httpClient
    .get(`${ENDPOINT_URL}/${appId}`, {
      params: filterParams,
    })
    .then((response) => new ActivationsResponseModel(response))
    .catch((error) => error);
};

export const exportActivationsList = (
  appId: number,
  filters: ExportActivationsFilterParamsType,
): Promise<BaseModel | ErrorModel> => {
  const filterParams: ActivationsFilterParamsInterface = {};

  if (filters.term) {
    filterParams.store_id = filters.term;
  }
  if (filters.activationStatus) {
    filterParams.activation_status = filters.activationStatus;
  }
  if (filters.settelmentStatus) {
    filterParams.settlement_status = filters.settelmentStatus;
  }
  if (filters.activationStartDate) {
    filterParams.activation_date_from = dayjs(filters.activationEndDate).format('DD/MM/YYYY');
  }
  if (filters.activationEndDate) {
    filterParams.activation_date_to = dayjs(filters.activationEndDate).format('DD/MM/YYYY');
  }

  return httpClient
    .get(`${ENDPOINT_URL}/export/${appId}`, {
      params: filterParams,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
