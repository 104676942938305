import { defineComponent } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
import { ZidIcon, ZidLoader, ZidTable, ZidTableRowGroup, ZidTableHeader, ZidTableRow, ZidTableCell } from '@zidsa/ui';
import useDevelopmentStoresManagement from '@/domains/development-store/helpers/useDevelopmentStoresManagement';
import CreateDevelopmentStore from '@/domains/development-store/views/create-development-store/CreateDevelopmentStore.vue';
import ChangeStorePassword from '@/domains/development-store/views/change-store-password/ChangeStorePassword.vue';

export default defineComponent({
  components: {
    BaseText,
    CreateDevelopmentStore,
    ChangeStorePassword,
    ZidIcon,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    return {
      ...useDevelopmentStoresManagement(),
    };
  },
});
