import { reactive, ref, watch, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import {
  ManageSubscriptionListState,
  SortingStateType,
  SubscriptionsListType,
  CurrentFilterRangeType,
  SortingStateProperties,
  ManageSubscriptionsResultPerPageProperty,
} from '../types/subscriptionsList.type';
import useVuelidate from '@vuelidate/core';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const useSubscriptionsList = () => {
  const ManageSubscriptionListState = reactive<ManageSubscriptionListState>({
    selectedResultPerPage: {
      label: '10',
      value: '10',
    },
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
  };

  const RESULT_PER_PAGE = [];

  for (let i = 10; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const sortingState = reactive<SortingStateType>({
    starts_at: null,
    ends_at: null,
  });

  const originalSubscriptions = reactive<SubscriptionsListType>({
    list: [],
  });

  const displayedSubscriptions = reactive<SubscriptionsListType>({
    list: [],
  });

  const filteredSubscriptions = reactive<SubscriptionsListType>({
    list: [],
  });

  const currentFilterRange = reactive<CurrentFilterRangeType>({
    startIndex: -1,
    endIndex: -1,
  });

  const isFilterApplied = ref(false);

  watch(
    () => filteredSubscriptions,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  watch(
    () => ManageSubscriptionListState.selectedResultPerPage,
    (newCountOfResultPerPage) => {
      if (originalSubscriptions.list.length >= Number(newCountOfResultPerPage.value)) {
        displayedSubscriptions.list = !isFilterApplied.value
          ? originalSubscriptions.list.slice(0, Number(newCountOfResultPerPage.value))
          : filteredSubscriptions.list.slice(0, Number(newCountOfResultPerPage.value));
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = Number(newCountOfResultPerPage.value);
      } else {
        displayedSubscriptions.list = !isFilterApplied.value
          ? originalSubscriptions.list.slice(0, originalSubscriptions.list.length)
          : filteredSubscriptions.list.slice(0, originalSubscriptions.list.length);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = originalSubscriptions.list.length;
      }
    },
    { deep: true },
  );

  const v$ = useVuelidate(rules, ManageSubscriptionListState);

  const onSelectionResultPerPageChange = (
    changedValue: Record<string, string>,
    property: ManageSubscriptionsResultPerPageProperty,
  ) => {
    v$.value[property].$touch();
    ManageSubscriptionListState[property] = JSON.parse(JSON.stringify(changedValue));
  };

  const onSort = (sortingProperty: SortingStateProperties) => {
    const isAscending = !sortingState[sortingProperty];

    const newSubscriptionsListEndIndex =
      originalSubscriptions.list.length >= currentFilterRange.endIndex
        ? currentFilterRange.endIndex
        : originalSubscriptions.list.length;

    filteredSubscriptions.list = originalSubscriptions.list.sort((a, b) => {
      const aValue = a[sortingProperty] ? dayjs(a[sortingProperty], 'DD/MM/YYYY') : null;
      const bValue = b[sortingProperty] ? dayjs(b[sortingProperty], 'DD/MM/YYYY') : null;

      if (!aValue || (aValue && bValue && aValue.isBefore(bValue))) return isAscending ? -1 : 1;
      if (!bValue || (aValue && bValue && aValue.isAfter(bValue))) return isAscending ? 1 : -1;
      return 0;
    });

    displayedSubscriptions.list = filteredSubscriptions.list.slice(
      currentFilterRange.startIndex,
      newSubscriptionsListEndIndex,
    );

    isFilterApplied.value = true;
    sortingState[sortingProperty] = isAscending;
  };

  const applyPageRangeFilter = () => {
    let newSubscriptionsListEndIndex;

    if (!isFilterApplied.value) {
      newSubscriptionsListEndIndex =
        originalSubscriptions.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalSubscriptions.list.length;
      displayedSubscriptions.list = originalSubscriptions.list.slice(
        currentFilterRange.startIndex,
        newSubscriptionsListEndIndex,
      );
    } else {
      newSubscriptionsListEndIndex =
        filteredSubscriptions.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredSubscriptions.list.length;
      displayedSubscriptions.list = filteredSubscriptions.list.slice(
        currentFilterRange.startIndex,
        newSubscriptionsListEndIndex,
      );
    }
  };

  const onPreviousClick = () => {
    currentFilterRange.startIndex -= Number(ManageSubscriptionListState.selectedResultPerPage.value);
    currentFilterRange.endIndex -= Number(ManageSubscriptionListState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const onNextClick = () => {
    currentFilterRange.startIndex += Number(ManageSubscriptionListState.selectedResultPerPage.value);
    currentFilterRange.endIndex += Number(ManageSubscriptionListState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const isNextButtonDisabled = computed(() => {
    if (isFilterApplied.value) {
      return currentFilterRange.endIndex >= filteredSubscriptions.list.length;
    } else {
      return currentFilterRange.endIndex >= originalSubscriptions.list.length;
    }
  });

  return {
    ManageSubscriptionListState,
    sortingState,
    resultPerPage,
    originalSubscriptions,
    displayedSubscriptions,
    isFilterApplied,
    isNextButtonDisabled,
    filteredSubscriptions,
    currentFilterRange,
    onSort,
    onSelectionResultPerPageChange,
    onPreviousClick,
    onNextClick,
  };
};

export default useSubscriptionsList;
