import httpClient from '@/api/httpClient';
import PartnershipCountriesResponseModel from '@/domains/partnership-details/models/PartnershipCountriesResponseModel';
import PartnershipCitiesResponseModel from '@/domains/partnership-details/models/PartnershipCitiesResponseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/settings';
export const getCountriesList = async (): Promise<PartnershipCountriesResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/countries`)
    .then((response) => new PartnershipCountriesResponseModel(response))
    .catch((error) => error);
};

export const getCountryCities = async (countryId: number): Promise<PartnershipCitiesResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/cities/by-country/${countryId}`)
    .then((response) => new PartnershipCitiesResponseModel(response))
    .catch((error) => error);
};
