import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import AllPayoutsResponseModel from '@/domains/payouts/models/AllPayoutsResponseModel';
import { PayoutsFilterParamsType, PayoutsFilterParamsInterface } from '@/domains/payouts/types/payouts.type';

const ENDPOINT_URL = '/pd/payouts';

export const getPayoutList = (filters: PayoutsFilterParamsType): Promise<AllPayoutsResponseModel | ErrorModel> => {
  const filterParams: PayoutsFilterParamsInterface = {};

  if (filters.searchTerm) {
    filterParams.code = filters.searchTerm;
  }
  if (filters.item.length) {
    filterParams.item = [...filters.item];
  }
  if (filters.status.length) {
    filterParams.status = [...filters.status];
  }
  if (filters.transaction_type) {
    filterParams.transaction_type = filters.transaction_type;
  }
  if (filters.date) {
    filterParams.date = filters.date;
  }
  if (filters.resultPerPage) {
    filterParams.per_page = filters.resultPerPage;
  }
  if (filters.targetPage) {
    filterParams.page = filters.targetPage;
  }

  return httpClient
    .get(`${ENDPOINT_URL}`, {
      params: filterParams,
    })
    .then((response) => new AllPayoutsResponseModel(response))
    .catch((error) => error);
};

export const exportPayoutDetails = (id: string): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/export`, { id }, { responseType: 'blob' })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const downloadPayoutInvoice = (id: string): Promise<Blob | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${id}/invoice`, { responseType: 'blob' })
    .then((response) => response)
    .catch((error) => error);
};

export const downloadPayoutReceipt = (id: string): Promise<Blob | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${id}/receipt`, { responseType: 'blob' })
    .then((response) => response)
    .catch((error) => error);
};

export const submitPayoutInvoice = (id: string, invoiceFile: File): Promise<BaseModel | ErrorModel> => {
  const formData = new FormData();
  formData.append('invoice_file', invoiceFile);

  return httpClient
    .post(`${ENDPOINT_URL}/${id}/invoice`, formData)
    .then((response) => response)
    .catch((error) => error);
};

export const submitPayoutReceipt = (id: string, receiptFile: File): Promise<BaseModel | ErrorModel> => {
  const formData = new FormData();
  formData.append('receipt_file', receiptFile);

  return httpClient
    .post(`${ENDPOINT_URL}/${id}/receipt`, formData)
    .then((response) => response)
    .catch((error) => error);
};
