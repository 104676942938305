import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import RightPointingArrowIcon from '@/assets/icons/domains/team-members/RightPointingArrowIcon.vue';
import ExclamationIcon from '@/assets/icons/domains/team-members/ExclamationIcon.vue';
import {
  ZidLoader,
  ZidButton,
  ZidPageHeader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidIcon,
  ZidTable,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
  ZidTableCell,
  ZidBadge,
  ZidModal,
} from '@zidsa/ui';
import useTeamMembers from '../../helpers/team-members/useTeamMembers';
import { useGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidLoader,
    ZidButton,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidIcon,
    ZidTable,
    ZidTableHeader,
    ZidTableRow,
    ZidTableRowGroup,
    ZidTableCell,
    RightPointingArrowIcon,
    ZidBadge,
    DeleteIcon,
    EditIcon,
    ZidModal,
    ExclamationIcon,
  },
  setup() {
    const { partnerID } = useGetters(['partnerID']);

    return {
      ...useTeamMembers(),
      partnerID,
    };
  },
});
