import httpClient from '@/api/httpClient';
import GetProfileResponseModel from '../models/GetProfileResponseModel';
import PartnerInfoModel from '../models/PartnerInfoModel';

const ENDPOINT_URL = '/market/partner';

export const getPartnerInformation = () => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new GetProfileResponseModel(response))
    .catch((error) => error);
};

export const updatePartnerInformation = (partnerInfo: PartnerInfoModel) => {
  return httpClient
    .put(ENDPOINT_URL, partnerInfo)
    .then((response) => new GetProfileResponseModel(response))
    .catch((error) => error);
};
