import { computed, defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import AwardIcon from '@/assets/icons/domains/partnership-details/AwardIcon.vue';
import {
  ZidAlert,
  ZidButton,
  ZidCard,
  ZidCardBody,
  ZidCardHeader,
  ZidInput,
  ZidInputGroup,
  ZidInputTrailing,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidSwitch,
} from '@zidsa/ui';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { getCountriesKeys } from '@/helpers/countries';
import {
  PartnershipContactInfoState,
  PartnershipDetailsContactInfoPropertyEnum,
  PartnershipDetailsMobilePropertyNameEnum,
  PartnershipDetailsStepEnum,
} from '@/domains/partnership-details/types/partnership.type';
import libphonenumber from 'libphonenumber-js/mobile';

import useVuelidate from '@vuelidate/core';
import { PhoneNumber } from 'libphonenumber-js/types';
import { email, minLength, required, requiredIf } from '@vuelidate/validators';
import { setPartnershipContactInfo } from '@/domains/partnership-details/api/partnership';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import router from '@/router';

export default defineComponent({
  props: {
    contactInfo: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
    },
  },
  components: {
    TabMultiLanguage,
    BaseText,
    ZidAlert,
    ZidButton,
    ZidCard,
    ZidCardBody,
    ZidCardHeader,
    ZidInput,
    ZidInputGroup,
    ZidInputTrailing,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidSwitch,
    BaseIcon,
    AwardIcon,
  },
  setup(props) {
    const COUNTRY_KEYS = getCountriesKeys();
    const selectedCountryKey = ref('+966');
    const selectedCountryKeyFinancial = ref('+966');
    const isValidPartnershipOfficerMobileNumber = ref(false);
    const isValidFinancialOfficerMobileNumber = ref(!props.contactInfo.withFinancialOfficer);
    const isLoadingContactInfoTab = ref(false);

    const partnershipContactInfoState = reactive<PartnershipContactInfoState>({
      partnershipOfficerNameAr: props.contactInfo.partnershipOfficerNameAr ?? '',
      partnershipOfficerNameEn: props.contactInfo.partnershipOfficerNameEn ?? '',
      partnershipOfficerEmail: props.contactInfo.partnershipOfficerEmail ?? '',
      partnershipOfficerMobile: props.contactInfo.partnershipOfficerMobile ?? '',
      financialOfficerNameAr: props.contactInfo.financialOfficerNameAr ?? '',
      financialOfficerNameEn: props.contactInfo.financialOfficerNameEn ?? '',
      financialOfficerEmail: props.contactInfo.financialOfficerEmail ?? '',
      financialOfficerMobile: props.contactInfo.financialOfficerMobile ?? '',
      withFinancialOfficer: props.contactInfo.withFinancialOfficer ?? false,
    });

    const rules = {
      partnershipOfficerNameAr: {
        required,
        minLength: minLength(4),
      },
      partnershipOfficerNameEn: {
        required,
        minLength: minLength(4),
      },
      partnershipOfficerEmail: {
        required,
        email,
      },
      partnershipOfficerMobile: {
        required,
      },
      financialOfficerNameAr: {
        required: requiredIf(() => {
          return partnershipContactInfoState.withFinancialOfficer ?? false;
        }),
      },
      financialOfficerNameEn: {
        required: requiredIf(() => {
          return partnershipContactInfoState.withFinancialOfficer ?? false;
        }),
      },
      financialOfficerEmail: {
        required: requiredIf(() => {
          return partnershipContactInfoState.withFinancialOfficer ?? false;
        }),
        email,
      },
      financialOfficerMobile: {
        required: requiredIf(() => {
          return partnershipContactInfoState.withFinancialOfficer ?? false;
        }),
      },
      withFinancialOfficer: {},
    };

    const validator = useVuelidate(rules, partnershipContactInfoState);

    onBeforeMount(() => {
      setParsedPhoneNumberValues(PartnershipDetailsMobilePropertyNameEnum.Partnership, null);
      if (props.contactInfo.financialOfficerMobile) {
        setParsedPhoneNumberValues(PartnershipDetailsMobilePropertyNameEnum.Financial, null);
      }
    });

    const isValidForm = computed(() => {
      return (
        !validator.value.$invalid &&
        isValidPartnershipOfficerMobileNumber.value &&
        isValidFinancialOfficerMobileNumber.value
      );
    });

    const isDisabledForm = computed(() => {
      return props.step !== PartnershipDetailsStepEnum.ContactInfo || isLoadingContactInfoTab.value;
    });

    const onCountryKeyChange = (
      event: Record<string, string>,
      phoneProperty: PartnershipDetailsMobilePropertyNameEnum,
    ) => {
      if (phoneProperty == PartnershipDetailsMobilePropertyNameEnum.Financial) {
        selectedCountryKeyFinancial.value = event.value;
      } else {
        selectedCountryKey.value = event.value;
      }
      setParsedPhoneNumberValues(phoneProperty, event.value);
    };

    const onMobilePhoneChange = (event: Event, phoneProperty: PartnershipDetailsMobilePropertyNameEnum) => {
      const target = event.target as HTMLInputElement;
      partnershipContactInfoState[phoneProperty] = target.value;
      setParsedPhoneNumberValues(phoneProperty, null);
    };

    const onSwitchFinancialContact = () => {
      partnershipContactInfoState.withFinancialOfficer = !partnershipContactInfoState.withFinancialOfficer;
      if (!partnershipContactInfoState.withFinancialOfficer) {
        resetFinancialOfficerState();
      }
      // Bring the form into viewport
      setTimeout(function () {
        if (partnershipContactInfoState.withFinancialOfficer) {
          document.getElementById('partnership-details-page-financial-officer-contact-card')?.scrollIntoView();
        }
      }, 100);
    };

    const parsePhoneNumber = (phone: string | null): PhoneNumber | undefined => {
      if (!phone) {
        return;
      }
      return libphonenumber(phone, 'SA');
    };

    const setPhonePropertyValidation = (phoneProperty: PartnershipDetailsMobilePropertyNameEnum, value: boolean) => {
      if (phoneProperty === PartnershipDetailsMobilePropertyNameEnum.Financial) {
        isValidFinancialOfficerMobileNumber.value = value;
        return;
      }
      isValidPartnershipOfficerMobileNumber.value = value;
    };

    const setSelectedCountryKey = (phoneProperty: PartnershipDetailsMobilePropertyNameEnum, value: string) => {
      if (phoneProperty === PartnershipDetailsMobilePropertyNameEnum.Financial) {
        selectedCountryKeyFinancial.value = value;
        return;
      }
      selectedCountryKey.value = value;
    };

    const setParsedPhoneNumberValues = (
      phoneProperty: PartnershipDetailsMobilePropertyNameEnum,
      selectedKey: string | null,
    ) => {
      let defaultKey = selectedKey ?? '';
      if (!defaultKey) {
        defaultKey =
          phoneProperty === PartnershipDetailsMobilePropertyNameEnum.Financial
            ? selectedCountryKeyFinancial.value
            : selectedCountryKey.value;
      }
      let mobileNumber = partnershipContactInfoState[phoneProperty];
      if (!mobileNumber) {
        return;
      }
      if (mobileNumber.indexOf('+') !== 0 && mobileNumber.indexOf('00') !== 0) {
        if (mobileNumber.indexOf('0') === 0) {
          mobileNumber = mobileNumber.slice(0);
        }
        mobileNumber = `${defaultKey} ${mobileNumber}`;
      }
      const parsedPhoneNumber = parsePhoneNumber(mobileNumber);
      if (!parsedPhoneNumber) {
        setPhonePropertyValidation(phoneProperty, false);
        setSelectedCountryKey(phoneProperty, selectedKey ?? defaultKey);
        partnershipContactInfoState[phoneProperty] = selectedKey ?? defaultKey;
        return;
      }
      if (selectedKey) {
        const newPhone = `${selectedKey} ${parsedPhoneNumber?.formatNational()}`;
        setPhonePropertyValidation(phoneProperty, parsePhoneNumber(newPhone)?.isValid() ?? false);
        partnershipContactInfoState[phoneProperty] = newPhone;
        setSelectedCountryKey(phoneProperty, selectedKey);
      } else {
        setPhonePropertyValidation(phoneProperty, parsedPhoneNumber.isValid());
        partnershipContactInfoState[phoneProperty] = parsedPhoneNumber?.formatInternational() ?? defaultKey;
        setSelectedCountryKey(phoneProperty, `+${parsedPhoneNumber.countryCallingCode}`);
      }
    };

    const onContactInfoPropertyChange = (
      property: PartnershipDetailsContactInfoPropertyEnum,
      event: Record<string, string>,
    ) => {
      partnershipContactInfoState[property] = event.value;
      validator.value[property].$touch();
    };

    const onConfirmButtonClick = async () => {
      isLoadingContactInfoTab.value = true;
      const response = await setPartnershipContactInfo(partnershipContactInfoState);
      isLoadingContactInfoTab.value = false;
      if (response instanceof ErrorModel || typeof response.partnership_details === typeof undefined) {
        Vue.$toast.error('Failed to save contact information');
        return;
      }
      router.go(0);
    };

    const resetFinancialOfficerState = () => {
      partnershipContactInfoState.financialOfficerNameAr = '';
      partnershipContactInfoState.financialOfficerNameEn = '';
      partnershipContactInfoState.financialOfficerMobile = '';
      partnershipContactInfoState.financialOfficerEmail = '';
      partnershipContactInfoState.withFinancialOfficer = false;
      isValidFinancialOfficerMobileNumber.value = true;
    };

    return {
      COUNTRY_KEYS,
      selectedCountryKey,
      selectedCountryKeyFinancial,
      partnershipContactInfoState,
      isValidPartnershipOfficerMobileNumber,
      isValidFinancialOfficerMobileNumber,
      PartnershipDetailsMobilePropertyNameEnum,
      PartnershipDetailsContactInfoPropertyEnum,
      validator,
      isValidForm,
      isLoadingContactInfoTab,
      isDisabledForm,
      PartnershipDetailsStepEnum,
      onCountryKeyChange,
      onSwitchFinancialContact,
      onMobilePhoneChange,
      onConfirmButtonClick,
      onContactInfoPropertyChange,
    };
  },
});
