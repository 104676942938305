import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PublishedShippingAppsResponseModel from '@/api/models/PublishedShippingAppsResponseModel';

const ENDPOINT_URL = '/market/partner-apps';

export const getShippingApplicationsList = (): Promise<PublishedShippingAppsResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL, {
      params: {
        shippingOnly: true,
      },
    })
    .then((response) => new PublishedShippingAppsResponseModel(response))
    .catch((error) => error);
};
