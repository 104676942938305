class BankAccountInfoModel {
  public partner_id?: string;
  public bank_name: string;
  public account_name: string;
  public iban: string;
  public swift_code: string;
  public city: string;
  public country: string;
  constructor(arg: any) {
    this.partner_id = arg.partner_id;
    this.bank_name = arg.bank_name;
    this.account_name = arg.account_name;
    this.iban = arg.iban;
    this.swift_code = arg.swift_code;
    this.city = arg.city;
    this.country = arg.country;
  }
}

export default BankAccountInfoModel;
