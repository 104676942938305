export interface ApplicationInterface {
  label: string;
  value: number;
  type: number;
}

export interface WebhookLogsParamInterface {
  app_id: number;
  created_at: string;
  per_page: number;
}

export interface WebhookLogsEventInterface {
  id: string;
  event_id: string;
  store_id: number;
  event: string;
  event_resource_type: string;
  resource_id: string;
  event_payload: Record<string, unknown> | null;
  response_payload: string | null;
  response_code: number | null;
  retries: number | null;
  status: WebhookEventStatusEnum;
  target_url: string;
  created_at: string;
  updated_at: string;
}

export interface WebhookEventPayloadModalInterface {
  eventResourceType: string;
  event: string;
  targetUrl: string;
  eventPayload: string | null;
  responsePayload: string | null;
  responseCode: number | null;
  status: WebhookEventStatusEnum;
}

export enum WebhookEventStatusEnum {
  Pending = 'pending',
  Failed = 'failed',
  Sent = 'sent',
}

export enum WebhookLogsDateTimeFieldTypeEnum {
  Time = 'time',
  Date = 'date',
}
