import BaseModel from '@/api/models/BaseModel';
import { AgreementResponseInterface } from '@/domains/partnership-details/types/partnership.type';

class PartnershipAgreementsListResponseModel extends BaseModel {
  public agreements: AgreementResponseInterface[];

  constructor(arg: any) {
    super(arg);
    this.agreements = arg.agreements;
  }
}

export default PartnershipAgreementsListResponseModel;
