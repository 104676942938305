import Vue from 'vue';
import { reactive, ref, onBeforeMount, SetupContext, watch, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import router from '@/router';
import { required, helpers, email, maxLength, minLength } from '@vuelidate/validators';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { ENGLISH_TEXT } from '@/helpers/regex/text';
import { PHONE_NUMBER } from '@/helpers/regex/numbers';
import {
  MemberDetailsStateInterface,
  TeamMemberInputPropertiesEnum,
  TeamMemberRolesEnum,
  TeamMemberPermissionsEnum,
} from '../../types/memberDetails.interface';
import { RootPermissionsEnum } from '@/router/permissions/root-permissions.enum';
import {
  PARTNER_FINANCIALS_PERMISSIONS,
  PARTNER_NORMAL_APPS_PERMISSIONS,
  PARTNER_PARTNERSHIPS_PERMISSIONS,
  PARTNER_SHIPPING_APPS_PERMISSIONS,
  PARTNER_TEAM_MEMBERS_PERMISSIONS,
  PARTNER_STORES_PERMISSIONS,
  PARTNER_THEMES_PERMISSIONS,
  PERSISTENT_PARTNER_PERMISSIONS,
  PERSISTENT_PERMISSIONS,
} from '@/router/permissions/partitioned-permissions';
import { addTeamMember, editTeamMember, getTeamMemberInfo, validateEmail } from '../../api/memberDetails';
import TeamMemberDetailsModel from '../../models/TeamMemberDetailsModel';
import ErrorModel from '@/api/models/ErrorModel';

const useMemberDetails = (context: SetupContext) => {
  const isEditMode = ref(!!context.root.$route.params.id);
  const isUsedEmailAddress = ref(false);
  const selectedRole = ref(TeamMemberRolesEnum.Manager);
  const isAtLeastOnePermissionSelected = ref(false);
  const isSubmitLoading = ref(false);
  const memberDetailsState = reactive<MemberDetailsStateInterface>({
    fullName: '',
    mobileNumber: '',
    memberEmail: '',
    permissions: {
      fullPermission: false,
      financials: false,
      apps: false,
      themes: false,
      teamMembers: false,
      partnerships: false,
      developmentStores: false,
    },
  });
  const finalGivenPermissionsList = reactive<Record<string, RootPermissionsEnum[]>>({
    list: [...PERSISTENT_PERMISSIONS, ...PERSISTENT_PARTNER_PERMISSIONS],
  });

  const rules = {
    fullName: {
      required,
      fullName: helpers.withMessage('Full name is required', (value: string) => {
        return ENGLISH_TEXT.test(value);
      }),
    },
    mobileNumber: {
      required,
      mobileNumber: helpers.withMessage('Mobile number is required', required),
      minLength: minLength(8),
      maxLength: maxLength(14),
      numberValue: (value: string) => {
        return PHONE_NUMBER.test(value);
      },
    },
    memberEmail: {
      required,
      email,
      memberEmail: helpers.withMessage('Invalid E-mail address', email),
      memberExists: helpers.withMessage('This E-mail has already been used', () => !isUsedEmailAddress.value),
    },
    permissions: {
      fullPermission: {},
      financials: {},
      apps: {},
      themes: {},
      teamMembers: {},
      partnerships: {},
      developmentStores: {},
    },
  };

  const v$ = useVuelidate(rules, memberDetailsState);

  onBeforeMount(async () => {
    if (isEditMode.value) {
      const memberRes = await getTeamMemberInfo(Number(context.root.$route.params.id) || 0);
      if (memberRes instanceof ErrorModel || !memberRes.partner) {
        Vue.$toast.error('Could not get team member information');
        return;
      }
      memberDetailsState.fullName = memberRes.partner.name;
      memberDetailsState.mobileNumber = memberRes.partner.mobile;
      memberDetailsState.memberEmail = memberRes.partner.email;
      selectedRole.value = memberRes.partner.position as TeamMemberRolesEnum;
      markGrantedPermissions(memberRes.partner.permissions);
      finalGivenPermissionsList.list = [...(memberRes.partner.permissions || [])];
    }
  });

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: TeamMemberInputPropertiesEnum,
  ): void => {
    v$.value[dataProperty].$touch();
    memberDetailsState[dataProperty] = changedValue.value;
  };

  const onRoleRadioButtonClick = (role: Record<string, TeamMemberRolesEnum>): void => {
    selectedRole.value = role.value;
  };

  const onPermissionCheckboxChange = (value: Record<string, boolean>, permission: TeamMemberPermissionsEnum): void => {
    v$.value.permissions[permission].$touch();
    memberDetailsState.permissions[permission] = value.isChecked;
    if (permission === TeamMemberPermissionsEnum.FullPemission) {
      memberDetailsState.permissions = {
        ...memberDetailsState.permissions,
        apps: value.isChecked,
        developmentStores: value.isChecked,
        financials: value.isChecked,
        partnerships: value.isChecked,
        teamMembers: value.isChecked,
        themes: value.isChecked,
      };
      fillPermissionsList(TeamMemberPermissionsEnum.FullPemission, value.isChecked);
    } else {
      memberDetailsState.permissions[TeamMemberPermissionsEnum.FullPemission] = false;
      const { fullPermission, ...rest } = memberDetailsState.permissions;
      if (Object.values(rest).every((permission) => !!permission)) {
        memberDetailsState.permissions[TeamMemberPermissionsEnum.FullPemission] = true;
        fillPermissionsList(TeamMemberPermissionsEnum.FullPemission, true);
      }
      if (Object.values(rest).every((permission) => !permission)) {
        memberDetailsState.permissions[TeamMemberPermissionsEnum.FullPemission] = false;
        fillPermissionsList(TeamMemberPermissionsEnum.FullPemission, false);
      }
      fillPermissionsList(permission, value.isChecked);
    }
  };

  const markGrantedPermissions = (grantedPermissions: RootPermissionsEnum[]): void => {
    const financialPermissions = checkAllPermissionsExistInGrantedPermissions(
      PARTNER_FINANCIALS_PERMISSIONS,
      grantedPermissions,
    );

    const appsPermissions =
      checkAllPermissionsExistInGrantedPermissions(PARTNER_NORMAL_APPS_PERMISSIONS, grantedPermissions) &&
      checkAllPermissionsExistInGrantedPermissions(PARTNER_SHIPPING_APPS_PERMISSIONS, grantedPermissions);

    const partnershipsPermissions = checkAllPermissionsExistInGrantedPermissions(
      PARTNER_PARTNERSHIPS_PERMISSIONS,
      grantedPermissions,
    );

    const teamMemberPermissions = checkAllPermissionsExistInGrantedPermissions(
      PARTNER_TEAM_MEMBERS_PERMISSIONS,
      grantedPermissions,
    );

    const developmentStoresPermissions = checkAllPermissionsExistInGrantedPermissions(
      PARTNER_STORES_PERMISSIONS,
      grantedPermissions,
    );

    const themesPermissions = checkAllPermissionsExistInGrantedPermissions(
      PARTNER_THEMES_PERMISSIONS,
      grantedPermissions,
    );

    if (
      financialPermissions &&
      appsPermissions &&
      partnershipsPermissions &&
      teamMemberPermissions &&
      developmentStoresPermissions &&
      themesPermissions
    ) {
      memberDetailsState.permissions[TeamMemberPermissionsEnum.FullPemission] = true;
      fillPermissionsCheckboxes(
        financialPermissions,
        appsPermissions,
        partnershipsPermissions,
        teamMemberPermissions,
        developmentStoresPermissions,
        themesPermissions,
      );
      return;
    }
    fillPermissionsCheckboxes(
      financialPermissions,
      appsPermissions,
      partnershipsPermissions,
      teamMemberPermissions,
      developmentStoresPermissions,
      themesPermissions,
    );
  };

  const fillPermissionsCheckboxes = (
    financialPermissions: boolean,
    appsPermissions: boolean,
    partnershipsPermissions: boolean,
    teamMemberPermissions: boolean,
    developmentStoresPermissions: boolean,
    themesPermissions: boolean,
  ) => {
    // financials
    memberDetailsState.permissions[TeamMemberPermissionsEnum.Financials] = financialPermissions;
    // /financials

    // apps
    memberDetailsState.permissions[TeamMemberPermissionsEnum.Apps] = appsPermissions;
    // /apps

    // partnerships
    memberDetailsState.permissions[TeamMemberPermissionsEnum.Partnerships] = partnershipsPermissions;
    // /partnerships

    // team members
    memberDetailsState.permissions[TeamMemberPermissionsEnum.TeamMembers] = teamMemberPermissions;
    // /team members

    // development stores
    memberDetailsState.permissions[TeamMemberPermissionsEnum.DevelopmentStores] = developmentStoresPermissions;
    // /development stores

    // themes
    memberDetailsState.permissions[TeamMemberPermissionsEnum.Themes] = themesPermissions;
    // /themes
  };

  const checkAllPermissionsExistInGrantedPermissions = (
    permissions: RootPermissionsEnum[],
    grantedPermissions: RootPermissionsEnum[],
  ): boolean => permissions.every((permission) => grantedPermissions.includes(permission));

  const isTargetPermissionListIncluded = (
    parentList: RootPermissionsEnum[],
    targetPermissionsList: RootPermissionsEnum[],
  ): boolean => targetPermissionsList.every((permission) => parentList.includes(permission));

  const addPermissionsListIfDoesntExist = (permissionsList: RootPermissionsEnum[]): void => {
    if (!isTargetPermissionListIncluded(finalGivenPermissionsList.list, permissionsList)) {
      finalGivenPermissionsList.list = [...finalGivenPermissionsList.list, ...permissionsList];
    }
  };

  const removePermissionsListIfExists = (permissionsList: RootPermissionsEnum[]): void => {
    if (isTargetPermissionListIncluded(finalGivenPermissionsList.list, permissionsList)) {
      finalGivenPermissionsList.list = finalGivenPermissionsList.list.filter(
        (permission) => !permissionsList.includes(permission),
      );
    }
  };

  const fillPermissionsList = (permissionKey: TeamMemberPermissionsEnum, granted: boolean): void => {
    switch (permissionKey) {
      case TeamMemberPermissionsEnum.FullPemission:
        if (!granted) {
          finalGivenPermissionsList.list = [...PERSISTENT_PERMISSIONS, ...PERSISTENT_PARTNER_PERMISSIONS];
          break;
        }
        addPermissionsListIfDoesntExist(PARTNER_FINANCIALS_PERMISSIONS);
        addPermissionsListIfDoesntExist([...PARTNER_NORMAL_APPS_PERMISSIONS, ...PARTNER_SHIPPING_APPS_PERMISSIONS]);
        addPermissionsListIfDoesntExist(PARTNER_THEMES_PERMISSIONS);
        addPermissionsListIfDoesntExist(PARTNER_TEAM_MEMBERS_PERMISSIONS);
        addPermissionsListIfDoesntExist(PARTNER_PARTNERSHIPS_PERMISSIONS);
        addPermissionsListIfDoesntExist(PARTNER_STORES_PERMISSIONS);
        break;
      case TeamMemberPermissionsEnum.Financials:
        if (!granted) {
          removePermissionsListIfExists(PARTNER_FINANCIALS_PERMISSIONS);
          break;
        }
        addPermissionsListIfDoesntExist(PARTNER_FINANCIALS_PERMISSIONS);
        break;
      case TeamMemberPermissionsEnum.Apps:
        if (!granted) {
          removePermissionsListIfExists([...PARTNER_NORMAL_APPS_PERMISSIONS, ...PARTNER_SHIPPING_APPS_PERMISSIONS]);
          break;
        }
        addPermissionsListIfDoesntExist([...PARTNER_NORMAL_APPS_PERMISSIONS, ...PARTNER_SHIPPING_APPS_PERMISSIONS]);
        break;
      case TeamMemberPermissionsEnum.Themes:
        if (!granted) {
          removePermissionsListIfExists(PARTNER_THEMES_PERMISSIONS);
          break;
        }
        addPermissionsListIfDoesntExist(PARTNER_THEMES_PERMISSIONS);
        break;
      case TeamMemberPermissionsEnum.TeamMembers:
        if (!granted) {
          removePermissionsListIfExists(PARTNER_TEAM_MEMBERS_PERMISSIONS);
          break;
        }
        addPermissionsListIfDoesntExist(PARTNER_TEAM_MEMBERS_PERMISSIONS);
        break;
      case TeamMemberPermissionsEnum.Partnerships:
        if (!granted) {
          removePermissionsListIfExists(PARTNER_PARTNERSHIPS_PERMISSIONS);
          break;
        }
        addPermissionsListIfDoesntExist(PARTNER_PARTNERSHIPS_PERMISSIONS);
        break;
      case TeamMemberPermissionsEnum.DevelopmentStores:
        if (!granted) {
          removePermissionsListIfExists(PARTNER_STORES_PERMISSIONS);
          break;
        }
        addPermissionsListIfDoesntExist(PARTNER_STORES_PERMISSIONS);
        break;
      default:
        break;
    }
  };

  const addOrEditTeamMemberClicked = async (): Promise<void> => {
    if (isEditMode.value) {
      isSubmitLoading.value = true;
      const teamMemberInfo = new TeamMemberDetailsModel({
        name: memberDetailsState.fullName,
        mobile: memberDetailsState.mobileNumber,
        position: selectedRole.value,
        permissions: finalGivenPermissionsList.list,
      });
      const editTeamMemberResponse = await editTeamMember(Number(context.root.$route.params.id) || 0, teamMemberInfo);
      if (editTeamMemberResponse instanceof ErrorModel) {
        Vue.$toast.error(editTeamMemberResponse.validations[0].errors[0] || `Could not edit team member`);
        isSubmitLoading.value = false;
        return;
      }
      router.push({ name: RoutingRouteEnum.TeamMembers });
      Vue.$toast.success(`Team member was edited successfully`);
      isSubmitLoading.value = false;
      return;
    }

    isSubmitLoading.value = true;
    const teamMemberInfo = new TeamMemberDetailsModel({
      name: memberDetailsState.fullName,
      mobile: memberDetailsState.mobileNumber,
      email: memberDetailsState.memberEmail,
      position: selectedRole.value,
      permissions: finalGivenPermissionsList.list,
    });
    const addTeamMemberResponse = await addTeamMember(teamMemberInfo);

    if (addTeamMemberResponse instanceof ErrorModel) {
      Vue.$toast.error(
        `${
          (addTeamMemberResponse.validations &&
            addTeamMemberResponse.validations.length &&
            addTeamMemberResponse.validations[0].errors.length &&
            addTeamMemberResponse.validations[0].errors[0]) ||
          'Could not add team member, please check your entry'
        }`,
      );
      isSubmitLoading.value = false;
      return;
    }
    router.push({ name: RoutingRouteEnum.TeamMembers });
    Vue.$toast.success(`Team member was added successfully`);
    isSubmitLoading.value = false;
  };

  const cancelBtnClicked = (): void => {
    router
      .push({
        name: RoutingRouteEnum.TeamMembers,
      })
      .catch(() => {
        //
      });
  };

  const checkEmailValidity = async (keyUp: KeyboardEvent): Promise<void> => {
    const target = keyUp.target as HTMLTextAreaElement;

    if (!v$.value.memberEmail.$invalid || isUsedEmailAddress.value) {
      const validateEmailRes = await validateEmail(target.value);
      if (validateEmailRes instanceof ErrorModel) return;
      isUsedEmailAddress.value = !validateEmailRes;
    }
  };

  watch(
    memberDetailsState,
    () => {
      isAtLeastOnePermissionSelected.value = Object.values(memberDetailsState.permissions).some(
        (permission) => !!permission,
      );
    },
    { deep: true },
  );

  const isTeamMemberInfoIncomplete = computed(() => {
    return !isAtLeastOnePermissionSelected.value || v$.value.$invalid;
  });

  return {
    selectedRole,
    isEditMode,
    memberDetailsState,
    v$,
    TeamMemberInputPropertiesEnum,
    TeamMemberRolesEnum,
    TeamMemberPermissionsEnum,
    isTeamMemberInfoIncomplete,
    isSubmitLoading,
    onInputDataChange,
    onRoleRadioButtonClick,
    onPermissionCheckboxChange,
    addOrEditTeamMemberClicked,
    checkEmailValidity,
    cancelBtnClicked,
  };
};

export default useMemberDetails;
