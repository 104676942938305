import BaseModel from '@/api/models/BaseModel';
import { CountryCityInterface } from '@/domains/partnership-details/types/partnership.type';

class PartnershipCountriesResponseModel extends BaseModel {
  public countries: CountryCityInterface[];

  constructor(arg: any) {
    super(arg);
    this.countries = arg.countries;
  }
}

export default PartnershipCountriesResponseModel;
