import { computed, defineComponent, onBeforeMount, reactive, ref } from '@vue/composition-api';
import {
  ZidBadge,
  ZidButton,
  ZidAlert,
  ZidDatepicker,
  ZidInput,
  ZidLoader,
  ZidModal,
  ZidPageHeader,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
  ZidTextarea,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import EyeIcon from '@/assets/icons/domains/webhooks/EyeIcon.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import { getPartnerAppsList } from '@/api/top-level-apis/application/applicationsList';
import {
  ApplicationInterface,
  WebhookEventPayloadModalInterface,
  WebhookEventStatusEnum,
  WebhookLogsDateTimeFieldTypeEnum,
  WebhookLogsEventInterface,
  WebhookLogsParamInterface,
} from '@/domains/webhooks/types/webhookLogs.types';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import { getAppWebhookLogs } from '@/domains/webhooks/api/webhookLogs';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    BaseIcon,
    EyeIcon,
    BaseText,
    ZidPageHeader,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidButton,
    ZidDatepicker,
    ZidInput,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    ZidLoader,
    ZidModal,
    ZidTextarea,
    ZidBadge,
    ZidAlert,
  },
  setup() {
    const PER_PAGE_OPTIONS = [];
    for (let i = 50; i <= 300; i += 10) {
      PER_PAGE_OPTIONS.push({
        label: `${i}`,
        value: `${i}`,
      });
    }
    const showPayloadModal = ref(false);
    const isLoadingAppList = ref(false);
    const isLoadingWebhookLogsList = ref(false);

    const appsList = reactive<Record<string, ApplicationInterface[]>>({ list: [] });
    const webhookLogsList = reactive<Record<string, WebhookLogsEventInterface[]>>({ list: [] });

    const filtersParamsState = reactive<WebhookLogsParamInterface>({
      app_id: 0,
      created_at: dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
      per_page: 100,
    });

    const payloadModalState = reactive<WebhookEventPayloadModalInterface>({
      eventResourceType: '',
      event: '',
      targetUrl: '',
      eventPayload: '',
      responsePayload: '',
      responseCode: null,
      status: WebhookEventStatusEnum.Pending,
    });

    onBeforeMount(async () => {
      await getAppsList();
      setInitialFiltersState();
      if (filtersParamsState.app_id) {
        await getWebhookLogsList();
      }
    });

    const selectedApp = computed(() => {
      return (
        appsList.list.find((app) => app.value == filtersParamsState.app_id) ?? {
          label: '...',
          value: 0,
          type: 1,
        }
      );
    });

    const formattedTime = computed(() => dayjs(filtersParamsState.created_at).format('HH:mm'));

    const onSearchButtonClick = async () => {
      await getWebhookLogsList();
    };

    const onPerPageChange = async (perPage: number) => {
      filtersParamsState.per_page = perPage;
      await getWebhookLogsList();
    };

    const onDateTimeChange = (value: string, type: WebhookLogsDateTimeFieldTypeEnum) => {
      if (type === WebhookLogsDateTimeFieldTypeEnum.Time) {
        filtersParamsState.created_at = `${dayjs(filtersParamsState.created_at).format('YYYY-MM-DD')} ${value}`;
        return;
      }
      filtersParamsState.created_at = `${dayjs(value).format('YYYY-MM-DD')} ${dayjs(
        filtersParamsState.created_at,
      ).format('HH:mm')}`;
    };

    const getAppsList = async () => {
      isLoadingAppList.value = true;
      const response = await getPartnerAppsList();
      isLoadingAppList.value = false;
      if (response instanceof ErrorModel || !response.apps) {
        Vue.$toast.error('Unable to get partner apps');
        return;
      }
      appsList.list = response.apps.map((app) => {
        return { label: app.name, value: app.id, type: app.type };
      });
    };

    const getWebhookLogsList = async () => {
      isLoadingWebhookLogsList.value = true;
      const response = await getAppWebhookLogs(filtersParamsState);
      isLoadingWebhookLogsList.value = false;
      if (response instanceof ErrorModel || !response.events) {
        Vue.$toast.error('Unable to load Webhook logs list');
        return;
      }
      webhookLogsList.list = response.events;
    };

    const onModalOpenClick = (eventId: string) => {
      const event = webhookLogsList.list.find((event) => event.id === eventId);
      payloadModalState.eventResourceType = event?.event_resource_type ?? '';
      payloadModalState.event = event?.event ?? '';
      payloadModalState.targetUrl = event?.target_url ?? '';
      payloadModalState.eventPayload = JSON.stringify(event?.event_payload) ?? '';
      payloadModalState.responsePayload = event?.response_payload ?? '';
      payloadModalState.responseCode = event?.response_code ?? null;
      payloadModalState.status = event?.status ?? WebhookEventStatusEnum.Pending;
      togglePayloadModal();
    };

    const togglePayloadModal = () => {
      showPayloadModal.value = !showPayloadModal.value;
    };

    const setInitialFiltersState = () => {
      filtersParamsState.app_id = appsList.list[0]?.value;
    };

    const getResponseCodeBadgeType = (status: WebhookEventStatusEnum): string => {
      switch (status) {
        case WebhookEventStatusEnum.Failed:
          return 'danger';
        case WebhookEventStatusEnum.Pending:
          return 'warning';
        default:
          return 'success';
      }
    };

    return {
      PER_PAGE_OPTIONS,
      showPayloadModal,
      isLoadingAppList,
      isLoadingWebhookLogsList,
      appsList,
      webhookLogsList,
      selectedApp,
      formattedTime,
      filtersParamsState,
      WebhookLogsDateTimeFieldTypeEnum,
      payloadModalState,
      togglePayloadModal,
      onDateTimeChange,
      onSearchButtonClick,
      onModalOpenClick,
      onPerPageChange,
      getResponseCodeBadgeType,
    };
  },
});
