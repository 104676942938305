import Vue from 'vue';
import { ref, reactive, watch, onBeforeMount, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import useVuelidate from '@vuelidate/core';
import {
  OrderStatusLabelsEnum,
  OrderStatusValuesEnum,
  SettelmentStatusValuesEnum,
  ManageOrderListFilterState,
  ManageOrderDateRangeFormState,
  OrderListInputProperties,
  ManageOrderDateRangeProperty,
  OrderListSelectProperties,
  OrderListType,
} from '../types/ordersManagement.type';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import { ORDER_STATUS_DROPDOWN } from '../helpers/dropdownInfo';
import { getAllOrders, exportOrdersList } from '../api/orders';
import ErrorModel from '@/api/models/ErrorModel';

const useOrdersManagement = () => {
  const loadingOrderList = ref(false);
  const isExportingDataInProgress = ref(false);
  const DEFAULT_ITEM_PER_PAGE = 10;
  const RESULT_PER_PAGE = [];
  const nextPage = ref('');
  const previousPage = ref('');

  for (let i = DEFAULT_ITEM_PER_PAGE; i <= 100; i += DEFAULT_ITEM_PER_PAGE) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const orderStatus = reactive({
    options: ORDER_STATUS_DROPDOWN,
  });

  const originalOrders = reactive<OrderListType>({
    list: [],
  });

  const ManageOrderListFilterState = reactive<ManageOrderListFilterState>({
    searchValue: '',
    selectedResultPerPage: {
      label: `${DEFAULT_ITEM_PER_PAGE}`,
      value: `${DEFAULT_ITEM_PER_PAGE}`,
    },
    orderStatus: {
      label: '',
      value: '',
    },
    createdDate: {
      startDate: '',
      endDate: '',
    },
    deliveryDate: {
      startDate: '',
      endDate: '',
    },
    targetPage: '',
  });

  const rules = {
    searchValue: {},
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    orderStatus: {
      label: {},
      value: {},
    },
    createdDate: {
      startDate: {},
      endDate: {},
    },
    deliveryDate: {
      startDate: {},
      endDate: {},
    },
    targetPage: {},
  };

  const v$ = useVuelidate(rules, ManageOrderListFilterState);

  const getAllOrdersData = async () => {
    loadingOrderList.value = true;
    originalOrders.list = [];
    const parameters = {
      term: ManageOrderListFilterState.searchValue,
      orderStatus: ManageOrderListFilterState.orderStatus.value,
      createdStartDate: ManageOrderListFilterState.createdDate.startDate,
      createdEndDate: ManageOrderListFilterState.createdDate.endDate,
      deliveryStartDate: ManageOrderListFilterState.deliveryDate.startDate,
      deliveryEndDate: ManageOrderListFilterState.deliveryDate.endDate,
      resultPerPage: ManageOrderListFilterState.selectedResultPerPage.value,
      targetPage: ManageOrderListFilterState.targetPage,
    };
    const response = await getAllOrders(parameters);
    if (response instanceof ErrorModel || !response.orders.data) {
      Vue.$toast.error('Unable to get Orders');
      loadingOrderList.value = false;
      return;
    }
    originalOrders.list = [...response.orders.data];
    nextPage.value = response.orders.nextPage;
    previousPage.value = response.orders.previousPage;
    loadingOrderList.value = false;
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: OrderListSelectProperties) => {
    v$.value[property].$touch();
    const newValues = JSON.parse(JSON.stringify(changedValue));
    if (newValues.value === OrderStatusValuesEnum.All) {
      // for order status only
      ManageOrderListFilterState[property].value = '';
    } else {
      ManageOrderListFilterState[property] = newValues;
    }
  };

  const onInputDataChange = (keyUp: KeyboardEvent, dataProperty: OrderListInputProperties) => {
    const target = keyUp.target as HTMLTextAreaElement;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      v$.value[dataProperty].$touch();
      ManageOrderListFilterState[dataProperty] = target.value;
    }
  };

  const onDateRangeChange = (
    changedValue: Record<string, string>,
    formState: ManageOrderDateRangeFormState,
    property: ManageOrderDateRangeProperty,
  ) => {
    v$.value[formState][property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    if (selectedValue.value) {
      const selectedValue = JSON.parse(JSON.stringify(changedValue));
      ManageOrderListFilterState[formState][property] = dayjs(selectedValue.value).format('YYYY/MM/DD');
    } else {
      ManageOrderListFilterState[formState][property] = '';
    }
  };

  const onExportAllClicked = async () => {
    isExportingDataInProgress.value = true;
    const parameters = {
      term: ManageOrderListFilterState.searchValue,
      orderStatus: ManageOrderListFilterState.orderStatus.value,
      createdStartDate: ManageOrderListFilterState.createdDate.startDate,
      createdEndDate: ManageOrderListFilterState.createdDate.endDate,
      deliveryStartDate: ManageOrderListFilterState.deliveryDate.startDate,
      deliveryEndDate: ManageOrderListFilterState.deliveryDate.endDate,
    };
    const response = await exportOrdersList(parameters);
    Vue.$toast.default('You will receive an email of orders list shortly');
    isExportingDataInProgress.value = false;
  };

  const onPreviousClick = async () => {
    if (previousPage) {
      ManageOrderListFilterState.targetPage = previousPage.value;
    }
  };

  const onNextClick = async () => {
    if (nextPage) {
      ManageOrderListFilterState.targetPage = nextPage.value;
    }
  };

  const isNextPageBtnDisable = computed(() => {
    return !nextPage.value;
  });

  const isPreviousPageBtnDisable = computed(() => {
    return !previousPage.value;
  });

  watch(
    ManageOrderListFilterState,
    async (ManageOrderListFilterState) => {
      const createdStartDate = ManageOrderListFilterState.createdDate.startDate;
      const createdEndDate = ManageOrderListFilterState.createdDate.endDate;
      const deliveryStartDate = ManageOrderListFilterState.deliveryDate.startDate;
      const deliveryEndDate = ManageOrderListFilterState.deliveryDate.endDate;
      if (createdStartDate && createdEndDate) {
        if (dayjs(createdStartDate).isAfter(dayjs(createdEndDate))) {
          Vue.$toast.error('Date range is not valid for Order Created');
          return;
        }
        if (dayjs(createdEndDate).diff(dayjs(createdStartDate), 'month') > 3) {
          Vue.$toast.error('Date range should be within 3 months');
          return;
        }
      }
      if (deliveryStartDate && deliveryEndDate) {
        if (dayjs(deliveryStartDate).isAfter(dayjs(deliveryEndDate))) {
          Vue.$toast.error('Date range is not valid for Order Delivered');
          return;
        }
        if (dayjs(deliveryEndDate).diff(dayjs(deliveryStartDate), 'month') > 3) {
          Vue.$toast.error('Date range should be within 3 months');
          return;
        }
      }
      await getAllOrdersData();
      return;
    },
    { deep: true },
  );

  onBeforeMount(async () => {
    await getAllOrdersData();
  });

  return {
    v$,
    orderStatus,
    resultPerPage,
    originalOrders,
    loadingOrderList,
    isExportingDataInProgress,
    OrderStatusLabelsEnum,
    OrderStatusValuesEnum,
    OrderListInputProperties,
    SettelmentStatusValuesEnum,
    OrderListSelectProperties,
    ManageOrderListFilterState,
    ManageOrderDateRangeProperty,
    ManageOrderDateRangeFormState,
    isNextPageBtnDisable,
    isPreviousPageBtnDisable,
    onInputDataChange,
    onSelectionDataChange,
    onDateRangeChange,
    onExportAllClicked,
    onPreviousClick,
    onNextClick,
  };
};

export default useOrdersManagement;
