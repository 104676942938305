














import { defineComponent } from '@vue/composition-api';
import BaseIcon from '../../../components/base-icon/BaseIcon.vue';
import BaseText from '../../../components/text/BaseText.vue';
import DocumentIcon from '../assets/DocumentIcon.vue';
import ArrowRightIcon from '../../../assets/icons/shared/ArrowRightIcon.vue';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    DocumentIcon,
    ArrowRightIcon,
  },
  props: {
    title: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  setup({ link }) {
    const openLink = () => window.open(link, '_blank');

    return {
      openLink,
    };
  },
});
