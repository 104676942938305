import Vue from 'vue';
import { defineComponent, ref, reactive, watch, onBeforeMount } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import CheckIcon from '@/assets/icons/domains/subscriptions-list/CheckIcon.vue';
import CloseIcon from '@/assets/icons/domains/subscriptions-list/CloseIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidDatepicker,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import dayjs from 'dayjs';
import {
  SortingStateProperties,
  PlanTypeValuesEnum,
  SubscriptionStatusValuesEnum,
  RecurringStatusPropertyEnum,
  InstallationStatusValuesEnum,
  ManageSubscriptionListAppSelectProperty,
  ManageSubscriptionsSearchProperty,
  ManageSubscriptionListFilterState,
  ManageSubscriptionsDateRangeProperty,
  ManageSubscriptionsResultPerPageProperty,
  ManageSubscriptionListSubscriptionStatusProperty,
  ApplicationSelection,
  DataToBeExportedType,
} from '../types/subscriptionsList.type';
import useVuelidate from '@vuelidate/core';
import { SUBSCRIPTION_STATUS_DROPDOWN } from '../helpers/dropdownInfo';
import ExcelIcon from '@/assets/icons/domains/partnership-admin/ExcelIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useSubscriptionsList from '../helpers/useSubscriptionsList';
import ApplicationModel, { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import { getApplicationsList } from '@/api/top-level-apis/dashboard/applicationsList';
import { getAllSubscriptions } from '../api/subscriptions';
import * as XLSX from 'xlsx';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    CheckIcon,
    CloseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidDatepicker,
    ExcelIcon,
    SearchIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },

  setup() {
    const {
      ManageSubscriptionListState,
      sortingState,
      resultPerPage,
      originalSubscriptions,
      displayedSubscriptions,
      isFilterApplied,
      isNextButtonDisabled,
      filteredSubscriptions,
      currentFilterRange,
      onSort,
      onSelectionResultPerPageChange,
      onPreviousClick,
      onNextClick,
    } = useSubscriptionsList();

    const ManageSubscriptionListFilterState = reactive<ManageSubscriptionListFilterState>({
      selectedApp: {
        label: '',
        value: '',
      },
      searchValue: '',
      subscriptionStatus: {
        label: '',
        value: '',
      },
      dateRange: {
        startDate: '',
        endDate: '',
      },
    });

    const subscriptionAppsList: ApplicationSelection[] = [];

    const appsList = reactive(subscriptionAppsList);
    const loadingAppsList = ref(false);
    const isAppsListDisabled = ref(false);

    const loadingSubscriptionList = ref(false);

    const rules = {
      selectedApp: {
        label: {},
        value: {},
      },
      searchValue: {},
      subscriptionStatus: {
        label: {},
        value: {},
      },
      dateRange: {
        startDate: {},
        endDate: {},
      },
    };

    const v$ = useVuelidate(rules, ManageSubscriptionListFilterState);

    const subscriptionStatus = reactive({
      options: SUBSCRIPTION_STATUS_DROPDOWN,
    });

    const getAppList = async () => {
      loadingAppsList.value = true;
      const response = await getApplicationsList();
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to load Applications');
        loadingAppsList.value = false;
        isAppsListDisabled.value = true;
        return;
      }
      isAppsListDisabled.value = false;
      const apps = response.payload;
      apps.map((app: ApplicationModel) => {
        if (app.type !== ApplicationTypeProperty.Shipping) {
          subscriptionAppsList.push({
            label: app.name,
            value: app.id?.toString() || '',
          });
        }
      });
      ManageSubscriptionListFilterState.selectedApp = subscriptionAppsList.length
        ? subscriptionAppsList[0]
        : { label: '', value: '' };
      loadingAppsList.value = false;
    };

    const getSubscriptionsData = async () => {
      loadingSubscriptionList.value = true;
      const response = await getAllSubscriptions(
        ManageSubscriptionListFilterState.selectedApp.value,
        ManageSubscriptionListFilterState.searchValue,
        ManageSubscriptionListFilterState.subscriptionStatus.value,
        ManageSubscriptionListFilterState.dateRange.startDate,
        ManageSubscriptionListFilterState.dateRange.endDate,
      );
      if (!response.subscriptions) {
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = 0;
        loadingSubscriptionList.value = false;
        return;
      }
      originalSubscriptions.list = response.subscriptions;
      displayedSubscriptions.list = originalSubscriptions.list;
      const initialTableRecordCount = response.subscriptions.length >= 10 ? 10 : response.subscriptions.length;
      displayedSubscriptions.list = response.subscriptions.slice(0, initialTableRecordCount);
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = initialTableRecordCount;
      loadingSubscriptionList.value = false;
    };

    onBeforeMount(async () => {
      await getAppList();
      if (!loadingAppsList.value) {
        await getSubscriptionsData();
      }
    });

    const onInputDataChange = (changedValue: string, dataProperty: ManageSubscriptionsSearchProperty) => {
      v$.value[dataProperty].$touch();
      ManageSubscriptionListFilterState[dataProperty] = changedValue;
    };

    const onSelectionDataChange = (
      changedValue: Record<string, string>,
      property: ManageSubscriptionListSubscriptionStatusProperty,
    ) => {
      v$.value[property].$touch();
      const newValues = JSON.parse(JSON.stringify(changedValue));
      if (newValues.value === 'all') {
        ManageSubscriptionListFilterState[property].value = '';
      } else {
        ManageSubscriptionListFilterState[property] = JSON.parse(JSON.stringify(changedValue));
      }
    };

    const onSelectionAppListDataChange = (
      changedValue: Record<string, string>,
      property: ManageSubscriptionListAppSelectProperty,
    ) => {
      v$.value[property].$touch();
      ManageSubscriptionListFilterState[property] = JSON.parse(JSON.stringify(changedValue));
    };

    const onDateRangeChange = (
      changedValue: Record<string, string>,
      property: ManageSubscriptionsDateRangeProperty,
    ) => {
      v$.value.dateRange[property].$touch();
      const selectedValue = JSON.parse(JSON.stringify(changedValue));
      if (selectedValue.value) {
        const selectedValue = JSON.parse(JSON.stringify(changedValue));
        ManageSubscriptionListFilterState.dateRange[property] = dayjs(selectedValue.value).format('YYYY/MM/DD');
      } else {
        ManageSubscriptionListFilterState.dateRange[property] = '';
      }
    };

    watch(
      () => ManageSubscriptionListFilterState,
      () => {
        const startDate = ManageSubscriptionListFilterState.dateRange.startDate;
        const endDate = ManageSubscriptionListFilterState.dateRange.endDate;
        if (startDate && endDate) {
          if (startDate > endDate) {
            Vue.$toast.error('Date range is not valid');
            return;
          }
        }
        getSubscriptionsData();
      },
      { deep: true },
    );

    const onExportAllClicked = () => {
      const dataToBeExported: DataToBeExportedType[] = originalSubscriptions.list.map((subscription) => {
        return {
          app_id: subscription.app_id,
          store_id: subscription.store_id,
          store_name: subscription.store_name,
          store_url: subscription.store_url,
          owner_name: subscription.owner_name,
          owner_phone: subscription.owner_phone,
          plan_name: subscription.plan_name,
          total_amount_paid: subscription.total_amount_paid ?? '0.0',
          partner_share: subscription.partner_share ?? '0.0',
          zid_share: subscription.zid_share ?? '0.0',
          plan_type: subscription.plan_type,
          starts_at: subscription.starts_at,
          ends_at: subscription.ends_at,
          installation_status: subscription.installation_status,
          subscription_status: subscription.subscription_status,
          is_recurring: subscription.is_recurring === 1 ? 'Yes' : 'No',
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(dataToBeExported);
      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, 'subscriptions');
      XLSX.writeFile(new_workbook, `subscriptions-list-${dayjs().format('DD-MM-YYYY')}.xlsx`);
    };

    return {
      appsList,
      isAppsListDisabled,
      loadingAppsList,
      ManageSubscriptionListState,
      ManageSubscriptionListFilterState,
      SortingStateProperties,
      PlanTypeValuesEnum,
      SubscriptionStatusValuesEnum,
      RecurringStatusPropertyEnum,
      InstallationStatusValuesEnum,
      ManageSubscriptionListAppSelectProperty,
      ManageSubscriptionsDateRangeProperty,
      ManageSubscriptionsResultPerPageProperty,
      ManageSubscriptionListSubscriptionStatusProperty,
      ManageSubscriptionsSearchProperty,
      subscriptionStatus,
      loadingSubscriptionList,
      sortingState,
      resultPerPage,
      originalSubscriptions,
      displayedSubscriptions,
      isFilterApplied,
      isNextButtonDisabled,
      filteredSubscriptions,
      currentFilterRange,
      onSort,
      onSelectionAppListDataChange,
      onSelectionResultPerPageChange,
      onPreviousClick,
      onNextClick,
      onInputDataChange,
      onSelectionDataChange,
      onDateRangeChange,
      onExportAllClicked,
    };
  },
});
