<template>
  <div class="bank-account">
    <div class="m-4 p-6 bg-white-pureWhite shadow-md rounded-lg">
      <base-text textStyle="h2" classes="text-primary-700 mb-2.5"> Bank Account </base-text>
      <base-text textStyle="h4" classes="text-neutrals-black">
        Your bank account information on which you will receive your income from ZId
      </base-text>
    </div>
    <div class="m-4 p-6 bg-white-pureWhite shadow-md rounded-lg">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 lg:col-span-5">
          <base-text textStyle="h2" classes="text-primary"> Bank Account Information </base-text>
          <base-text textStyle="h4" classes="text-neutrals-black">
            You can modify your bank account information whenever you want
          </base-text>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <form>
            <fieldset class="mb-3 md:mb-5">
              <label for="bankName">
                <base-text textStyle="h5" classes="flex mb-1">
                  Bank Name:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.bankName.$model"
                :invalid="v$.bankName.$dirty && v$.bankName.$invalid"
                :errorMessage="v$.bankName.bankName.$message"
                id="bankName"
                name="Bank Name"
                @change="onInputDataChange($event, 'bankName')"
              >
              </zid-input>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="accountName">
                <base-text textStyle="h5" classes="flex mb-1">
                  Account Name:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.accountName.$model"
                :invalid="v$.accountName.$dirty && v$.accountName.$invalid"
                :errorMessage="v$.accountName.accountName.$message"
                id="accountName"
                name="Account Name"
                @change="onInputDataChange($event, 'accountName')"
              >
              </zid-input>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="swiftCode">
                <base-text textStyle="h5" classes="flex mb-1">
                  Swift Code:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.swiftCode.$model"
                :invalid="v$.swiftCode.$dirty && v$.swiftCode.$invalid"
                :errorMessage="v$.swiftCode.swiftCode.$message"
                id="swiftCode"
                name="Swift Code"
                @change="onInputDataChange($event, 'swiftCode')"
              >
              </zid-input>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="ibanNumber">
                <base-text textStyle="h5" classes="flex mb-1">
                  IBAN Number:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.ibanNumber.$model"
                :invalid="v$.ibanNumber.$dirty && v$.ibanNumber.$invalid"
                :errorMessage="v$.ibanNumber.ibanNumber.$message"
                id="ibanNumber"
                name="Iban Number"
                @change="onInputDataChange($event, 'ibanNumber')"
              >
              </zid-input>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="country">
                <base-text textStyle="h5" classes="flex mb-1">
                  Country:
                  <base-text textStyle="h5" classes="text-error">&nbsp;*</base-text>
                </base-text>
              </label>
              <zid-select
                :value="v$.country.$model"
                class="bank-account-zid-select w-full"
                @change="onSelectionDataChange($event, 'country')"
              >
                <zid-select-header class="bank-account-zid-select-header w-full"
                  >{{ !!v$.country.$model ? v$.country.$model.label : ` ` }}&nbsp;</zid-select-header
                >
                <zid-select-body class="bank-account-zid-select-body w-full">
                  <zid-select-option v-for="country in countriesList.options" :key="country.value" :value="country">{{
                    country.label
                  }}</zid-select-option>
                </zid-select-body>
              </zid-select>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="city">
                <base-text textStyle="h5" classes="flex mb-1">
                  City:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.city.$model"
                :invalid="v$.city.$dirty && v$.city.$invalid"
                :errorMessage="v$.city.city.$message"
                id="city"
                name="City"
                @change="onInputDataChange($event, 'city')"
              >
              </zid-input>
            </fieldset>
            <fieldset>
              <div class="flex flex-col lg:flex-row justify-center items-center">
                <zid-button type="neutral" class="me-0 lg:me-3" @click.prevent="onBackClicked">
                  <base-text textStyle="h3" classes="px-8 py-1"> Back </base-text>
                </zid-button>
                <zid-button
                  type="primary"
                  class="mt-4 lg:mt-0"
                  :disabled="v$.$invalid"
                  @click.prevent="onSaveChangesClicked"
                >
                  <base-text textStyle="h3" classes="px-2 py-1"> Save Changes </base-text>
                </zid-button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import BaseText from '../../../components/text/BaseText.vue';
import useBankAccount from '..//helpers/useBankAccount';
import { ZidInput, ZidSelect, ZidSelectHeader, ZidSelectBody, ZidSelectOption, ZidButton } from '@zidsa/ui';
export default defineComponent({
  components: {
    BaseText,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidButton,
  },
  setup() {
    return {
      ...useBankAccount(),
    };
  },
});
</script>

<style lang="scss" scoped>
.bank-account {
  &-zid-select {
    height: 45px;
    .bank-account-zid-select-header::v-deep button {
      height: 45px;
      width: 100%;
      justify-content: flex-start;
    }
    .bank-account-zid-select-body {
      max-height: 150px;
      overflow-y: auto;
      z-index: 10;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background: theme('colors.gray.DEFAULT');
      }
      &::-webkit-scrollbar-thumb {
        background: theme('colors.green.DEFAULT');
        border-radius: 8px;
      }
    }
  }
}
</style>
