<template>
  <div class="profile">
    <div class="m-4 p-6 bg-white-pureWhite shadow-md rounded-lg">
      <base-text textStyle="h2" classes="text-primary-700 mb-2.5"> My Profile </base-text>
      <base-text textStyle="h4" classes="text-neutrals-black">
        You can modify your information whenever you want.
      </base-text>
    </div>
    <div v-if="loadingData" class="h-28 flex justify-center items-center">
      <zid-loader size="large" />
    </div>
    <div v-else class="m-4 p-8 bg-white-pureWhite shadow-md rounded-lg">
      <form>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-4">
            <base-text textStyle="h2" classes="text-primary"> Personal Information </base-text>
          </div>
          <div class="col-span-12 lg:col-span-8">
            <fieldset class="mb-3 md:mb-5">
              <label for="fullName">
                <base-text textStyle="h5" classes="flex mb-1">
                  Full Name:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.fullName.$model"
                :invalid="v$.fullName.$dirty && v$.fullName.$invalid"
                :errorMessage="v$.fullName.fullName.$message"
                :disabled="!isAccountOwner"
                id="fullName"
                name="Full Name"
                @change="onInputDataChange($event, 'fullName')"
              >
              </zid-input>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="emailAddress">
                <base-text textStyle="h5" classes="flex mb-1">
                  E-mail Address:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.emailAddress.$model"
                :invalid="v$.emailAddress.$dirty && v$.emailAddress.$invalid"
                :errorMessage="v$.emailAddress.emailAddress.$message"
                :disabled="!isAccountOwner"
                id="emailAddress"
                name="E-mail Address"
                @change="onInputDataChange($event, 'emailAddress')"
              >
              </zid-input>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="mobileNumber">
                <base-text textStyle="h5" classes="flex mb-1">
                  Mobile Number:
                  <base-text textStyle="h5" classes="text-error">*</base-text>
                </base-text>
              </label>
              <zid-input
                :value="v$.mobileNumber.$model"
                :invalid="v$.mobileNumber.$dirty && v$.mobileNumber.$invalid"
                :errorMessage="v$.mobileNumber.mobileNumber.$message"
                :disabled="!isAccountOwner"
                placeholder="Ex: +9661234567891"
                id="mobileNumber"
                name="Mobile Number"
                @change="onInputDataChange($event, 'mobileNumber')"
              >
              </zid-input>
            </fieldset>
            <fieldset>
              <label for="country">
                <base-text textStyle="h5" classes="flex mb-1">
                  Country:
                  <base-text textStyle="h5" classes="text-error">&nbsp;*</base-text>
                </base-text>
              </label>
              <zid-select
                :value="v$.country.$model"
                :invalid="v$.country.$dirty && v$.country.$invalid"
                :disabled="!isAccountOwner"
                class="profile-zid-select w-full"
                @change="onSelectionDataChange($event, 'country')"
              >
                <zid-select-header class="profile-zid-select-header w-full"
                  >{{ !!v$.country.$model ? v$.country.$model.label : ` ` }}&nbsp;</zid-select-header
                >
                <zid-select-body class="profile-zid-select-body w-full">
                  <zid-select-option v-for="country in countriesList.options" :key="country.value" :value="country">{{
                    country.label
                  }}</zid-select-option>
                </zid-select-body>
              </zid-select>
            </fieldset>
          </div>
        </div>
        <div class="mt-8 lg:mt-10 grid grid-cols-12 gap-6">
          <div class="col-span-12 lg:col-span-4">
            <base-text textStyle="h2" classes="text-primary"> Technical Information </base-text>
          </div>
          <div class="col-span-12 lg:col-span-8">
            <fieldset class="mb-3 md:mb-5">
              <label for="areYou">
                <base-text textStyle="h5" classes="flex mb-1">
                  Are you?
                  <base-text textStyle="h5" classes="text-error">&nbsp;*</base-text>
                </base-text>
              </label>
              <zid-select
                :value="v$.partnerType.$model"
                :invalid="v$.partnerType.$dirty && v$.partnerType.$invalid"
                :disabled="!isAccountOwner"
                class="profile-zid-select w-full"
                @change="onSelectionDataChange($event, 'partnerType')"
              >
                <zid-select-header class="profile-zid-select-header w-full"
                  >{{ !!v$.partnerType.$model ? v$.partnerType.$model.label : ` ` }}&nbsp;</zid-select-header
                >
                <zid-select-body class="profile-zid-select-body w-full">
                  <zid-select-option
                    v-for="partnerType in partnerTypes.options"
                    :key="partnerType.value"
                    :value="partnerType"
                    >{{ partnerType.label }}</zid-select-option
                  >
                </zid-select-body>
              </zid-select>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="appIntendedFor">
                <base-text textStyle="h5" classes="flex mb-1">
                  What are you willing to develop?
                  <base-text textStyle="h5" classes="text-error">&nbsp;*</base-text>
                </base-text>
              </label>
              <zid-select
                :value="v$.appIntendedFor.$model"
                :invalid="v$.appIntendedFor.$dirty && v$.appIntendedFor.$invalid"
                :disabled="!isAccountOwner"
                class="profile-zid-select w-full"
                @change="onSelectionDataChange($event, 'appIntendedFor')"
              >
                <zid-select-header class="profile-zid-select-header w-full"
                  >{{ !!v$.appIntendedFor.$model ? v$.appIntendedFor.$model.label : ` ` }}&nbsp;</zid-select-header
                >
                <zid-select-body class="profile-zid-select-body w-full">
                  <zid-select-option
                    v-for="intention in applicationIntentions.options"
                    :key="intention.value"
                    :value="intention"
                    >{{ intention.label }}</zid-select-option
                  >
                </zid-select-body>
              </zid-select>
            </fieldset>
            <fieldset class="mb-3 md:mb-5">
              <label for="isLookingForIdea">
                <base-text textStyle="h5" classes="flex mb-1">
                  Are You Looking For Ideas To Build?
                  <base-text textStyle="h5" classes="text-error">&nbsp;*</base-text>
                </base-text>
              </label>
              <zid-select
                :value="v$.isLookingForIdea.$model"
                :invalid="v$.isLookingForIdea.$dirty && v$.isLookingForIdea.$invalid"
                :disabled="!isAccountOwner"
                class="profile-zid-select w-full"
                @change="onSelectionDataChange($event, 'isLookingForIdea')"
              >
                <zid-select-header class="profile-zid-select-header w-full"
                  >{{
                    v$.isLookingForIdea.$model.value !== null ? v$.isLookingForIdea.$model.label : ` `
                  }}&nbsp;</zid-select-header
                >
                <zid-select-body class="profile-zid-select-body w-full">
                  <zid-select-option
                    v-for="isLooking in isLookingForIdeasOptions.options"
                    :key="isLooking.value"
                    :value="isLooking"
                    >{{ isLooking.label }}</zid-select-option
                  >
                </zid-select-body>
              </zid-select>
            </fieldset>
          </div>
        </div>
        <fieldset class="flex">
          <div class="hidden lg:block flex-1"></div>
          <div class="flex-1 flex flex-col lg:flex-row justify-start items-center">
            <zid-button type="neutral" class="me-0 lg:me-3" :disabled="!isAccountOwner" @click.prevent="onBackClicked">
              <base-text textStyle="h3" classes="px-8 py-1"> Back </base-text>
            </zid-button>
            <zid-button
              type="primary"
              class="mt-4 lg:mt-0"
              :disabled="v$.$invalid || !isAccountOwner"
              @click.prevent="onSaveChangesClicked"
            >
              <base-text textStyle="h3" classes="px-2 py-1"> Save Changes </base-text>
            </zid-button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from '@vue/composition-api';
import BaseText from '../../../components/text/BaseText.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidButton,
  ZidTextarea,
  ZidLoader,
} from '@zidsa/ui';
import useProfile from '../helpers/useProfile';

export default defineComponent({
  components: {
    BaseText,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidButton,
    ZidTextarea,
    ZidLoader,
  },
  setup() {
    const {
      profileState,
      countriesList,
      partnerTypes,
      applicationIntentions,
      isLookingForIdeasOptions,
      showPassword,
      v$,
      loadingData,
      isAccountOwner,
      toggleShowPassword,
      onSelectionDataChange,
      onInputDataChange,
      onSaveChangesClicked,
      onBackClicked,
    } = useProfile();

    onMounted(() => {
      if (v$.value.$invalid) v$.value.$validate();
    });

    return {
      profileState,
      countriesList,
      partnerTypes,
      applicationIntentions,
      isLookingForIdeasOptions,
      showPassword,
      v$,
      loadingData,
      isAccountOwner,
      toggleShowPassword,
      onSelectionDataChange,
      onInputDataChange,
      onSaveChangesClicked,
      onBackClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.profile {
  .profile-zid-select {
    height: 45px !important;
    .profile-zid-select-header::v-deep button {
      height: 45px !important;
      width: 100%;
      justify-content: flex-start !important;
      overflow: hidden;
    }
    .profile-zid-select-body {
      max-height: 150px;
      overflow-y: auto;
      z-index: 10;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: theme('colors.gray.DEFAULT');
      }
      &::-webkit-scrollbar-thumb {
        background: theme('colors.green.DEFAULT');
        border-radius: 8px;
      }
    }
  }
}
</style>
