export interface PartnershipStateType {
  partnershipOfficerName: string;
  companyName: string;
  companyWebsite: string;
  partnerEmail: string;
  mobileNumber: string;
  workField: {
    label: string;
    value: string;
  };
  workField_DeliveryAndStorage: {
    label: string;
    value: string;
  };
  workField_TechnologySolutions: {
    label: string;
    value: string;
  };
  potentialCustomers: {
    label: string;
    value: string;
  };
  companyProfileFile?: File;
  commercialRegisterFile?: File;
  VATCertificateFile?: File;
  servicesWithSuggestedPricingFile?: File;
  communicationsAndInformationTechnologyFile?: File;
}

export interface PartnershipContactInfoState {
  partnershipOfficerNameAr: string;
  partnershipOfficerNameEn: string;
  partnershipOfficerEmail: string;
  partnershipOfficerMobile: string;
  financialOfficerNameAr: string;
  financialOfficerNameEn: string;
  financialOfficerEmail: string;
  financialOfficerMobile: string;
  withFinancialOfficer: boolean;
}

export enum InputPropertiesEnum {
  PartnershipOfficerName = 'partnershipOfficerName',
  CompanyName = 'companyName',
  CompanyWebsite = 'companyWebsite',
  partnerEmail = 'partnerEmail',
  mobileNumber = 'mobileNumber',
}

export enum SelectPropertiesEnum {
  WorkField = 'workField',
  workField_DeliveryAndStorage = 'workField_DeliveryAndStorage',
  workField_TechnologySolutions = 'workField_TechnologySolutions',
  potentialCustomers = 'potentialCustomers',
}

export enum FilesPropertiesEnum {
  companyProfile = 'companyProfileFile',
  commercialRegister = 'commercialRegisterFile',
  VATCertificate = 'VATCertificateFile',
  servicesWithSuggestedPricing = 'servicesWithSuggestedPricingFile',
  communicationsAndInformationTechnology = 'communicationsAndInformationTechnologyFile',
}

export enum FilesStateEnum {
  isCompanyProfileFileInvalid = 'isCompanyProfileFileInvalid',
  isCommercialRegisterFileInvalid = 'isCommercialRegisterFileInvalid',
  isVATCertificateFileInvalid = 'isVATCertificateFileInvalid',
  isServicesWithSuggestedPricingFileInvalid = 'isServicesWithSuggestedPricingFileInvalid',
  isCommunicationsAndInformationTechnologyFileInvalid = 'isCommunicationsAndInformationTechnologyFileInvalid',
}

export enum WorkFieldsEnum {
  DeliveryOrStorage = 'Delivery/Storage Companies',
  TechnologySolutions = 'Technology Solutions Companies',
}

export enum WorkFieldValuesEnum {
  DeliveryOrStorage = '1',
  TechnologySolutions = '2',
}

export enum SubWorkFieldValuesEnum {
  LastMileDelivery = '1',
  StorageAndOrderProcessing = '2',
  All = '3',
  AccountingSoftwareProvider = '4',
  ResourceManagementSoftwareProvider = '5',
  ChatCommunicationSolutionsProvider = '6',
  DedicatedApplicationsForZidMerchants = '7',
  OtherSolutions = '8',
}

export enum DeliveryAndStorageOptionsEnum {
  LastMileDelivery = 'Last Mile Delivery Company',
  StorageAndOrderProcessing = 'Storage And Order Processing Company',
  All = 'All',
}

export enum TechnologySolutionsOptionsEnum {
  AccountingSoftwareProvider = 'Accounting Software Provider',
  ResourceManagementSoftwareProvider = 'Resource Management Software Provider',
  ChatCommunicationSolutionsProvider = 'Chat & Communication Solutions Provider',
  DedicatedApplicationsForZidMerchants = 'Dedicated Applications For Zid Merchants',
  OtherSolutions = 'Other Solutions',
}

export enum PotentialCustomersOptionsEnum {
  '0-50' = '0-50',
  '51-100' = '51-100',
  '101-500' = '101-500',
  MoreThan500 = 'More Than 500',
}
export enum PotentialCustomersValuesEnum {
  '0-50' = '50',
  '51-100' = '100',
  '101-500' = '500',
  MoreThan500 = '501',
}

export enum PartnershipInfoProps {
  Id = 'id',
  Partnership_officer_name = 'partnership_officer_name',
  Company_website = 'company_website',
  Company_name = 'company_name',
  Company_work_field_type = 'company_work_field_type',
  Company_work_field = 'company_work_field',
  Customer_count = 'customer_count',
  Language = 'language',
  Partner_id = 'partner_id',
  Partnership_officer_email = 'partnership_officer_email',
  Partnership_officer_mobile = 'partnership_officer_mobile',
  Company_profile_doc = 'company_profile_doc',
  Commercial_register_doc = 'commercial_register_doc',
  Vat_certificate_doc = 'vat_certificate_doc',
  Service_pricing_doc = 'service_pricing_doc',
  Communications_license_doc = 'communications_license_doc',
}

export type PartnershipContractType = {
  id: number;
  partner_id: number;
  company_name: string;
  company_website: string;
  company_work_field_type: number;
  company_work_field: number;
  customer_count: number;
  language: string;
  partnership_officer_email: string;
  partnership_officer_mobile: string;
  partnership_officer_name: string;
  company_profile_doc: string;
  commercial_register_doc: string;
  vat_certificate_doc: string | null;
  service_pricing_doc: string;
  communications_license_doc: string | null;
  submission_date: string;
};

export type PartnershipDetailsAddressType = {
  building_number: string | null;
  city_id: number | null;
  country_code: string | null;
  district: string | null;
  line_1: string | null;
  line_2: string | null;
  postal_code: string | null;
};

export type PartnershipDetailsOfficerType = {
  name: {
    ar: string | null;
    en: string | null;
  };
  email: string;
  mobile: string;
};

export type PartnershipDetailsResponseType = {
  id: number | null;
  partner_id: number;
  business_type: number | null;
  language: string | null;
  submission_date: string | null;
  step: PartnershipDetailsStepEnum | null;
  partnership_officer: PartnershipDetailsOfficerType | null;
  financial_officer: PartnershipDetailsOfficerType | null;
  company: {
    name: {
      ar: string | null;
      en: string | null;
    };
    website: string;
    work_field_type: number;
    work_field: number;
    customer_count: number;
    profile_doc: string | null;
    cr_doc: string | null;
    vat_doc: string | null;
    pricing_doc: string | null;
    communications_license_doc: string | null;
    cr_number: string | null;
    vat_number: string | null;
    address: PartnershipDetailsAddressType | null;
  } | null;
  national_id_doc: string | null;
  freelancer_doc: string | null;
};

export interface PartnershipBusinessDetailsInterface {
  businessType: number | null;
  companyNameAr: string | null;
  companyNameEn: string | null;
  country: string | null;
  cityId: number | null;
  addressLine1: string | null;
  addressLine2: string | null;
  district: string | null;
  postalCode: string | null;
  commercialRegisterNumber: string | null;
  commercialRegisterDoc: string | File | null;
  vatNumber: string | null;
  vatDoc: string | File | null;
  nationalIdDoc: string | File | null;
  freelancerDoc: string | File | null;
}

export interface CountryCityInterface {
  id: number;
  name: string;
  code: string;
}

export interface AgreeableItemInterface {
  id: number;
  name: string;
  type: AgreeableItemTypeEnum;
}

export interface AgreementInterface {
  id: number;
  itemName: string;
  itemType: AgreeableItemTypeEnum;
  itemId: number;
  status: number;
  approvedAt: string | null;
  document: string | null;
}

export interface AgreementResponseInterface {
  id: number;
  item_name: string;
  item_type: AgreeableItemTypeEnum;
  item_id: number;
  approved_at: string | null;
  status: number;
  document: string | null;
}

export interface UploadedDocumentsInterface {
  commercialRegisterDoc: string;
  vatDoc: string;
  nationalIdDoc: string;
  freelancerDoc: string;
}

export enum PartnershipDetailsTabsNamesEnum {
  AuthorizedMember = 'authorized-member',
  ContactInfo = 'contact-info',
  BusinessDetails = 'business-details',
  AgreementDetails = 'agreement-details',
  BankInformation = 'bank-information',
}

export enum PartnershipDetailsBusinessTypeEnum {
  Company = 1,
  Individual = 2,
}

export enum PartnershipDetailsMobilePropertyNameEnum {
  Partnership = 'partnershipOfficerMobile',
  Financial = 'financialOfficerMobile',
}

export enum PartnershipDetailsFilePropertyEnum {
  CommercialRegisterDoc = 'commercialRegisterDoc',
  VatDoc = 'vatDoc',
  NationalIdDoc = 'nationalIdDoc',
  FreelancerDoc = 'freelancerDoc',
}

export enum PartnershipDetailsContactInfoPropertyEnum {
  PartnershipOfficerNameAr = 'partnershipOfficerNameAr',
  PartnershipOfficerNameEn = 'partnershipOfficerNameEn',
  PartnershipOfficerEmail = 'partnershipOfficerEmail',
  PartnershipOfficerMobile = 'partnershipOfficerMobile',
  FinancialOfficerNameAr = 'financialOfficerNameAr',
  FinancialOfficerNameEn = 'financialOfficerNameEn',
  FinancialOfficerEmail = 'financialOfficerEmail',
  FinancialOfficerMobile = 'financialOfficerMobile',
}

export enum AgreeableItemTypeEnum {
  App = 1,
  Theme = 2,
}

export enum AgreementStatusValueEnum {
  Draft = 1,
  InReview = 2,
  Approved = 3,
  Rejected = 4,
}

export enum AgreementStatusLabelEnum {
  Draft = 'Draft',
  InReview = 'In Review',
  Approved = 'Signed',
  Rejected = 'Rejected',
}

export enum AuthorizedMemberModeEnum {
  Self = 1,
  TeamMember = 2,
}
export enum PartnershipDetailsStepEnum {
  None = 0,
  AuthorizedMember = 1,
  ContactInfo = 2,
  BusinessDetails = 3,
  Agreement = 4,
}
