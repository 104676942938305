export interface MemberDetailsStateInterface {
  fullName: string;
  mobileNumber: string;
  memberEmail: string;
  permissions: {
    fullPermission: boolean;
    financials: boolean;
    apps: boolean;
    themes: boolean;
    teamMembers: boolean;
    partnerships: boolean;
    developmentStores: boolean;
  };
}

export enum TeamMemberInputPropertiesEnum {
  FullName = 'fullName',
  MobileNumber = 'mobileNumber',
  MemberEmail = 'memberEmail',
}

export enum TeamMemberRolesEnum {
  Owner = 'owner',
  Manager = 'partnerdashboard.manager',
  Developer = 'partnerdashboard.developer',
  Accountant = 'partnerdashboard.accountant',
  PartnershipOfficer = 'partnerdashboard.partnership.officer',
  Other = 'partnerdashboard.other',
}

export enum TeamMemberRolesNamesEnum {
  Owner = 'Owner',
  Manager = 'Manager',
  Developer = 'Developer',
  Accountant = 'Accountant',
  PartnershipOfficer = 'Partnership Officer',
  Other = 'Other',
}

export enum TeamMemberPermissionsEnum {
  FullPemission = 'fullPermission',
  Financials = 'financials',
  Apps = 'apps',
  Themes = 'themes',
  TeamMembers = 'teamMembers',
  Partnerships = 'partnerships',
  DevelopmentStores = 'developmentStores',
}

export enum TeamMemberStatusEnum {
  Inactive = 0,
  Active = 1,
}
