import { DevelopmentStoresList } from '@/domains/development-store/types/developmentStore.type';
import { onBeforeMount, reactive, ref } from '@vue/composition-api';
import { getDevelopmentStores } from '@/domains/development-store/api/development-store/developmentStore';
import ErrorModel from '@/api/models/ErrorModel';
import { MERCHANT_DASHBOARD_LOGIN_LINK } from '@/helpers/constantLinks';

const useDevelopmentStoresManagement = () => {
  const MAX_STORES_ALLOWED = 15;
  const isFetchingStoresList = ref(false);
  const disableCreateStoreButton = ref(true);
  const storesList: DevelopmentStoresList = reactive({ list: [] });

  onBeforeMount(async () => {
    await fetchDevelopmentStoresList();
  });

  const fetchDevelopmentStoresList = async () => {
    isFetchingStoresList.value = true;
    const response = await getDevelopmentStores();
    if (response instanceof ErrorModel || !response.payload) {
      isFetchingStoresList.value = false;
      return;
    }
    storesList.list = response.payload;
    disableCreateStoreButton.value = storesList.list.length >= MAX_STORES_ALLOWED;
    isFetchingStoresList.value = false;
  };

  return {
    MERCHANT_DASHBOARD_LOGIN_LINK,
    isFetchingStoresList,
    disableCreateStoreButton,
    storesList,
    fetchDevelopmentStoresList,
  };
};
export default useDevelopmentStoresManagement;
