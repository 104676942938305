import BaseModel from '@/api/models/BaseModel';
import PartnerModel from '@/api/models/PartnerModel';

class TeamMemberDetailsResponse extends BaseModel {
  public partner: PartnerModel;

  constructor(arg: any) {
    super(arg);
    this.partner = arg.partner;
  }
}

export default TeamMemberDetailsResponse;
