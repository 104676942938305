import {
  PartnershipBusinessDetailsInterface,
  PartnershipDetailsBusinessTypeEnum,
} from '@/domains/partnership-details/types/partnership.type';

export const buildBusinessDetailsFormData = (data: PartnershipBusinessDetailsInterface): FormData => {
  const businessDetailsData = new FormData();
  businessDetailsData.append('business_type', data.businessType?.toString() ?? '');
  businessDetailsData.append('country_code', data.country ?? '');
  businessDetailsData.append('city_id', data.cityId?.toString() ?? '');
  businessDetailsData.append('address_line_1', data.addressLine1 ?? '');
  businessDetailsData.append('address_line_2', data.addressLine2 ?? '');
  businessDetailsData.append('district', data.district ?? '');
  businessDetailsData.append('postal_code', data.postalCode ?? '');
  businessDetailsData.append('building_number', '0');

  if (data.businessType === PartnershipDetailsBusinessTypeEnum.Company) {
    businessDetailsData.append('company_name_ar', data.companyNameAr ?? '');
    businessDetailsData.append('company_name_en', data.companyNameEn ?? '');
    businessDetailsData.append('cr_number', data.commercialRegisterNumber ?? '');
    businessDetailsData.append('cr_document', data.commercialRegisterDoc ?? '');
    businessDetailsData.append('vat_number', data.vatNumber ?? '');
    businessDetailsData.append('tax_document', data.vatDoc ?? '');
  }

  if (data.businessType === PartnershipDetailsBusinessTypeEnum.Individual) {
    businessDetailsData.append('freelancer_document', data.freelancerDoc ?? '');
    businessDetailsData.append('id_document', data.nationalIdDoc ?? '');
  }
  return businessDetailsData;
};
