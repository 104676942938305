import BaseModel from '@/api/models/BaseModel';
import { TeamMemberStatusEnum } from '../types/memberDetails.interface';

class TeamMembersResponseModel extends BaseModel {
  public users: TeamMemberInfo[];

  constructor(arg: any) {
    super(arg);
    this.users = arg.users;
  }
}

export default TeamMembersResponseModel;

export interface TeamMemberInfo {
  id: number;
  name: string;
  email: string;
  mobile: string;
  status: TeamMemberStatusEnum;
  position: string;
}
