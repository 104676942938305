import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import DownloadIcon from '@/assets/icons/domains/payouts/DownloadIcon.vue';
import PayoutDetailsIcon from '@/assets/icons/domains/payouts/PayoutDetailsIcon.vue';
import LinkIcon from '@/assets/icons/domains/finance-admin/LinkIcon.vue';
import FilterIcon from '@/assets/icons/domains/finance-admin/FilterIcon.vue';
import {
  ZidButton,
  ZidCard,
  ZidCardBody,
  ZidCardHeader,
  ZidCheckbox,
  ZidCombo,
  ZidComboItem,
  ZidDatepicker,
  ZidFileSelector,
  ZidIcon,
  ZidInput,
  ZidLoader,
  ZidModal,
  ZidPageHeader,
  ZidPagination,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
} from '@zidsa/ui';
import usePayouts from '../helpers/usePayouts';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidLoader,
    ZidButton,
    ZidPageHeader,
    ZidIcon,
    ZidTable,
    ZidTableHeader,
    ZidTableRow,
    ZidTableRowGroup,
    ZidTableCell,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidCheckbox,
    ZidCombo,
    ZidComboItem,
    ZidInput,
    ZidModal,
    ZidDatepicker,
    ZidPagination,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidFileSelector,
    DownloadIcon,
    PayoutDetailsIcon,
    LinkIcon,
    FilterIcon,
  },
  setup() {
    return {
      ...usePayouts(),
    };
  },
});
