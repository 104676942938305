import Vue from 'vue';
import { ref, reactive, watch, onBeforeMount, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import useVuelidate from '@vuelidate/core';
import {
  SelectedApplicationType,
  ActivationStatusValuesEnum,
  SettelmentStatusValuesEnum,
  ManageActivationListFilterState,
  ManageActivationDateRangeFormState,
  ManageActivationDateRangeProperty,
  ActivationListInputProperties,
  ActivationListSelectProperties,
  ActivationListType,
  DefaultActivationAndSettelmentStatusValueEnum,
} from '../types/activationsManagement.type';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import { ACTIVATION_STATUS_DROPDOWN, SETTELMENT_STATUS_DROPDOWN } from '../helpers/dropdownInfo';
import { getAllActivations, exportActivationsList } from '../api/activations';
import { getShippingApplicationsList } from '@/api/top-level-apis/shipping/dashboard/shippingApplicationsList';
import ErrorModel from '@/api/models/ErrorModel';

const useActivationsManagement = () => {
  const DEFAULT_ITEM_PER_PAGE = 10;
  const RESULT_PER_PAGE = [];
  const loadingActivationData = ref(false);
  const isExportingDataInProgress = ref(false);
  const loadingAppsList = ref(false);
  const isAppsListDisabled = ref(false);
  const activationAppsList: SelectedApplicationType[] = [];
  const appsList = reactive(activationAppsList);
  const nextPage = ref('');
  const previousPage = ref('');

  for (let i = DEFAULT_ITEM_PER_PAGE; i <= 100; i += DEFAULT_ITEM_PER_PAGE) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const activationStatus = reactive({
    options: ACTIVATION_STATUS_DROPDOWN,
  });

  const settelmentStatus = reactive({
    options: SETTELMENT_STATUS_DROPDOWN,
  });

  const activationData = reactive<ActivationListType>({
    list: [],
  });

  const manageActivationListFilterState = reactive<ManageActivationListFilterState>({
    searchValue: '',
    selectedResultPerPage: {
      label: `${DEFAULT_ITEM_PER_PAGE}`,
      value: `${DEFAULT_ITEM_PER_PAGE}`,
    },
    selectedApp: {
      label: '',
      value: '',
    },
    activationStatus: {
      label: '',
      value: '',
    },
    settelmentStatus: {
      label: '',
      value: '',
    },
    activationDate: {
      startDate: '',
      endDate: '',
    },
    targetPage: '',
  });

  const rules = {
    searchValue: {},
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    selectedApp: {
      label: {},
      value: {},
    },
    activationStatus: {
      label: {},
      value: {},
    },
    settelmentStatus: {
      label: {},
      value: {},
    },
    activationDate: {
      startDate: {},
      endDate: {},
    },
    targetPage: {},
  };

  const v$ = useVuelidate(rules, manageActivationListFilterState);

  const getAllActivationsList = async () => {
    activationData.list = [];
    loadingActivationData.value = true;
    const parameters = {
      term: manageActivationListFilterState.searchValue,
      activationStatus: manageActivationListFilterState.activationStatus.value,
      settelmentStatus: manageActivationListFilterState.settelmentStatus.value,
      activationStartDate: manageActivationListFilterState.activationDate.startDate,
      activationEndDate: manageActivationListFilterState.activationDate.endDate,
      resultPerPage: manageActivationListFilterState.selectedResultPerPage.value,
      targetPage: manageActivationListFilterState.targetPage,
    };
    const response = await getAllActivations(Number(manageActivationListFilterState.selectedApp.value), parameters);
    if (response instanceof ErrorModel || !response.activations.data) {
      Vue.$toast.error('Unable to get Activations');
      loadingActivationData.value = false;
      return;
    }
    activationData.list = [...response.activations.data];
    nextPage.value = response.activations.nextPage;
    previousPage.value = response.activations.previousPage;
    loadingActivationData.value = false;
  };

  const onInputDataChange = (keyUp: KeyboardEvent, dataProperty: ActivationListInputProperties) => {
    const target = keyUp.target as HTMLTextAreaElement;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      v$.value[dataProperty].$touch();
      manageActivationListFilterState[dataProperty] = target.value;
    }
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: ActivationListSelectProperties) => {
    v$.value[property].$touch();
    const newValues = JSON.parse(JSON.stringify(changedValue));
    if (newValues.value === DefaultActivationAndSettelmentStatusValueEnum.All) {
      // for activation & settelment status only
      manageActivationListFilterState[property].value = '';
    } else {
      manageActivationListFilterState[property] = newValues;
    }
  };

  const onDateRangeChange = (
    changedValue: Record<string, string>,
    formState: ManageActivationDateRangeFormState,
    property: ManageActivationDateRangeProperty,
  ) => {
    v$.value[formState][property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    if (selectedValue.value) {
      const selectedValue = JSON.parse(JSON.stringify(changedValue));
      manageActivationListFilterState[formState][property] = dayjs(selectedValue.value).format('YYYY/MM/DD');
    } else {
      manageActivationListFilterState[formState][property] = '';
    }
  };

  const onExportAllClicked = async () => {
    isExportingDataInProgress.value = true;
    const parameters = {
      term: manageActivationListFilterState.searchValue,
      activationStatus: manageActivationListFilterState.activationStatus.value,
      settelmentStatus: manageActivationListFilterState.settelmentStatus.value,
      activationStartDate: manageActivationListFilterState.activationDate.startDate,
      activationEndDate: manageActivationListFilterState.activationDate.endDate,
    };
    const response = await exportActivationsList(Number(manageActivationListFilterState.selectedApp.value), parameters);
    Vue.$toast.default('You will receive an email containing activation list shortly.');
    isExportingDataInProgress.value = false;
  };

  const onPreviousClick = async () => {
    if (previousPage) {
      manageActivationListFilterState.targetPage = previousPage.value;
    }
  };

  const onNextClick = async () => {
    if (nextPage) {
      manageActivationListFilterState.targetPage = nextPage.value;
    }
  };

  const isNextPageBtnDisable = computed(() => {
    return !nextPage.value;
  });

  const isPreviousPageBtnDisable = computed(() => {
    return !previousPage.value;
  });

  watch(
    manageActivationListFilterState,
    async (manageActivationListFilterState) => {
      const createdStartDate = manageActivationListFilterState.activationDate.startDate;
      const createdEndDate = manageActivationListFilterState.activationDate.endDate;
      if (createdStartDate && createdEndDate) {
        if (dayjs(createdStartDate).isAfter(dayjs(createdEndDate))) {
          Vue.$toast.error('Date range is not valid');
          return;
        }
      }
      await getAllActivationsList();
    },
    { deep: true },
  );

  const getAppList = async () => {
    loadingAppsList.value = true;
    const response = await getShippingApplicationsList();
    if (response instanceof ErrorModel || !response.payload) {
      isAppsListDisabled.value = true;
      loadingAppsList.value = false;
      return;
    }
    isAppsListDisabled.value = false;
    const apps = response.payload;
    apps.forEach((app: any) => {
      activationAppsList.push({
        label: app.name,
        value: `${app.id}`,
      });
    });
    manageActivationListFilterState.selectedApp = activationAppsList.length
      ? activationAppsList[0]
      : { label: '', value: '' };
    loadingAppsList.value = false;
  };

  onBeforeMount(async () => {
    await getAppList();
    if (!loadingAppsList.value) {
      await getAllActivationsList();
    }
  });

  return {
    v$,
    appsList,
    resultPerPage,
    activationData,
    activationStatus,
    settelmentStatus,
    loadingAppsList,
    isAppsListDisabled,
    loadingActivationData,
    isExportingDataInProgress,
    ActivationStatusValuesEnum,
    SettelmentStatusValuesEnum,
    ActivationListInputProperties,
    ActivationListSelectProperties,
    manageActivationListFilterState,
    ManageActivationDateRangeProperty,
    ManageActivationDateRangeFormState,
    isNextPageBtnDisable,
    isPreviousPageBtnDisable,
    onInputDataChange,
    onSelectionDataChange,
    onDateRangeChange,
    onExportAllClicked,
    onPreviousClick,
    onNextClick,
  };
};

export default useActivationsManagement;
