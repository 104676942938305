import BaseModel from '@/api/models/BaseModel';

export default class PartnershipAgreementPreviewResponseModel extends BaseModel {
  public agreement: string | null;

  constructor(arg: any) {
    super(arg);
    this.agreement = arg.agreement;
  }
}
