import httpClient from '@/api/httpClient';
import BankAccountInfoModel from '../models/BankAccountInfoModel';
import UpdateBankAccountResponseModel from '../models/UpdateBankAccountResponseModel';
import GetBankAccountResponseModel from '../models/GetBankAccountResponseModel';

const ENDPOINT_URL = '/market';

export const updateBankAccountInformation = (bankAccountInfo: BankAccountInfoModel) => {
  return httpClient
    .post(`${ENDPOINT_URL}/bank-accounts/save`, bankAccountInfo)
    .then((response) => new UpdateBankAccountResponseModel(response))
    .catch((error) => error);
};

export const getBankAccountInformation = () => {
  return httpClient
    .get(`${ENDPOINT_URL}/bank-account`)
    .then((response) => new GetBankAccountResponseModel(response))
    .catch((error) => error);
};
