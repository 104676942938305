





































































































































































































































































import { defineComponent } from '@vue/composition-api';
import BaseIcon from '../../../components/base-icon/BaseIcon.vue';
import BaseText from '../../../components/text/BaseText.vue';
import { ZidButton, ZidPageHeader } from '@zidsa/ui';
import {
  BOOK_MEETING_URL,
  JOIN_SLACK_URL,
  API_DOCUMENTATION,
  THEMES_DOCUMENTATION_URL,
  ALL_ARTICLES_URL,
  CHANGELOG_URL,
  REQUEST_FEATURE_URL,
} from '../../../helpers/constantLinks';
import EnvelopeIcon from '../assets/EnvelopeIcon.vue';
import ChatIcon from '../assets/ChatIcon.vue';
import MeetIcon from '../assets/MeetIcon.vue';
import CalendarIcon from '../assets/CalendarIcon.vue';
import SlackIcon from '../assets/SlackIcon.vue';
import Slack2Icon from '../assets/Slack2Icon.vue';
import ExternalLinkIcon from '../assets/ExternalLinkIcon.vue';
import DocumentIcon from '../assets/DocumentIcon.vue';
import ArticleLink from '../components/ArticleLink.vue';
import RocketIcon from '@/assets/icons/shared/RocketIcon.vue';
import PlusIcon from '@/assets/icons/shared/PlusIcon.vue';

export default defineComponent({
  components: {
    ZidButton,
    BaseText,
    BaseIcon,
    ZidPageHeader,
    EnvelopeIcon,
    ChatIcon,
    MeetIcon,
    CalendarIcon,
    SlackIcon,
    Slack2Icon,
    ExternalLinkIcon,
    DocumentIcon,
    ArticleLink,
    RocketIcon,
    PlusIcon,
  },
  setup() {
    const onOpenChatClicked = () => {
      if (window.Intercom) window.Intercom('showMessages');
    };
    return {
      BOOK_MEETING_URL,
      JOIN_SLACK_URL,
      API_DOCUMENTATION,
      THEMES_DOCUMENTATION_URL,
      ALL_ARTICLES_URL,
      CHANGELOG_URL,
      REQUEST_FEATURE_URL,
      onOpenChatClicked,
    };
  },
});
