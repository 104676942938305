export enum SubscriptionStatusLabelsEnum {
  All = 'All Statuses',
  Upgrade = 'Upgrade',
  Active = 'Active',
  Inactive = 'Inactive',
  Renew = 'Renew',
  Warning = 'Warning',
  Refund = 'Refund',
  Expired = 'Expired',
  Suspend = 'Suspend',
}

export enum SubscriptionStatusValuesEnum {
  All = 'all',
  Upgrade = 'upgrade',
  Active = 'active',
  Inactive = 'inactive',
  Renew = 'renew',
  Warning = 'warning',
  Refund = 'refund',
  Expired = 'expire',
  Suspend = 'suspend',
}

export enum PlanTypeValuesEnum {
  Free = 'Free',
  Trial = 'Trial',
  Paid = 'Paid',
}

export enum InstallationStatusValuesEnum {
  Activated = 'Activated',
  NotActivated = 'Not Activated',
}

export enum RecurringStatusPropertyEnum {
  IsRecurring = 1,
  NotRecurring = 0,
}

export type ApplicationSelection = {
  label: string;
  value: string;
};

export type ManageSubscriptionListState = {
  selectedResultPerPage: Record<string, string>;
};

export type ManageSubscriptionListFilterState = {
  selectedApp: Record<string, string>;
  searchValue: string | number;
  subscriptionStatus: Record<string, string>;
  dateRange: Record<string, string>;
};

export enum ManageSubscriptionsResultPerPageProperty {
  ResultPerPage = 'selectedResultPerPage',
}

export enum ManageSubscriptionsSearchProperty {
  SearchValue = 'searchValue',
}

export enum ManageSubscriptionsDateRangeProperty {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum ManageSubscriptionListSubscriptionStatusProperty {
  SubscriptionStatus = 'subscriptionStatus',
}

export enum ManageSubscriptionListAppSelectProperty {
  SelectedApp = 'selectedApp',
}

export type SortingStateType = {
  starts_at: null | boolean;
  ends_at: null | boolean;
};

export type SubscriptionItemType = {
  app_id: number;
  store_id: number;
  store_name: string;
  store_url: string;
  plan_name: string;
  plan_price: string;
  total_amount_paid: string;
  partner_share: string;
  zid_share: string;
  plan_type: string;
  subscription_status: SubscriptionStatusValuesEnum;
  starts_at: string;
  ends_at: string;
  installation_status: string;
  owner_name: string;
  owner_phone: string;
  is_recurring: number | null;
};

export type DataToBeExportedType = {
  app_id: number;
  store_id: number;
  store_name: string;
  store_url: string;
  plan_name: string;
  total_amount_paid: string;
  partner_share: string;
  zid_share: string;
  plan_type: string;
  subscription_status: SubscriptionStatusValuesEnum;
  starts_at: string;
  ends_at: string;
  installation_status: string;
  owner_name: string;
  owner_phone: string;
  is_recurring: string;
};

export type SubscriptionsListType = {
  list: SubscriptionItemType[];
};

export type CurrentFilterRangeType = {
  startIndex: number;
  endIndex: number;
};

export enum SortingStateProperties {
  Starts_at = 'starts_at',
  Ends_at = 'ends_at',
}
