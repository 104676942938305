import BaseModel from '@/api/models/BaseModel';
import { PartnershipDetailsResponseType } from '@/domains/partnership-details/types/partnership.type';
import { PartnerBankAccountType } from '@/domains/partnerships-admin/types/partnerDetails.type';

class PartnershipResponseModel extends BaseModel {
  public partnership_details: PartnershipDetailsResponseType;
  public bank_account: PartnerBankAccountType | null;
  constructor(arg: any) {
    super(arg);
    this.partnership_details = arg.partnership_details;
    this.bank_account = arg.bank_account;
  }
}

export default PartnershipResponseModel;
