import dayjs from 'dayjs';
import httpClient from '@/api/httpClient';
import AllSubscriptionsResponseModel from '../models/AllSubscriptionsResponseModel';

const ENDPOINT_URL = '/market/dashboard/subscriptions';

export const getAllSubscriptions = (
  appID: string,
  term: string | number,
  subscriptionStatus: string,
  startDate: string,
  endDate: string,
) => {
  let formattedStartDate = '';
  let formattedEndDate = '';
  if (startDate) {
    formattedStartDate = dayjs(startDate).format('DD/MM/YYYY');
  }
  if (endDate) {
    formattedEndDate = dayjs(endDate).format('DD/MM/YYYY');
  }
  return httpClient
    .get(
      `${ENDPOINT_URL}/${appID}?&term=${term}&status=${subscriptionStatus}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
    )
    .then((response) => new AllSubscriptionsResponseModel(response))
    .catch((error) => error);
};
