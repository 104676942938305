import { defineComponent, onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import AwardIcon from '@/assets/icons/domains/partnership-details/AwardIcon.vue';
import DownloadIcon from '@/assets/icons/domains/partnership-details/DownloadIcon.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidAlert,
  ZidBadge,
  ZidButton,
  ZidCard,
  ZidCardBody,
  ZidInput,
  ZidInputGroup,
  ZidInputTrailing,
  ZidLoader,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidTable,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRow,
  ZidTableRowGroup,
  ZidCheckbox,
} from '@zidsa/ui';
import {
  AgreeableItemInterface,
  AgreeableItemTypeEnum,
  AgreementInterface,
  AgreementStatusLabelEnum,
  AgreementStatusValueEnum,
} from '@/domains/partnership-details/types/partnership.type';
import { getApplicationsList } from '@/api/top-level-apis/dashboard/applicationsList';
import {
  getItemAgreementPreview,
  getPartnerAgreements,
  initiateAgreementRequest,
} from '@/domains/partnership-details/api/partnership';
import ErrorModel from '@/api/models/ErrorModel';
import Vue from 'vue';
import { partnerThemesList } from '@/api/top-level-apis/themes/partnerThemesList';
import { list } from 'postcss';

export default defineComponent({
  computed: {
    list() {
      return list;
    },
  },
  components: {
    AwardIcon,
    DownloadIcon,
    BaseText,
    BaseIcon,
    ZidButton,
    ZidBadge,
    ZidCard,
    ZidCardBody,
    ZidAlert,
    ZidInput,
    ZidInputGroup,
    ZidInputTrailing,
    ZidLoader,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidTable,
    ZidTableHeader,
    ZidTableRowGroup,
    ZidTableRow,
    ZidTableCell,
    ZidCheckbox,
  },
  setup() {
    const isLoadingAgreementDetails = ref(false);
    const isAgreementsLoadSuccess = ref(false);
    const selectedAgreementText = ref('');
    const isLoadingAgreementPreview = ref(false);
    const isLoadingAgreementRequest = ref(false);
    const isLoadingAgreementCancel = ref(false);
    const isConsentChecked = ref(false);

    const agreeableItemsList = reactive<Record<string, AgreeableItemInterface[]>>({ list: [] });
    const agreementsList = reactive<Record<string, AgreementInterface[]>>({ list: [] });
    const selectedItemState = reactive<AgreeableItemInterface>({
      id: 0,
      type: AgreeableItemTypeEnum.App,
      name: 'Choose...',
    });

    onBeforeMount(async () => {
      await loadAllAgreementInitialData();
    });

    watch(selectedItemState, async () => {
      await loadItemAgreementPreview();
    });

    const loadAgreementsList = async () => {
      const response = await getPartnerAgreements();
      if (response instanceof ErrorModel || typeof response.agreements === typeof undefined) {
        Vue.$toast.error('Failed to load agreements List!');
        isAgreementsLoadSuccess.value = false;
        return;
      }

      response.agreements.forEach(function (agreement) {
        agreementsList.list.push({
          id: agreement.id,
          itemName: agreement.item_name,
          itemType: agreement.item_type,
          itemId: agreement.item_id,
          status: agreement.status,
          approvedAt: agreement.approved_at,
          document: agreement.document,
        });
      });
      isAgreementsLoadSuccess.value = true;
    };

    const loadAllAgreementInitialData = async () => {
      agreeableItemsList.list = [];
      agreementsList.list = [];
      isLoadingAgreementDetails.value = true;
      await loadAgreementsList();
      if (isAgreementsLoadSuccess.value) {
        await loadAgreeableItemsList();
      }
      isLoadingAgreementDetails.value = false;
    };

    const loadAgreeableItemsList = async () => {
      await loadPartnerApps();
      await loadPartnerThemes();
    };

    const loadPartnerApps = async () => {
      const response = await getApplicationsList();
      if (response instanceof ErrorModel || typeof response.payload === typeof undefined) {
        Vue.$toast.error('Failed to load applications list');
        return;
      }
      response.payload.forEach((application) => {
        if (!itemHasAgreement(application.id, AgreeableItemTypeEnum.App)) {
          agreeableItemsList.list.push({
            id: application.id,
            type: AgreeableItemTypeEnum.App,
            name: application.name,
          });
        }
      });
    };

    const loadPartnerThemes = async () => {
      const response = await partnerThemesList();
      if (response instanceof ErrorModel || typeof response.themes === typeof undefined) {
        Vue.$toast.error('Failed to load themes list');
        return;
      }

      response.themes.forEach((theme) => {
        if (!itemHasAgreement(theme.id, AgreeableItemTypeEnum.Theme)) {
          agreeableItemsList.list.push({
            id: theme.id,
            type: AgreeableItemTypeEnum.Theme,
            name: theme.name,
          });
        }
      });
    };

    const onAgreeableItemChange = async (itemId: number, itemType: AgreeableItemTypeEnum) => {
      const selectedItem = getAgreeableItem(itemId, itemType);
      if (!selectedItem) {
        Vue.$toast.error('Wrong app or theme selected!');
        return;
      }
      selectedItemState.id = selectedItem.id;
      selectedItemState.name = selectedItem.name;
      selectedItemState.type = selectedItem.type;
    };

    const loadItemAgreementPreview = async () => {
      if (!selectedItemState.id) {
        return;
      }
      selectedAgreementText.value = '';
      isLoadingAgreementPreview.value = true;
      const response = await getItemAgreementPreview(selectedItemState.id, selectedItemState.type);
      isLoadingAgreementPreview.value = false;
      if (response instanceof ErrorModel || typeof response.agreement === typeof undefined) {
        Vue.$toast.error('Failed to load agreement preview');
        return;
      }
      selectedAgreementText.value = response.agreement ?? '';
    };

    const onRequestAgreementButtonClick = async () => {
      isLoadingAgreementRequest.value = true;
      const response = await initiateAgreementRequest(selectedItemState, AgreementStatusValueEnum.InReview);
      isLoadingAgreementRequest.value = false;
      if (response instanceof ErrorModel) {
        Vue.$toast.error('An error occurred.');
        return;
      }
      resetSelectedItemState();
      selectedAgreementText.value = '';
      Vue.$toast.success('Agreement request was sent successfully');
      await loadAllAgreementInitialData();
    };

    const onCancelAgreementRequestButtonClick = async () => {
      isLoadingAgreementCancel.value = true;
      const response = await initiateAgreementRequest(selectedItemState, AgreementStatusValueEnum.Draft);
      isLoadingAgreementCancel.value = false;

      if (response instanceof ErrorModel) {
        Vue.$toast.error('An error occurred.');
        return;
      }
      selectedAgreementText.value = '';
      resetSelectedItemState();
    };

    const resetSelectedItemState = () => {
      selectedItemState.id = 0;
      selectedItemState.name = 'Choose...';
    };

    const itemHasAgreement = (itemId: number, itemType: AgreeableItemTypeEnum): boolean => {
      const agreement = agreementsList.list.find((agreement) => {
        return itemId === agreement.itemId && itemType === agreement.itemType;
      });
      return typeof agreement !== typeof undefined;
    };

    const getAgreeableItem = (itemId: number, itemType: AgreeableItemTypeEnum) => {
      return agreeableItemsList.list.find((agreeableItem) => {
        return agreeableItem.id == itemId && agreeableItem.type === itemType;
      });
    };

    const getAgreementStatusLabel = (status: AgreementStatusValueEnum): AgreementStatusLabelEnum => {
      switch (status) {
        case AgreementStatusValueEnum.Draft:
          return AgreementStatusLabelEnum.Draft;
        case AgreementStatusValueEnum.InReview:
          return AgreementStatusLabelEnum.InReview;
        case AgreementStatusValueEnum.Approved:
          return AgreementStatusLabelEnum.Approved;
        case AgreementStatusValueEnum.Rejected:
          return AgreementStatusLabelEnum.Rejected;
      }
    };

    const getStatusBadgeType = (status: AgreementStatusValueEnum): string => {
      switch (status) {
        case AgreementStatusValueEnum.Draft:
          return 'primary';
        case AgreementStatusValueEnum.InReview:
          return 'warning';
        case AgreementStatusValueEnum.Approved:
          return 'success';
        case AgreementStatusValueEnum.Rejected:
          return 'danger';
      }
    };

    return {
      isLoadingAgreementDetails,
      isAgreementsLoadSuccess,
      selectedAgreementText,
      agreeableItemsList,
      agreementsList,
      selectedItemState,
      isLoadingAgreementPreview,
      isLoadingAgreementRequest,
      isLoadingAgreementCancel,
      isConsentChecked,
      getAgreementStatusLabel,
      getStatusBadgeType,
      onAgreeableItemChange,
      onRequestAgreementButtonClick,
      onCancelAgreementRequestButtonClick,
    };
  },
});
