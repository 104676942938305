import BaseModel from '../../../api/models/BaseModel';
import { SubscriptionItemType } from '../types/subscriptionsList.type';

class AllSubscriptionsResponseModel extends BaseModel {
  public subscriptions: SubscriptionItemType[];

  constructor(arg: any) {
    super(arg);
    this.subscriptions = arg.subscriptions;
  }
}

export default AllSubscriptionsResponseModel;
