import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import TeamMembersResponseModel from '../models/TeamMembersResponseModel';

const ENDPOINT_URL = '/market';

export const getTeamMembers = (): Promise<TeamMembersResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/team-members`)
    .then((response) => new TeamMembersResponseModel(response))
    .catch((error) => error);
};

export const deleteTeamMember = (teamMemberId: number): Promise<TeamMembersResponseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/team-members/${teamMemberId}`)
    .then((response) => new TeamMembersResponseModel(response))
    .catch((error) => error);
};
