import BaseModel from '@/api/models/BaseModel';
import { SingleActivationType } from '../types/activationsManagement.type';

class ActivationsResponseModel extends BaseModel {
  public activations: {
    data: SingleActivationType[];
    nextPage: string;
    previousPage: string;
  };

  constructor(arg: any) {
    super(arg);
    this.activations = arg.activations;
  }
}

export default ActivationsResponseModel;
