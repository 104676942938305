import BaseModel from './BaseModel';
import ShippingApplicationModel from './ShippingApplicationModel';

class PublishedShippingAppsResponseModel extends BaseModel {
  public payload: ShippingApplicationModel[];

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default PublishedShippingAppsResponseModel;
