import BaseModel from '@/api/models/BaseModel';

class GetBankAccountResponseModel extends BaseModel {
  public partnerBankAccount: {
    partner_name: string;
    bank_name: string;
    account_name: string;
    iban: string;
    swift_code: string;
    country: string;
    city: string;
  };
  constructor(arg: any) {
    super(arg);
    this.partnerBankAccount = arg.partnerBankAccount;
  }
}

export default GetBankAccountResponseModel;
