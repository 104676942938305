import BaseModel from '@/api/models/BaseModel';
import { CountryCityInterface } from '@/domains/partnership-details/types/partnership.type';

class PartnershipCitiesResponseModel extends BaseModel {
  public cities: CountryCityInterface[];

  constructor(arg: any) {
    super(arg);
    this.cities = arg.cities;
  }
}

export default PartnershipCitiesResponseModel;
