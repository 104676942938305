import BaseModel from '@/api/models/BaseModel';
import { SingleOrderType } from '../types/ordersManagement.type';

class OrdersResponseModel extends BaseModel {
  public orders: {
    data: SingleOrderType[];
    nextPage: string;
    previousPage: string;
  };

  constructor(arg: any) {
    super(arg);
    this.orders = arg.orders;
  }
}

export default OrdersResponseModel;
