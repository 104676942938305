import { defineComponent, ref, reactive } from '@vue/composition-api';
import { ZidButton, ZidIcon, ZidInput, ZidModal } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import Vue from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import {
  ChangePasswordFieldPropertyEnum,
  ValidationErrorsEnum,
  changePasswordData,
} from '@/domains/development-store/types/developmentStore.type';
import ErrorModel from '@/api/models/ErrorModel';
import { changeStorePassword } from '@/domains/development-store/api/development-store/developmentStore';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidIcon,
    ZidInput,
    ZidModal,
  },
  props: {
    storeId: {
      type: Number,
      required: true,
    },
    storeName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isModalActive = ref(false);
    const isUpdatingPassword = ref(false);
    const showPassword = ref(false);
    const changePasswordState = reactive<changePasswordData>({ password: '', passwordConfirmation: '' });

    const passwordRules = {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(55),
      },
      passwordConfirmation: {
        required,
        sameAsPassword: helpers.withMessage('Password fields do not match', (value: string) => {
          return value === changePasswordState.password;
        }),
      },
    };

    const passwordValidator = useVuelidate(passwordRules, changePasswordState);

    const toggleModal = () => {
      isModalActive.value = !isModalActive.value;
      clearPasswordState();
    };

    const clearPasswordState = () => {
      changePasswordState.password = '';
      changePasswordState.passwordConfirmation = '';
    };

    const onChangePasswordInputDataChange = (
      changedValue: Record<string, string>,
      dataProperty: ChangePasswordFieldPropertyEnum,
    ) => {
      passwordValidator.value[dataProperty].$touch();
      changePasswordState[dataProperty] = changedValue.value;
    };

    const onSavePasswordClicked = async () => {
      isUpdatingPassword.value = true;
      const response = await changeStorePassword(props.storeId, {
        password: changePasswordState.password,
        passwordConfirmation: changePasswordState.passwordConfirmation,
      });
      if (response instanceof ErrorModel) {
        Vue.$toast.error('Failed to change store password.');
        isUpdatingPassword.value = false;
        return;
      }
      Vue.$toast.success('Store password was changed successfully.');
      isUpdatingPassword.value = false;
      toggleModal();
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      isModalActive,
      isUpdatingPassword,
      showPassword,
      passwordValidator,
      ChangePasswordFieldPropertyEnum,
      ValidationErrorsEnum,
      toggleModal,
      onChangePasswordInputDataChange,
      onSavePasswordClicked,
      togglePasswordVisibility,
    };
  },
});
